import React from "react";
import PropTypes from "prop-types";
import ClientInfo from "components/client-info-view";
import appconfig from "config/appconfig";
import moment from "moment";
import { meLocale } from "modules/me-module";
import { useSelector } from "react-redux";

export const ClientInfoContainer = ({
  onCopy,
  isOneColumnLayout,
  ...props
}) => {
  const { meLocale } = useSelector(getValuesFromStore);

  const getClientStartDate = (startDate) => {
    if (startDate) {
      const lang = meLocale ? meLocale.split("_")[0] : "en";
      return moment(startDate).locale(lang).format("LL");
    }
    return "";
  };

  const getClientData = () => {
    const clientData = { ...props.clientData };
    clientData.permissionsList = {};
    if (!clientData.scopes) {
      clientData.scopes = [];
    }

    // add default profile scopes if it's not already present in client scopes
    if(!clientData.scopes.includes(appconfig.defaultProfileScope)) {
      clientData.scopes.unshift(appconfig.defaultProfileScope);
    }
    const permissionsList = { ...appconfig.permissionsList };
    if (permissionsList && permissionsList.profile) {
      if (permissionsList.profile.indexOf(appconfig.defaultProfileScope) < 0) {
        permissionsList.profile.unshift(appconfig.defaultProfileScope);
      }
    }
    for (let [key, permissionScopes] of Object.entries(permissionsList)) {
      permissionScopes = permissionScopes || [];
      if (permissionScopes.length) {
        const foundScopes = clientData.scopes.filter(function (item) {
          return permissionScopes.includes(item);
        });
        if (foundScopes.length) {
          clientData.permissionsList[key] = {
            parentScopeKey: key,
            parentScopeTranslationId: `shared.permissionItem.${key}.title`,
            allScopesSelected: foundScopes.length === permissionScopes.length,
            numberOfScopesSelected: foundScopes.length,
            numberOfScopesInProduct: permissionScopes.length,
          };
        }
      }
    }

    clientData.clientStartDate = getClientStartDate(clientData.created);
    // //Catching client data to avoid extra function calls
    // setLoadedClientData(clientData);
    return clientData;
  };

  return (
    <ClientInfo
      clientData={getClientData()}
      isOneColumnLayout={isOneColumnLayout}
      onCopy={onCopy}
    />
  );
};

ClientInfoContainer.propTypes = {
  clientData: PropTypes.object.isRequired,
  isOneColumnLayout: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
};

export const getValuesFromStore = (state) => {
  return {
    meLocale: meLocale(state),
  };
};

export default ClientInfoContainer;
