import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { meLocale } from "modules/me-module";
import App from "core/components/app-view";
import Core from "core/containers/core-container";
import { IntlProvider } from "react-intl";
import { Router } from "react-router-dom";
import history from "lib/history";
import appconfig from "config/appconfig";

/* istanbul ignore next */
const locales = {
  en_US: {
    messages: () => import("locales/en_US"),
    intlPolyfill: () => import("intl/locale-data/jsonp/en"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/en"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/en"),
  },
  de_DE: {
    messages: () => import("locales/de_DE"),
    intlPolyfill: () => import("intl/locale-data/jsonp/de"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/de"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/de"),
  },
  es_ES: {
    messages: () => import("locales/es_ES"),
    intlPolyfill: () => import("intl/locale-data/jsonp/es"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/es"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/es"),
  },
  fr_FR: {
    messages: () => import("locales/fr_FR"),
    intlPolyfill: () => import("intl/locale-data/jsonp/fr"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/fr"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/fr"),
  },
  it_IT: {
    messages: () => import("locales/it_IT"),
    intlPolyfill: () => import("intl/locale-data/jsonp/it"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/it"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/it"),
  },
  zh_CN: {
    messages: () => import("locales/zh_CN"),
    intlPolyfill: () => import("intl/locale-data/jsonp/zh"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/zh"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/zh"),
  },
  ja_JP: {
    messages: () => import("locales/ja_JP"),
    intlPolyfill: () => import("intl/locale-data/jsonp/ja"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/ja"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/ja"),
  },
  ko_KR: {
    messages: () => import("locales/ko_KR"),
    intlPolyfill: () => import("intl/locale-data/jsonp/ko"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/ko"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/ko"),
  },
  pt_BR: {
    messages: () => import("locales/pt_BR"),
    intlPolyfill: () => import("intl/locale-data/jsonp/pt"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/pt"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/pt"),
  },
};

export const IntlContainer = () => {
  let { meLocale } = useSelector(getLocaleValueFromStore);
  const [messages, setMessages] = useState({});
  const [locale, setLocale] = useState("en");
  const [loadingLocaleData, setLoadingLocaleData] = useState("initial");
  /* istanbul ignore next */
  const setLocaleValues = (locale) => {
    setLoadingLocaleData("true");
    const tmpLocale =
      appconfig.supportedLocales.indexOf(locale) > -1 ? locale : "en_US";
    const settings = locales[tmpLocale];
    if (!window.Intl) {
      settings.intlPolyfill();
      if (!window.Intl.PluralRules) {
        import("@formatjs/intl-pluralrules/polyfill");
      }
      if (!window.Intl.RelativeTimeFormat) {
        import("@formatjs/intl-relativetimeformat/polyfill");
      }
    }
    const promises = [
      settings.intlPluralRules(),
      settings.intlRelativeTimeFormat(),
      settings.messages(),
    ];
    Promise.all(promises).then((module) => {
      setLocale(tmpLocale.split("_")[0]);
      setMessages(module[2].default);
      setLoadingLocaleData("false");
    });
  };
  const hasMounted = useRef(false);
  useEffect(() => {
    if (!hasMounted.current) {
      setLocaleValues(meLocale);
    } else {
      setLocaleValues(meLocale);
    }
  }, [meLocale]);

  if (loadingLocaleData === "false") {
    return (
      <IntlProvider locale={locale} textComponent="span" messages={messages}>
        <App>
          <Router history={history}>
            <Core />
          </Router>
        </App>
      </IntlProvider>
    );
  } else {
    return <span />;
  }
};

export const getLocaleValueFromStore = (state) => ({
  meLocale: meLocale(state),
});

export default IntlContainer;
