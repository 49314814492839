import {
  daoClientSecretKeyListGet,
  daoClientSecretKeyPost,
  daoClientSecretKeyPatch,
  daoClientSecretKeyDelete,
} from "dao/clientsecretkey-dao";
// ------------------------------------
// Constants
// ------------------------------------
export const CLIENT_SECRETKEYLIST_GET = "CLIENT_SECRETKEYLIST_GET";
export const CLIENT_SECRETKEYLIST_GET_SUCCESS = "CLIENT_SECRETKEYLIST_GET_SUCCESS";
export const CLIENT_SECRETKEYLIST_GET_FAILURE = "CLIENT_SECRETKEYLIST_GET_FAILURE";

export const CLIENT_SECRETKEY_POST = "CLIENT_SECRETKEY_POST";
export const CLIENT_SECRETKEY_POST_SUCCESS = "CLIENT_SECRETKEY_POST_SUCCESS";
export const CLIENT_SECRETKEY_POST_FAILURE = "CLIENT_SECRETKEY_POST_FAILURE";

export const CLIENT_SECRETKEY_PATCH = "CLIENT_SECRETKEY_PATCH";
export const CLIENT_SECRETKEY_PATCH_SUCCESS = "CLIENT_SECRETKEY_PATCH_SUCCESS";
export const CLIENT_SECRETKEY_PATCH_FAILURE = "CLIENT_SECRETKEY_PATCH_FAILURE";

export const CLIENT_SECRETKEY_DELETE = "CLIENT_SECRETKEY_DELETE";
export const CLIENT_SECRETKEY_DELETE_SUCCESS = "CLIENT_SECRETKEY_DELETE_SUCCESS";
export const CLIENT_SECRETKEY_DELETE_FAILURE = "CLIENT_SECRETKEY_DELETE_FAILURE";

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  data: {
    clientSecretKeyList: [],
  },

  // generic or global properties go here
  isLoading: false,
  isAdmin: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const clientsIsLoading = (state) => state.clientSecretKey && state.clientSecretKey.data && state.clientSecretKey.data.isLoading;
export const clientSecretKeyList = (state) => {
  state.clientSecretKey && state.clientSecretKey.data && state.clientSecretKey.data.clientSecretKeyList
    ? state.clientSecretKey.data.clientSecretKeyList
    : [];
};

// ------------------------------------
// Actions
// ------------------------------------
export const clientSecretKeyRequestGet = (clientId) => (dispatch) => {
  dispatch({
    type: CLIENT_SECRETKEYLIST_GET,
  });

  return daoClientSecretKeyListGet(clientId).then(
    (response) => dispatch(clientSecretKeyRequestGetSuccess(response.data)),
    (ex) => {
      dispatchEvent(clientSecretKeyRequestGetFailure(ex));
      Promise.reject(ex);
    }
  );
};

export const clientSecretKeyRequestGetSuccess = (payload = {}) => ({
  type: CLIENT_SECRETKEYLIST_GET_SUCCESS,
  payload
});

export const clientSecretKeyRequestGetFailure = (payload = {}) => ({
  type: CLIENT_SECRETKEYLIST_GET_FAILURE,
  payload
});

export const clientSecretKeyRequestPostSuccess = (payload = {}) => ({
  type: CLIENT_SECRETKEY_POST_SUCCESS,
  payload,
});

export const clientSecretKeyRequestPostFailure = (payload = {}) => ({
  type: CLIENT_SECRETKEY_POST_FAILURE,
  payload,
});

export const clientSecretKeyRequestPost = (clientId, payload = {}) => (dispatch) => {
    dispatch({
      type: CLIENT_SECRETKEY_POST,
    });
    return daoClientSecretKeyPost(clientId, payload).then(
      (response) => dispatch(clientSecretKeyRequestPostSuccess(response.data)),
      (ex) => {
        dispatch(clientSecretKeyRequestPostFailure(ex));
        return Promise.reject(ex);
      }
    );
  };
export const clientSecretKeyRequestPatchSuccess = (payload = {}) => ({
  type: CLIENT_SECRETKEY_PATCH_SUCCESS,
  payload,
});

export const clientSecretKeyRequestPatchFailure = (payload = {}) => ({
  type: CLIENT_SECRETKEY_PATCH_FAILURE,
  payload,
});

export const clientSecretKeyRequestPatch = (clientId, secretId, payload = {}) => (dispatch) => {
    dispatch({
      type: CLIENT_SECRETKEY_PATCH,
    });
    return daoClientSecretKeyPatch(clientId, secretId, payload).then(
      (response) => dispatch(clientSecretKeyRequestPatchSuccess(response.data)),
      (ex) => {
        dispatch(clientSecretKeyRequestPatchFailure(ex));
        return Promise.reject(ex);
      }
    );
  };

export const clientSecretKeyRequestDeleteSuccess = (payload = {}) => ({
  type: CLIENT_SECRETKEY_DELETE_SUCCESS,
  payload,
});

export const clientSecretKeyRequestDeleteFailure = (payload = {}) => ({
  type: CLIENT_SECRETKEY_DELETE_FAILURE,
  payload,
});

export const clientSecretKeyRequestDelete = (clientId, secretId) => (dispatch) => {
    dispatch({
      type: CLIENT_SECRETKEY_DELETE,
    });
    return daoClientSecretKeyDelete(clientId, secretId).then(
      (response) =>
        dispatch(clientSecretKeyRequestDeleteSuccess(response.data)),
      (ex) => {
        dispatch(clientSecretKeyRequestDeleteFailure(ex));
        return Promise.reject(ex);
      }
    );
  };
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CLIENT_SECRETKEYLIST_GET]: (state) => ({ ...state, isLoading: true }),

  [CLIENT_SECRETKEYLIST_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {
      if (action.payload.clients) {
        action.payload.clients.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
      }
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [CLIENT_SECRETKEYLIST_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [CLIENT_SECRETKEY_POST]: (state) => ({ ...state, isLoading: true }),

  [CLIENT_SECRETKEY_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [CLIENT_SECRETKEY_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [CLIENT_SECRETKEY_PATCH]: (state) => ({ ...state, isLoading: true }),

  [CLIENT_SECRETKEY_PATCH_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [CLIENT_SECRETKEY_PATCH_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [CLIENT_SECRETKEY_DELETE]: (state) => ({ ...state, isLoading: true }),

  [CLIENT_SECRETKEY_DELETE_SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [CLIENT_SECRETKEY_DELETE_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const clientsSecretKeyReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default clientsSecretKeyReducer;
