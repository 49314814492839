import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainNav from "../components/main-nav-view";
import {
  meId,
  meProducts,
  meIsAccountAdmin,
  meUserName,
  meDisplayName,
  meSupportExperience,
} from "modules/me-module";
import auth from "lib/developer-auth.js";
import environment from "lib/environment";
import { meIsBillingAdmin } from "modules/billing-module";
import { mtgServiceSettingsGet, gotoAppEnabled } from "modules/meeting-module";
import appconfig from "config/appconfig";

export const MainNavContainer = () => {
  let {
    logout,
    myProducts,
    displayName,
    avatarImageUrl,
    meIsBillingAdmin,
    meIsAccountAdmin,
    displayEmail,
    supportExperience,
    gotoAppEnabled,
  } = useSelector(getValuesFromStore);
  let dispatch = useDispatch();
  useEffect(() => {
    if (myProducts.indexOf("g2m") > -1 || myProducts.indexOf("g2mfree") > -1) {
      dispatch(mtgServiceSettingsGet(true));
    }
  }, myProducts);

  return (
    <MainNav
      logout={logout}
      displayName={displayName}
      meIsAccountAdmin={meIsAccountAdmin}
      myProducts={myProducts}
      avatarImageUrl={avatarImageUrl}
      meIsBillingAdmin={meIsBillingAdmin}
      displayEmail={displayEmail}
      showSupportLink={supportExperience !== appconfig.supportExperiences.HIDE}
      gotoAppEnabled={gotoAppEnabled}
    />
  );
};

export const getValuesFromStore = (state) => {
  // Adding a default value for environment.get().avatar_image_url,
  //   because during unit testing a false warning message is thrown without it.
  const avatarBaseImageUrl = environment.get().avatar_image_url || "";
  const avatarImageUrl = avatarBaseImageUrl.length
    ? `${avatarBaseImageUrl}${meId(state)}_medium.jpg`
    : "";

  return {
    logout: auth.logout.bind(auth),
    myProducts: meProducts(state),
    displayName: meDisplayName(state) || "",
    avatarImageUrl,
    meIsBillingAdmin: meIsBillingAdmin(state),
    meIsAccountAdmin: meIsAccountAdmin(state),
    displayEmail: meUserName(state) || "",
    supportExperience:
      meSupportExperience(state) || appconfig.supportExperiences.DEFAULT,
    gotoAppEnabled: gotoAppEnabled(state),
  };
};

export default MainNavContainer;
