import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, defineMessages, useIntl} from 'react-intl';
import classes from './permission-item.scss';
import CheckboxInput from './checkbox-input-view';
import st from "core/shared-translations";
import appconfig from "config/appconfig";

const t = defineMessages({
  'permissionItem.granular.link.show': {
    id: 'permissionItem.granular.link.show',
    defaultMessage: 'Choose from granular permissions',
    description: 'Link text for showing more granular permissions',
  },
  'permissionItem.granular.link.hide': {
    id: 'permissionItem.granular.link.hide',
    defaultMessage: 'Hide granular scopes',
    description: 'Link text for hiding granular permissions',
  },
});

const PermissionItemView = ({
  permissionData,
  onPermissionCheckboxClick,
  onPermissionGroupCheckboxClick,
  togglePermissionsDetail,
  isProfilePermission,
  disabled = false,
}) => {
  const intl = useIntl();
  return (
    <div className="col-xs-12" id={`permissionItem-${permissionData.name}`}>
      <div className={classes['permissionItemTile']}>
        <div className="row">
          <div className="col-xs-11">
            <div className={classes['permissionTitle']}>
              <FormattedMessage {...st[`shared.permissionItem.${permissionData.name}.title`]} />
            </div>
            <div className={classes['permissionSubTitle']}>
              <FormattedMessage {...st[`shared.permissionItem.${permissionData.name}.subtitle`]} />
              {!permissionData.hasChildScopes && !isProfilePermission &&
                <span className={classes['scopeId']}>[{permissionData.scopes[0]}]</span>}
            </div>
          </div>
          <div className={`col-xs-1 ${classes['cb']}`}>
            {!permissionData.hasChildScopes && !isProfilePermission &&
              <CheckboxInput
                id={permissionData.scopes[0]}
                name={permissionData.scopes[0]}
                title={intl.formatMessage(st[`shared.permissionItem.${permissionData.name}.cbtitle`])}
                checked={permissionData.selectedScopes.indexOf(permissionData.scopes[0]) > -1}
                onChange={onPermissionCheckboxClick(permissionData.name, permissionData.scopes[0])}
                disabled={disabled}
              />}
            {permissionData.hasChildScopes && !isProfilePermission  &&
              <CheckboxInput
                id={`groupCheck_${permissionData.name}`}
                name={`groupCheck_${permissionData.name}`}
                title={intl.formatMessage(st[`shared.permissionItem.${permissionData.name}.cbtitle`])}
                indeterminate={
                  !!(permissionData.selectedScopes.length &&
                  permissionData.scopes.length > 0 &&
                  permissionData.selectedScopes.length < permissionData.scopes.length)
                }
                checked={!!(permissionData.selectedScopes.length && permissionData.selectedScopes.length === permissionData.scopes.length)}
                onChange={onPermissionGroupCheckboxClick(permissionData.name)}
                disabled={disabled}
              />}
          </div>
        </div>
        {(permissionData.hasChildScopes || isProfilePermission) &&
          <div className="row">
            <div className="col-xs-12 no-padding">
              {!isProfilePermission &&
                <div className="row no-margin">
                  <div
                    className={`col-xs-12 ${classes['permissionsDetailToggle']} ${permissionData.isGranularView ? '' : classes['shallow']}`}>
                    <a
                      href="#"
                      onClick={togglePermissionsDetail(permissionData.name)}>
                      {intl.formatMessage(t[permissionData.isGranularView ? 'permissionItem.granular.link.hide' : 'permissionItem.granular.link.show'])}
                      <i
                        className={`togo-icon togo-icon-down-arrow ${permissionData.isGranularView ? ' togo-icon-rotate-180' : ''}`} />
                    </a>
                  </div>
                </div>}
              {permissionData.scopes.map((scope, index) => (
                <div
                  className={`row ${classes['permissionsChildScopeRow']} ${permissionData.isGranularView ? '' : 'hidden'}`}
                  style={scope === appconfig.defaultProfileScope ? {marginTop: '10px'} : {}}
                  key={index}>
                  <div className="col-xs-11">
                    <div className={classes['permissionsChildScopeTitle']}>
                      <FormattedMessage {...st[`shared.permissionItem.${permissionData.name}.${scope}`]} />
                      {scope !== appconfig.defaultProfileScope &&
                        <span className={classes['scopeId']}>[{scope}]</span>}
                    </div>
                  </div>
                  <div className={`col-xs-1 ${classes['cb']}`}>
                    <CheckboxInput
                      id={scope}
                      name={scope}
                      title={intl.formatMessage(st[`shared.permissionItem.${permissionData.name}.${scope}.cbtitle`])}
                      checked={permissionData.selectedScopes.indexOf(scope) > -1 || scope === appconfig.defaultProfileScope}
                      disabled={scope === appconfig.defaultProfileScope || disabled}
                      onChange={onPermissionCheckboxClick(permissionData.name, scope)}
                    />
                  </div>
                </div>
                ))}
            </div>
          </div>}
      </div>
    </div>);
};

PermissionItemView.propTypes = {
  permissionData: PropTypes.object.isRequired,
  onPermissionCheckboxClick: PropTypes.func.isRequired,
  onPermissionGroupCheckboxClick: PropTypes.func.isRequired,
  togglePermissionsDetail: PropTypes.func.isRequired,
  isProfilePermission:PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default PermissionItemView;
