import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { copyTextToClipboard, stopEvent } from 'lib/utility-functions';
import ClientSearch from '../components/client-search-view';
import { uiShowToastrMessage } from 'modules/ui-module';
import {
  setHandledErrorCodes,
  removeHandledErrorCodes
} from 'lib/error-message-handling';
import {
  clientList,
  clientsIsLoading,
  clientAdminRequestSearchGet,
} from 'modules/portal-module';

const getErrorCodesArray = () => {
  return [
    404,
    'client.not.found',
  ];
};

export const ClientSearchContainer = (props) => {
  const [clientExist, setClientExist] = React.useState(false);
  const [clientId, setClientId] = React.useState(props.match.params.clientId);
  const [clientData, setClientData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState({});

  const dispatch = useDispatch();
  const { isLoading } = useSelector(getValuesFromStore);

  const onChange = (e) => {
    const client = e.target.value;
    stopEvent(e);
    setClientId(client);
  };

  const searchClient = (e) => {
    setError({});
    setLoading(true);
    stopEvent(e);
    //check if client is valid or not
    const clientIsValid = clientId.trim().length !== 0;
    if (clientIsValid) {
      setHandledErrorCodes(getErrorCodesArray());
      dispatch(clientAdminRequestSearchGet(clientId.trim()))
        .then(
          (result) => {
            setClientExist(true);
            removeHandledErrorCodes(getErrorCodesArray());
            setClientData(result.payload);
            setLoading(false);
          },
          (ex) => {
            setClientExist(false);
            removeHandledErrorCodes(getErrorCodesArray());
            dispatch(uiShowToastrMessage('error', 'client-info.client-not-found', null));
            setLoading(false);
          }
        );
    }
    else {
      setError({ name: 'shared.client-id.validation.error'});
      setLoading(false);
    }
  };

  const toClientList = () => {
    props.history.push('/clients');
  };

  const onCopy = (str) => (e) => {
    stopEvent(e);
    if (copyTextToClipboard(str)) {
      dispatch(uiShowToastrMessage('success', 'copy-to-clipboard.client-id-copied'));
    }
    return false;
  };

  //search client info when component loads
  React.useEffect(searchClient, []);

  return (
    <ClientSearch
      clientId={clientId}
      clientData={clientData}
      clientExist={clientExist}
      onChange={onChange}
      navToClientList={toClientList}
      onSearch={searchClient}
      onCopy={onCopy}
      isLoading={isLoading|| loading}
      error={error}
    />
  );
};

ClientSearchContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export const getValuesFromStore = (state) => {
  return {
    isLoading: clientsIsLoading(state),
  };
};

export default ClientSearchContainer;
