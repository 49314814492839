import appconfig from 'config/appconfig';

// Product settings and name references
const navProductSpecs = {
  g2ars: { class: 'togo-icon togo-icon-gotoassist togo-color-g2a', product: appconfig.logMeInProducts.g2ars, productUrlRef: 'GoToAssist_url' },
  g2asd: { class: 'togo-icon togo-icon-gotoassist color-gotoassist togo-color-g2a', product: appconfig.logMeInProducts.g2asd, productUrlRef: 'ServiceDesk_url' },
  g2aseeit: { class: 'togo-icon togo-icon-seeit togo-color-g2a', product: appconfig.logMeInProducts.g2aseeit, productUrlRef: 'Seeit_url' },
  g2m: { class: 'togo-icon togo-icon-gotomeeting color-gotomeeting togo-color-g2m', product: appconfig.logMeInProducts.g2m, productUrlRef: 'GoToMeeting_url' },
  g2mApp: { class: 'togo-icon togo-icon-gotomeeting color-gotomeeting togo-color-g2m', product: appconfig.logMeInProducts.g2m, productUrlRef: 'GoToMeetingApp_url' },
  g2mfree: { class: 'togo-icon togo-icon-gotomeeting color-gotomeeting togo-color-g2m', product: appconfig.logMeInProducts.g2mfree, productUrlRef: 'GoToMeeting_url' },
  g2mfreeApp: { class: 'togo-icon togo-icon-gotomeeting color-gotomeeting togo-color-g2m', product: appconfig.logMeInProducts.g2mfree, productUrlRef: 'GoToMeetingApp_url' },
  g2t: { class: 'togo-icon togo-icon-gotomeeting color-gototraining togo-color-g2t', product: appconfig.logMeInProducts.g2t, productUrlRef: 'GoToTraining_url' },
  g2w: { class: 'togo-icon togo-icon-gotomeeting color-gotowebinar togo-color-g2w', product: appconfig.logMeInProducts.g2w, productUrlRef: 'GoToWebinar_url' },
  g2p: { class: 'togo-icon togo-icon-gotomypc color-gotomypc togo-color-g2p', product: appconfig.logMeInProducts.g2p, productUrlRef: 'GoToMyPc_url' },
  openvoice: { class: 'togo-icon togo-icon-openvoice color-openvoice togo-color-openvoice', product: appconfig.logMeInProducts.openvoice, productUrlRef: 'OpenVoice_url' },
  jive: { class: 'nav-prodlogo jive', product: appconfig.logMeInProducts.jive, productUrlRef: 'Jive_url' },
  hvoice: { class: 'nav-prodlogo hvoice', product: appconfig.logMeInProducts.hvoice, productUrlRef: 'Jive_url' },
  gotoconnect: { class: 'nav-prodlogo gotoconnect', product: appconfig.logMeInProducts.gotoconnect, productUrlRef: 'GoToConnect_url' },
  bold360: { class: 'nav-prodlogo bold360', product: appconfig.logMeInProducts.bold360, productUrlRef: 'Bold360_url' },
  gotoresolve: { class: 'nav-prodlogo resolve', product: appconfig.logMeInProducts.gotoresolve, productUrlRef: 'GoToResolve_url' },
  grasshopper: { class: 'nav-prodlogo grasshopper', product: appconfig.logMeInProducts.grasshopper, productUrlRef: 'Grasshopper_url' },
};
export default navProductSpecs;
