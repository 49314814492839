import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
} from 'react-intl';
import errorImage from 'static/images/error-robot.svg';
import classes from './error-view.scss';

const statusCodes = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  409: 'Conflict',
  415: 'Unsupported Media Type',
  422: 'Unprocessable Entity',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  507: 'Insufficient Storage',
};

export const ErrorView = (props) => {
  const t = defineMessages({
    'common.error.header': {
      id: 'common.error.header',
      defaultMessage: 'Error! We’ve encountered a problem!',
    },
    'common.error.link': {
      id: 'common.error.link',
      defaultMessage: 'Please try again later or go back to <a href="{href}" id="my-account-link">your account</a>.',
    },
    'common.error.details': {
      id: 'common.error.details',
      defaultMessage: 'Error Details',
    },
    'common.error.id': {
      id: 'common.error.id',
      defaultMessage: 'Error ID',
    },
    'common.error.code': {
      id: 'common.error.code',
      defaultMessage: 'Code',
    },
    'common.error.status': {
      id: 'common.error.status',
      defaultMessage: 'Status',
    },
    'common.error.field': {
      id: 'common.error.field',
      defaultMessage: 'Field',
    },
    'common.error.value': {
      id: 'common.error.value',
      defaultMessage: 'Value',
    },
    'common.error.debug': {
      id: 'common.error.debug',
      defaultMessage: 'Debug',
    },
  });

  return (
    <div id="error-view" className={`text-center ${classes['error-view']}`}>
      <div className="row">
        <div className="col-sm-offset-2 col-sm-8 col-xs-12">
          <img src={errorImage} width="286" height="269" alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-offset-2 col-sm-8 col-xs-12">
          <h2><FormattedMessage {...t['common.error.header']} /></h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-offset-3 col-sm-6 col-xs-12">
          <h5><FormattedHTMLMessage {...t['common.error.link']} values={{href: props.errorUrl}} /></h5>
        </div>
      </div>
      {(props.error && props.error.data) &&
        <div className={`row ${classes['error-details']}`}>
          <div className="col-xs-12 col-sm-8 col-sm-offset-2">
            <h5><FormattedMessage {...t['common.error.details']} /></h5>
            <hr />
            <div className="row">
              <div className="col-xs-4 text-right">
                <FormattedMessage {...t['common.error.id']} />
              </div>
              <div className="col-xs-8 text-left">
                <strong>{props.error.data.id}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-4 text-right">
                <FormattedMessage {...t['common.error.status']} />
              </div>
              <div className="col-xs-8 text-left">
                <strong id="error-status-code">{statusCodes[props.error.status] || props.error.status}</strong>
              </div>
            </div>
            {Array.isArray(props.error.data.errors) && props.error.data.errors.map((errorData) => (
              <div id="errors" key={errorData.code}>
                {errorData.code &&
                  <div className="row">
                    <div className="col-xs-4 text-right">
                      <FormattedMessage {...t['common.error.code']} />
                    </div>
                    <div className="col-xs-8 text-left">
                      <strong className="error-code">{errorData.code}</strong>
                    </div>
                  </div>}
                {errorData.field &&
                  <div className="row">
                    <div className="col-xs-4 text-right">
                      <FormattedMessage {...t['common.error.field']} />
                    </div>
                    <div className="col-xs-8 text-left">
                      <strong className="error-field">{errorData.field}</strong>
                    </div>
                  </div>}
                {errorData.value &&
                  <div className="row">
                    <div className="col-xs-4 text-right">
                      <FormattedMessage {...t['common.error.value']} />
                    </div>
                    <div className="col-xs-8 text-left">
                      <strong className="error-value">{errorData.value}</strong>
                    </div>
                  </div>}
                {errorData.debug &&
                  <div className="row">
                    <div className="col-xs-4 text-right">
                      <FormattedMessage {...t['common.error.debug']} />
                    </div>
                    <div className="col-xs-8 text-left">
                      <strong className="error-debug">{errorData.debug}</strong>
                    </div>
                  </div>}
              </div>
            ))}
            <hr />
          </div>
        </div>}
    </div>
  );
};

ErrorView.propTypes = {
  errorUrl: PropTypes.string.isRequired,
  error: PropTypes.object,
};

export default ErrorView;
