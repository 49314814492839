import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
} from 'react-intl';
import classes from './unauthorized-view.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';

export const UnauthorizedView = (props) => {
  const t = defineMessages({
    'common.forbidden': {
      id: 'common.forbidden',
      defaultMessage: 'You seem to have landed in the wrong place.',
    },
    'common.contact-admin': {
      id: 'common.contact-admin',
      defaultMessage: 'Please contact the account admin to gain access to this location. We will now redirect you to your <a href="{href}">account profile</a>.',
    },
  });

  return (
    <div id="unauthorized-view" className={`text-center ${classes['unauthorized-view']}`}>
      <div className="row">
        <div className="col-sm-offset-2 col-sm-8 col-xs-12">
          <i className="togo-icon togo-icon-rocket" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-offset-2 col-sm-8 col-xs-12">
          <h2><FormattedMessage {...t['common.forbidden']} /></h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-offset-3 col-sm-6 col-xs-12">
          <h5><FormattedHTMLMessage {...t['common.contact-admin']} id="account-link" values={{href: props.accountUrl}} /></h5>
        </div>
      </div>
      <div className={`row ${classes['progress-padding']}`}>
        <div className="col-sm-offset-3 col-sm-6 col-xs-12">
          <ProgressBar animated now={props.percent}  />
        </div>
      </div>
    </div>
  );
};

UnauthorizedView.propTypes = {
  accountUrl: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
};

export default UnauthorizedView;
