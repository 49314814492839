import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, useIntl} from 'react-intl';
import classes from './redirect-uri-row.scss';

const t = defineMessages({
  'redirect.uri.row.delete.title': {
    id: 'redirect.uri.row.delete.title',
    defaultMessage: 'Delete {url}',
    description: 'Hover text for the delete row icon',
  },
});

const RedirectURIRow = ({
  position,
  redirectUri,
  uriCount,
  onDeleteUriClick,
  disabled = false,
}) => {
  const intl = useIntl();
  return (
    <div
      className={`row no-margin ${classes['uriRow']}`}
      id={`redirectUri-${position}`}>
      <div className={`col-xs-10 ${classes['uriCell']}`}>
        <span className={classes['uriString']} title={redirectUri}>
          {redirectUri}
        </span>
      </div>
      <div className="col-xs-2 text-right">
        {uriCount > 1 && !disabled &&
          <button
            className="linkButton"
            onClick={onDeleteUriClick(position)}
            type="button"
            title={intl.formatMessage(t['redirect.uri.row.delete.title'], {url: redirectUri})}>
            <i className="togo-icon togo-icon-trash" />
          </button>}
      </div>
    </div>
  );
};

RedirectURIRow.propTypes = {
  position: PropTypes.number.isRequired,
  redirectUri: PropTypes.string.isRequired,
  uriCount: PropTypes.number.isRequired,
  onDeleteUriClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default RedirectURIRow;
