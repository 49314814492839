import {
  daoClientGet,
  daoClientPost,
  daoClientPatch,
  daoClientDelete,
  daoClientListGet,
  daoAdminClientGet,
  daoAdminClientSearch
} from 'dao/portalservice-dao';

// ------------------------------------
// Constants
// ------------------------------------
export const PORTAL_CLIENT_GET = 'PORTAL_CLIENT_GET';
export const PORTAL_CLIENT_GET_SUCCESS = 'PORTAL_CLIENT_GET_SUCCESS';
export const PORTAL_CLIENT_GET_FAILURE = 'PORTAL_CLIENT_GET_FAILURE';

export const PORTAL_CLIENT_POST = 'PORTAL_CLIENT_POST';
export const PORTAL_CLIENT_POST_SUCCESS = 'PORTAL_CLIENT_POST_SUCCESS';
export const PORTAL_CLIENT_POST_FAILURE = 'PORTAL_CLIENT_POST_FAILURE';

export const PORTAL_CLIENT_PATCH = 'PORTAL_CLIENT_PATCH';
export const PORTAL_CLIENT_PATCH_SUCCESS = 'PORTAL_CLIENT_PATCH_SUCCESS';
export const PORTAL_CLIENT_PATCH_FAILURE = 'PORTAL_CLIENT_PATCH_FAILURE';

export const PORTAL_CLIENT_DELETE = 'PORTAL_CLIENT_DELETE';
export const PORTAL_CLIENT_DELETE_SUCCESS = 'PORTAL_CLIENT_DELETE_SUCCESS';
export const PORTAL_CLIENT_DELETE_FAILURE = 'PORTAL_CLIENT_DELETE_FAILURE';

export const PORTAL_CLIENTLIST_GET = 'PORTAL_CLIENTLIST_GET';
export const PORTAL_CLIENTLIST_GET_SUCCESS = 'PORTAL_CLIENTLIST_GET_SUCCESS';
export const PORTAL_CLIENTLIST_GET_FAILURE = 'PORTAL_CLIENTLIST_GET_FAILURE';

export const PORTAL_ADMIN_CHECK_GET = 'PORTAL_ADMIN_CHECK_GET';
export const PORTAL_ADMIN_CHECK_GET_SUCCESS = 'PORTAL_ADMIN_CHECK_GET_SUCCESS';
export const PORTAL_ADMIN_CHECK_GET_FAILURE = 'PORTAL_ADMIN_CHECK_GET_FAILURE';

export const PORTAL_CLIENT_SEARCH_BY_ADMIN = 'PORTAL_CLIENT_SEARCH_BY_ADMIN';
export const PORTAL_CLIENT_SEARCH_BY_ADMIN_SUCCESS = 'PORTAL_CLIENT_SEARCH_BY_ADMIN_SUCCESS';
export const PORTAL_CLIENT_SEARCH_BY_ADMIN_FAILURE = 'PORTAL_CLIENT_SEARCH_BY_ADMIN_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  data: {
    clientList: [],
  },

  // generic or global properties go here
  isLoading: false,
  isAdmin: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const clientsIsLoading = (state) => state.clients.isLoading;
export const clientList = (state) => (state.clients && state.clients.data && state.clients.data.clients) ? state.clients.data.clients : [];
export const getClientById = (state, clientId) => {
  const client = clientList(state).find((client) => client.clientId === clientId);
  return typeof client !== 'undefined' ? client : {};
};
export const getNewClientCredentials = (state) => (
  state.clients &&
  state.clients.data &&
  state.clients.data.newClient) ? {
    id: state.clients.data.newClient.id,
    secret:state.clients.data.newClient.secret
  } : {};
export const clientIsAdmin = (state) =>  state.clients && state.clients.isAdmin;

// ------------------------------------
// Actions
// ------------------------------------
export const clientByIdReceiveGetSuccess = (payload = {}) => ({
  type: PORTAL_CLIENT_GET_SUCCESS,
  payload,
});

export const clientByIdReceiveGetFailure = (payload = {}) => ({
  type: PORTAL_CLIENT_GET_FAILURE,
  payload,
});

export const clientByIdRequestGet = (clientId) => (dispatch) => {
  dispatch({
    type: PORTAL_CLIENT_GET,
  });

  return daoClientGet(clientId)
    .then(
      response => dispatch(clientByIdReceiveGetSuccess(response.data)),
      ex => dispatch(clientByIdReceiveGetFailure(ex)),
    );
};

export const portalClientRequestPostSuccess = (payload = {}) => ({
  type: PORTAL_CLIENT_POST_SUCCESS,
  payload,
});

export const portalClientRequestPostFailure = (payload = {}) => ({
  type: PORTAL_CLIENT_POST_FAILURE,
  payload,
});

export const portalClientRequestPost = (payload = {}) => dispatch => {
  dispatch({
    type: PORTAL_CLIENT_POST,
  });
  return daoClientPost(payload)
    .then(
      response => dispatch(portalClientRequestPostSuccess(response.data)),
      ex => {
        dispatch(portalClientRequestPostFailure(ex));
        return Promise.reject(ex);
      },
    );
};

export const portalClientRequestPatchSuccess = (payload = {}) => ({
  type: PORTAL_CLIENT_PATCH_SUCCESS,
  payload,
});

export const portalClientRequestPatchFailure = (payload = {}) => ({
  type: PORTAL_CLIENT_PATCH_FAILURE,
  payload,
});

export const portalClientRequestPatch = (clientId = '', payload = {}) => dispatch => {
  dispatch({
    type: PORTAL_CLIENT_PATCH,
  });
  return daoClientPatch(clientId, payload)
    .then(
      response => dispatch(portalClientRequestPatchSuccess(response.data)),
      ex => {
        dispatch(portalClientRequestPatchFailure(ex));
        return Promise.reject(ex);
      },
    );
};

export const portalClientRequestDeleteSuccess = (payload = {}) => ({
  type: PORTAL_CLIENT_DELETE_SUCCESS,
  payload,
});

export const portalClientRequestDeleteFailure = (payload = {}) => ({
  type: PORTAL_CLIENT_DELETE_FAILURE,
  payload,
});

export const portalClientRequestDelete = (clientId = '') => dispatch => {
  dispatch({
    type: PORTAL_CLIENT_DELETE,
  });
  return daoClientDelete(clientId)
    .then(
      response => dispatch(portalClientRequestDeleteSuccess(response.data)),
      ex => {
        dispatch(portalClientRequestDeleteFailure(ex));
        return Promise.reject(ex);
      },
    );
};

export const clientListReceiveGetSuccess = (payload = {}) => ({
  type: PORTAL_CLIENTLIST_GET_SUCCESS,
  payload,
});

export const clientListReceiveGetFailure = (payload = {}) => ({
  type: PORTAL_CLIENTLIST_GET_FAILURE,
  payload,
});

export const clientListRequestGet = () => dispatch => {
  dispatch({
    type: PORTAL_CLIENTLIST_GET,
  });

  return daoClientListGet()
    .then(
      response => dispatch(clientListReceiveGetSuccess(response.data)),
      ex => dispatch(clientListReceiveGetFailure(ex)),
    );
};

export const clientAdminGetSuccess = (payload = {}) => ({
  type: PORTAL_ADMIN_CHECK_GET_SUCCESS,
  payload,
});

export const clientAdminGetFailure = (payload = {}) => ({
  type: PORTAL_ADMIN_CHECK_GET_FAILURE,
  payload,
});

export const clientAdminRequestGet = () => dispatch => {
  dispatch({
    type: PORTAL_ADMIN_CHECK_GET,
  });

  return daoAdminClientGet()
    .then(
      response => dispatch(clientAdminGetSuccess(response.data)),
      ex => dispatch(clientAdminGetFailure(ex)),
    );
};

export const clientAdminSearchGetSuccess = (payload = {}) => ({
  type: PORTAL_CLIENT_SEARCH_BY_ADMIN_SUCCESS,
  payload,
});

export const clientAdminSearchGetFailure = (payload = {}) => ({
  type: PORTAL_CLIENT_SEARCH_BY_ADMIN_FAILURE,
  payload,
});

export const clientAdminRequestSearchGet = (clientId = '') => dispatch => {
  dispatch({
    type: PORTAL_CLIENT_SEARCH_BY_ADMIN,
  });

  return daoAdminClientSearch(clientId)
    .then(
      response => dispatch(clientAdminSearchGetSuccess(response.data)),
      ex => {
        dispatch(clientAdminSearchGetFailure(ex));
        return Promise.reject(ex);
      }
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PORTAL_CLIENT_POST]: (state) => ({...state, isLoading: true}),
  [PORTAL_CLIENT_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        data: {
          ...state.data,
          newClient: {
            ...action.payload
          },
        },
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [PORTAL_CLIENT_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PORTAL_CLIENT_PATCH]: (state) => ({...state, isLoading: true}),
  [PORTAL_CLIENT_PATCH_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [PORTAL_CLIENT_PATCH_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PORTAL_CLIENT_GET]: (state) => ({...state, isLoading: true}),
  [PORTAL_CLIENT_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [PORTAL_CLIENT_GET_FAILURE]: (state) => {
    return {
      ...state,
      isLoading: false,
    };
  },

  [PORTAL_CLIENTLIST_GET]: (state) => ({...state, isLoading: true}),
  [PORTAL_CLIENTLIST_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      if (action.payload.clients) {
        action.payload.clients.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
      }
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [PORTAL_CLIENTLIST_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PORTAL_CLIENT_DELETE]: (state) => ({...state, isLoading: true}),
  [PORTAL_CLIENT_DELETE_SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [PORTAL_CLIENT_DELETE_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PORTAL_ADMIN_CHECK_GET]: (state) => ({
    ...state,
    isLoading: true
  }),
  [PORTAL_ADMIN_CHECK_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object'){
      return {
        ...state,
        isAdmin: action.payload.admin,
        isLoading: false,
      };
    }
    return { ...state, isLoading:false};
  },
  [PORTAL_ADMIN_CHECK_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PORTAL_CLIENTLIST_GET]: (state) => ({...state, isLoading: true}),
  [PORTAL_CLIENT_SEARCH_BY_ADMIN_SUCCESS]: (state, action) => {
    if(typeof action.payload === 'object'){
      return {
        ...state,
        data: {
          clients: action.payload &&  [action.payload] || []
        },
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [PORTAL_CLIENT_SEARCH_BY_ADMIN_FAILURE]: (state) => ({
      ...state,
      isLoading: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const clientsReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default clientsReducer;
