import axios from 'axios';
import auth from 'lib/developer-auth.js';
import environment from 'lib/environment';
import Session from 'lib/session';
import resolveRoute from 'lib/resolve-route';
import {isErrorHandledByUI} from 'lib/error-message-handling';

/**
 * Creates an instance of an axios HTTP provider
 * @type {axios.AxiosInstance}
 */
export const httpClient = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  crossDomain: true,
  baseURL: environment.get().api.url,
});

/**
 * Augments all HTTP requests
 * @param authentication
 * @returns {*}
 */
export const requestInterceptorFactory = (authentication) => (config) => {
  const authToken = authentication.token;
  if (authToken && authToken.hasOwnProperty('access_token')) {
    // For userName updates we need to add an Email token value to the Authorization header after Bearer -
    //   it is passed in as an already defined "Authorization" header, which the code below consolidates into one.
    let authHeader = '';
    if (config.headers && config.headers.Authorization) {
      authHeader = `, ${config.headers.Authorization}`;
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `${authToken.token_type} ${authToken.access_token}${authHeader}`,
      },
    };
  }
  return config;
};

/**
 * Determines what to do when an HTTP response has an error
 *
 * @param {object} error The error object from an HTTP response
 * @param [testOverride] Only used for the test suite because of challenges with overriding process.env.NODE_ENV
 * @returns {Promise.<*>}
 */
export const responseInterceptorErrorHandler = (error, testOverride = false) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'test' || testOverride) {
    if (error.response.status === 401) {
      auth.clear();
      window.location.reload();
    } else if (!isErrorHandledByUI(error.response)) {
      Session.setItem('last_error', JSON.stringify(error.response));
      if (window.location.pathname !== resolveRoute('error')) {
        window.location.replace(resolveRoute('error'));
      }
    }
  }
  return Promise.reject(error.response);
};

/*
 * Binds a request interceptor to the axios instance
 */
export const bindRequestInterceptor = httpClient.interceptors.request.use(requestInterceptorFactory(auth), errorMsg => Promise.reject(errorMsg));

export const bindResponseInterceptor = httpClient.interceptors.response.use((response) => response, responseInterceptorErrorHandler);

export default httpClient;
