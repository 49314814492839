import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import classes from './clientlist.scss';
import ClientInfoContainer from 'containers/client-info-container';
import { Link } from 'react-router-dom';
import resolveRoute from 'lib/resolve-route';
import GetStarted from 'components/get-started-view';
import TextInput from 'components/text-input-view';
import st from 'core/shared-translations';

const t = defineMessages({
  'client-info.addFirstClient': {
    id: 'client-info.addFirstClient',
    defaultMessage: 'Create your first client',
    description: 'Label for the add client CTA area - first client',
  },
  'client-info.createAnotherClient': {
    id: 'client-info.createAnotherClient',
    defaultMessage: 'Create a new client',
    description: 'Label for the add client CTA area',
  }
});

const ClientList = ({
  clientList,
  hasClients,
  isOneColumnLayout,
  onCopy,
  isLoading,
  showClientIdSearchInput,
  onChange,
  searchValue,
  onSearch,
  error
}) => {
  const intl = useIntl();
  return (
    <div>
      {isLoading &&
        <div
          id="loadingDiv"
          className="text-center"
          style={{ display: 'table', width: '100%', height: '100%' }}>
          <div
            id="center-content"
            style={{ display: 'table-cell', verticalAlign: 'middle', padding: '100px' }}>
            <span className="togo-icon-stack togo-icon-4x togo-icon-spinner-stack">
              <i className="togo-icon-stack-1x togo-icon togo-icon-spinner" />
              <i className="togo-icon-stack-1x togo-icon togo-icon-spinner-segment" />
            </span>
          </div>
        </div>}
      {!isLoading &&
        <div id="client-list-section" className={classes['clientListContainer']}>
          <div className="row">
            {/* Multile client layout*/}
            {!isOneColumnLayout && hasClients && <div className="row"><div className="col-xs-12 col-sm-6">
              <Link
                to={resolveRoute('clients.clientedit.add')}
                id="client-list-add-client"
                className={classes['createClientCTA']}
                props={{ 'aria-label': intl.formatMessage(t['client-info.createAnotherClient']) }}
              >
                <i className="togo-icon togo-icon-add" />
                <FormattedMessage {...t['client-info.createAnotherClient']} />
              </Link>
            </div>
              {showClientIdSearchInput && <div className="col-xs-12 col-sm-6">
                <div className={`row  ${classes['adminSearch']}`}>
                  <div className="col-xs-9 col-sm-9">
                    <TextInput
                      id="search-input"
                      name="client"
                      type="text"
                      placeholder={intl.formatMessage(st['shared.search.client-id'])}
                      label=""
                      onChange={onChange}
                      value={searchValue}
                      error={error.name && intl.formatMessage(st[error.name])}
                    />
                  </div>
                  <div className={`col-xs-3 col-sm-3 ${classes['noPadding']}`}>
                    <button
                      id="btn-save"
                      type="submit"
                      disabled={false}
                      className="btn btn-default"
                      onClick={onSearch}
                      title={intl.formatMessage(st['shared.search'])}
                      aria-label={intl.formatMessage(st['shared.search'])}
                    >
                      <FormattedMessage {...st['shared.search']} />
                    </button>
                  </div>
                </div>
              </div>}
              <div className="clearfix" />
              {clientList.map((clientData, index) => (
                <ClientInfoContainer
                  key={index}
                  clientData={clientData}
                  onCopy={onCopy}
                  isOneColumnLayout={isOneColumnLayout}
                />
              ))}
            </div>}
            {/* when no client is found show get started page*/}
            {!hasClients && !isLoading && <div className="row">
              {!showClientIdSearchInput && <div className="col-xs-12">
                <GetStarted />
              </div>}
              {showClientIdSearchInput && <div className="row">
                <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                  <div className="col-xs-9 col-sm-9">
                    <TextInput
                      id="search-input"
                      name="client"
                      type="text"
                      placeholder={intl.formatMessage(st['shared.search.client-id'])}
                      label=""
                      onChange={onChange}
                      value={searchValue}
                      error={error.name && intl.formatMessage(st[error.name])}
                    />
                  </div>
                  <div className={`col-xs-3 col-sm-3 ${classes['noPadding']}`}>
                    <button
                      id="btn-save"
                      type="submit"
                      disabled={false}
                      className="btn btn-default"
                      onClick={onSearch}
                      title={intl.formatMessage(st['shared.search'])}
                      aria-label={intl.formatMessage(st['shared.search'])}
                    >
                      <FormattedMessage {...st['shared.search']} />
                    </button>
                  </div>
                </div>
                <div className="col-xs-12">
                  <GetStarted />
                </div>
              </div>}
            </div>}
            {/* when only single client is found show below layout*/}
            {isOneColumnLayout && hasClients && <div className="row">
              {showClientIdSearchInput && <div className="col col-xs-12 col-sm-6 col-sm-offset-3">
                <div className={`row  ${classes['adminSearch']}`}>
                  <div className="col-xs-9 col-sm-9">
                    <TextInput
                      id="search-input"
                      name="client"
                      type="text"
                      placeholder={intl.formatMessage(st['shared.search.client-id'])}
                      label=""
                      onChange={onChange}
                      value={searchValue}
                      error={error.name && intl.formatMessage(st[error.name])}
                    />
                  </div>
                  <div className={`col-xs-3 col-sm-3 ${classes['noPadding']}`}>
                    <button
                      id="btn-save"
                      type="submit"
                      disabled={false}
                      className="btn btn-default"
                      onClick={onSearch}
                      title={intl.formatMessage(st['shared.search'])}
                      aria-label={intl.formatMessage(st['shared.search'])}
                    >
                      <FormattedMessage {...st['shared.search']} />
                    </button>
                  </div>
                </div>
              </div>}
              <div className="row justify-content-center">
                {clientList.map((clientData, index) => (
                  <ClientInfoContainer
                    key={index}
                    clientData={clientData}
                    onCopy={onCopy}
                    isOneColumnLayout={isOneColumnLayout}
                  />
                ))}
              </div>
              <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                <Link
                  to={resolveRoute('clients.clientedit.add')}
                  id="client-list-add-client"
                  className={classes['createClientCTA']}
                  props={{ 'aria-label': intl.formatMessage(t['client-info.createAnotherClient'])}}
                >
                  <i className="togo-icon togo-icon-add" />
                  <FormattedMessage {...t['client-info.createAnotherClient']} />
                </Link>
              </div>
            </div>}
          </div>
        </div>}
    </div>);
};

ClientList.propTypes = {
  clientList: PropTypes.array.isRequired,
  onCopy: PropTypes.func.isRequired,
  hasClients: PropTypes.bool.isRequired,
  isOneColumnLayout: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showClientIdSearchInput: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
};

export default ClientList;
