import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from "lib/environment";

const portalBaseUrl = { baseURL: environment.get().api.portalURL };

const portalClientById = appconfig.endpoints.portalClientById;
const portalClientsById = appconfig.endpoints.portalClientsById;
const portalClientsEndpoint = appconfig.endpoints.portalClients;
const portalAdminClientGetEndpoint = appconfig.endpoints.portalAdminClientGetEndpoint;

export const daoClientGet = (clientId) => api.get(portalClientsById(clientId), portalBaseUrl);

export const daoClientPost = (payload) => api.post(portalClientsEndpoint, payload, portalBaseUrl);

export const daoClientPatch = (clientId, payload) => api.patch(portalClientsById(clientId), payload, portalBaseUrl);

export const daoClientDelete = (clientId) => api.delete(portalClientsById(clientId), portalBaseUrl);

export const daoClientListGet = () => api.get(`${portalClientsEndpoint}/?limit=40`, portalBaseUrl);

export const daoAdminClientGet = () => api.get(portalAdminClientGetEndpoint, portalBaseUrl);

export const daoAdminClientSearch = (clientId) => api.get(`${portalClientsEndpoint}/${clientId}`, portalBaseUrl);
