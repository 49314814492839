import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const meEndpoint = appconfig.endpoints.me;
const meEndpointWithUniqueParam = appconfig.endpoints.meWithUniqueParam;
const avatarEndpoint = appconfig.endpoints.avatarUrls;

export const daoMeGet = () => api.get(meEndpointWithUniqueParam);

export const daoMePatch = (payload) => api.patch(meEndpoint, payload);

export const daoAvatarUrlsGet = () => api.get(avatarEndpoint);

export const daoMePatchWithAuthProp = (authProp, payload) => api.patch(meEndpoint, payload, {headers: {Authorization: `${authProp}`}});