import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import history from "lib/history";
import Session from "lib/session";
import IntlContainer from "./intl-container";
import { ToastContainer } from "react-toastify";
import { ToastifyCloseButton } from "lib/utility-functions";
import "react-toastify/dist/ReactToastify.css";

const AppContainer = ({ store, locale }) => {
  /**
   * If there is a destination in storage then clear it out and route to it.
   * Does not use resolve-route because we are just handling user intent here
   */
  const routeToOriginalTarget = () => {
    const target = Session.getOriginalTarget();
    if (target) {
      Session.removeOriginalTarget();
      history.push(target);
    }
  };
  useEffect(() => {
    routeToOriginalTarget(store);
  }, [store]);

  return (
    <Provider store={store}>
      <div style={{ height: "100%" }}>
        <ToastContainer closeButton={<ToastifyCloseButton />} />
        <IntlContainer meLocale={locale} />
      </div>
    </Provider>
  );
};
AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};
export default AppContainer;
