import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import resolveRoute from 'lib/resolve-route';

export const AppLink = ({
  to,
  routeParams,
  activeClassName,
  titleText = '',
  children,
}) => <NavLink
  to={resolveRoute(to, {routeParams})}
  activeClassName={activeClassName}
  title={titleText}
  aria-label={titleText}
>
  {children}
</NavLink>;

AppLink.propTypes = {
  to: PropTypes.string.isRequired,
  routeParams: PropTypes.arrayOf(PropTypes.string),
  activeClassName: PropTypes.string,
  titleText: PropTypes.string,
  children: PropTypes.node,
};

export default AppLink;
