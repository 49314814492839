import React, { useState, useRef } from 'react';
import ClientSecretCreateView from '../components/client-secret-create-view';
import { uiShowConfirmDialog } from 'modules/ui-module';
import {
  clientSecretKeyRequestGet,
  clientSecretKeyRequestPost,
  clientSecretKeyRequestPatch,
  clientSecretKeyRequestDelete,
} from 'modules/client-secret-key-module';
import { copyTextToClipboard, stopEvent } from 'lib/utility-functions';
import {
  setHandledErrorCodes,
  removeHandledErrorCodes,
} from 'lib/error-message-handling';
import { uiShowToastrMessage } from 'modules/ui-module';
import { meLocale } from 'modules/me-module';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';

const getErrorCodesArray = () => {
  return ['max.active.secret.limit.reached',
    'not.allowed.to.create.more.than.four.active.client',
    'name.duplicate',
    'secret.name.max.length.limit.reached',
    'name.invalid'
  ];
};

export const ClientSecretCreateContainer = (props) => {
  let MAX_ACTIVE_SECRET_LIMIT = 3, MAX_AVAILABLE_SECRET = 6;
  const [clientSecretList, setClientSecretList] = useState([]);
  let [clientSecretData, setClientSecretData] = useState({
    id: '',
    name: '',
    secretCopied: '',
    active: '',
  });
  let originalState = { id: '', name: '', secretCopied: '', active: '' };
  const [openedSecretEdit, setOpenSecretEdit] = useState('');
  const [enableCTA, setEnableCTA] = useState(false);
  const [enableEditCTA, setEnableEditCTA] = useState(false);
  const [createModelIsOpen, setCreateModelIsOpen] = useState(false);
  const [copySecretModelIsOpen, setcopySecretModelIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [disableCreateSecret, setDisableCreateSecret] = useState(false);
  const [currentSecretSubHeading, setCurrentSecretSubHeading] = useState('client-secret.title');

  const mounted = useRef();
  const client = React.useRef(props.client && props.client);
  const dispatch = useDispatch();
  const { meLocale } = useSelector(getValuesFromStore);

  const openCreateModal = (e) => {
    stopEvent(e);
    setCreateModelIsOpen(true);
    resetClientData();
    setOpenSecretEdit('');
    setErrors({});
  };

  const closeCreateModal = (e) => {
    setEnableCTA(false);
    setCreateModelIsOpen(false);
    setErrors({});
  };

  const closeCopySecretModal = (e) => {
    setcopySecretModelIsOpen(false);
    setEnableCTA(false);
  };

  const onNextClick = (e) => {
    stopEvent(e);
    setCreateModelIsOpen(false);
    setcopySecretModelIsOpen(true);
    createClientSecret(e);
    setEnableCTA(false);
  };

  const displayToastrMessage = (toastrType, toastrMessageId, toastrParams = null) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams));
  };

  const handleXHRResult = (success, messageId, toastrParams = null) => {
    const toastrType = success ? 'success' : 'error';
    displayToastrMessage(toastrType, messageId, toastrParams);
  };

  const resetClientData = () => {
    setClientSecretData(originalState);
  };

  const toggleEditMode = (secretId) => (e) => {
    stopEvent(e);
    setErrors({});
    if (openedSecretEdit === secretId) {
      setOpenSecretEdit('');
    } else {
      setOpenSecretEdit(secretId);
      const openedSecret = clientSecretList.find((secretData) => secretData.secretId === secretId);
      const secretName = openedSecret.name;
      setClientSecretData((prevState) => ({
        ...openedSecret
      }));
    }
  };

  const validateUserInput = (field, value, returnBoolean = false) => {
    let errorText = '';
    const foundErrors = { ...errors };
    const openedSecretObject = clientSecretList.find(val => val.secretId === openedSecretEdit);
    if (foundErrors[field]) {
      delete foundErrors[field];
    }

    //secret name feild validations 
    if (field === 'name') {
      const feildValue = value || (openedSecretObject && openedSecretObject.name) || '';
      const alreadyPresentName = clientSecretList.filter(val => val.secretId != openedSecretEdit).some(secret => secret.name.trim() == feildValue.trim());
      
      if (alreadyPresentName) {
        errorText = 'client-edit.client-secret-key-create-already-present';
      }
    }

    //length check for secret name
    if((field ==='longEditSecret') && value.trim().length > 25) {
        errorText =  'client-edit.secret.length.exceeded';
    }

    //secret Status validations
    if (field === 'status') {
      const activeSecretLimitReached = clientSecretList.filter(val => val.active) ;
      if(activeSecretLimitReached.length >= MAX_ACTIVE_SECRET_LIMIT &&
        !!openedSecretObject &&
        !openedSecretObject.active) {
        errorText = 'client-secret.active-secret-limit';
      }     
    }
    if (errorText.length) {
      foundErrors[field] = errorText;
    }
    if(returnBoolean) {
      return !errorText.length;
    }
    else { 
      setErrors(foundErrors);
    }
  };

  const disableSecretCreation = () => {
    const activeSecretCount = clientSecretList.filter(val => val.active).length >= MAX_ACTIVE_SECRET_LIMIT ;
    const totalSecretCount = clientSecretList.length >= MAX_AVAILABLE_SECRET;
    if(activeSecretCount) {
      setDisableCreateSecret(true);
      setCurrentSecretSubHeading('client-secret.title.disable.max-active-secrets');
    }                     
    else if(totalSecretCount) {
      setDisableCreateSecret(true);
      setCurrentSecretSubHeading('client-secret.title.disable.max-secrets');
    }
    else {
      setDisableCreateSecret(false);
      setCurrentSecretSubHeading('client-secret.title');
    }
  };

  const updateNewClientSecret = (e) => {
    stopEvent(e);
    setErrors({});
    const clientSecretVal = e.target && e.target.value;
    const newSecretNameIsValid = newClientNameIsValid(clientSecretVal);
    if(clientSecretVal.trim().length > 25) {
      validateUserInput('longEditSecret', clientSecretVal.trim());
    }
    else if (!!clientSecretVal.length && clientSecretVal.trim().length !== 0  && validateUserInput('name', clientSecretVal, true)) {
      setEnableCTA(true);
    } else {
      setEnableCTA(false);
    }

    if (newSecretNameIsValid) {
      setClientSecretData((prevState) => ({
        ...prevState,
        name: clientSecretVal,
      }));
    }
  };

  const statusOnChange = (e) => {
    let statusVal = !clientSecretData.active;
    setClientSecretData((prevState) => ({
      ...prevState,
      active: statusVal,
    }));
  };

  const fetchClientSecret = (e) => {
    stopEvent(e);
    const clientId = client && client.current && client.current.clientId;
    const clientIsValid = clientId.trim().length !== 0;
    if (clientIsValid) {
      dispatch(clientSecretKeyRequestGet(clientId.trim())).then(
        (response) => {
          const allClientSecret = (response.payload && response.payload.secrets) || [];
          const lang = meLocale ? meLocale.split('_')[0] : 'en';
          //converting all times in secret array using moment
          allClientSecret.forEach((data) => {
            if (data.createTime)
              data.createTime = moment(data.createTime).locale(lang).format('LLL');

            if (data.lastUsedTime)
              data.lastUsedTime = moment(data.lastUsedTime).locale(lang).format('LLL');
          });
          setClientSecretList(allClientSecret);
        }
      );
    }
  };

  const createClientSecret = (e) => {
    stopEvent(e);
    const clientId = client && client.current && client.current.clientId;
    const clientIsValid = clientId.trim().length !== 0;
    const clientNameIsValid = clientSecretData.name.trim().length !== 0;
    if (clientIsValid && clientNameIsValid) {
      setHandledErrorCodes(getErrorCodesArray());
      dispatch(clientSecretKeyRequestPost(clientId, { name: removeLastSpace(clientSecretData.name) }))
        .then(
          (response) => {
            removeHandledErrorCodes(getErrorCodesArray());
            setClientSecretData((prevState) => ({
              ...prevState,
              id: response.payload.value,
            }));
            fetchClientSecret();
          },
          (ex) => {
            removeHandledErrorCodes(getErrorCodesArray());
            //All possibel error handling by status code 
            if (ex.status === 409) {
              handleXHRResult(false, 'client-edit.client-secret-key-create-already-present');
            }
            else if (ex.status === 400) {
              handleXHRResult(false, 'client-edit.client-secret-key-name-max-length-reached');
            }
            setcopySecretModelIsOpen(false);
            fetchClientSecret();
          }
        );
    }
  };

  const onDelete = (secretId) => (e) => {
    stopEvent(e);
    const secretName = clientSecretList.find((secretData) => secretData.secretId === secretId).name;
    dispatch(uiShowConfirmDialog('deleteSecretKey', () => doDelete(secretId), () => true, secretName));
  };

  const doDelete = (secretId) => {
    const clientId = client && client.current && client.current.clientId;
    if (!!clientId && !!secretId) {
      dispatch(clientSecretKeyRequestDelete(clientId, secretId)).then(
        () => {
          fetchClientSecret();
        },
        (ex) => {
          fetchClientSecret();
        }
      );
    }
  };

  const onCopy = (str) => (e) => {
    stopEvent(e);
    copyTextToClipboard(str);
    return false;
  };

  const onConfirmSecretCopy = (e) => {
    const isChecked = e.target && e.target.checked;
    setClientSecretData((prevState) => ({
      ...prevState,
      secretCopied: isChecked,
    }));
    return true;
  };

  const updateClientSecretName = (e) => {
    stopEvent(e);
    if(errors && errors.name || errors && errors.longEditSecret) {
      const newErrorObject = {...errors};
      delete newErrorObject.name;
      delete newErrorObject.longEditSecret;
      setErrors(newErrorObject);
    }
    const newClientSecretName =  e.target && e.target.value;
    if (newClientNameIsValid(newClientSecretName)) {
      setClientSecretData((prevState) => ({
        ...prevState,
        name: newClientSecretName,
      })); 
    }
    else {
      validateUserInput('longEditSecret', newClientSecretName);
    }
  };

  const newClientNameIsValid = (clientName) => {
    const newClientName = clientName;
    if(newClientName.trim().length === 0 && newClientName.length !== 0) {
      //if the name start with empty space do not count the alphabets
      return false;
    }
    else if(clientName.slice(-1) ===" " && clientName.slice(-1) === clientName.slice(-2, -1)){
      //transorm multiple spaces between words to a single space
      return false;
    }
    else {
      return newClientName.length <= 25 ? true : false;
    }
  };

  const updateEditCTA = () => {
    const originalSecret = openedSecretEdit ? clientSecretList.find((secretData) => secretData.secretId === openedSecretEdit) : '';
    if (((clientSecretData &&
      clientSecretData.name &&
      clientSecretData.name.trim().length > 0 &&
      originalSecret.name &&
      clientSecretData.name.trim() !== originalSecret.name.trim() &&
      validateUserInput('name', clientSecretData.name, true) &&
      clientSecretData.active === originalSecret.active) ||
      (clientSecretData.active !== originalSecret.active &&
        clientSecretData.name &&
        originalSecret.name &&
        clientSecretData.name.trim().length > 0 && 
        clientSecretData.name.trim() === originalSecret.name.trim() &&
        isEmpty(errors)) || 
        (validateUserInput('name', clientSecretData.name, true) &&
        clientSecretData.name &&
        originalSecret.name &&
        clientSecretData.name.trim() !== originalSecret.name.trim() && 
        clientSecretData.active !== originalSecret.active))) {
      setEnableEditCTA(true);
    }
    else {
      setEnableEditCTA(false);
    }
  };

  const removeLastSpace = (text) => {
    return text.slice(-1) === ' ' ? text.slice(0,-1) : text;
  };

  const onUpdateSecret = (confirmedStatus) => {
    const payload = {
      name: removeLastSpace(clientSecretData.name),
      active: confirmedStatus,
    };

    setHandledErrorCodes(getErrorCodesArray());
    dispatch(clientSecretKeyRequestPatch(props.client.clientId, openedSecretEdit, payload))
      .then(
        (response) => {
          removeHandledErrorCodes(getErrorCodesArray());
          fetchClientSecret();
          return true;
        },
        (ex) => {
          removeHandledErrorCodes(getErrorCodesArray());
          if (ex.status === 409) {
            handleXHRResult(false, 'client-edit.client-secret-key-create-already-present');
          }
          else if (ex.status === 400) {
            handleXHRResult(false, 'client-edit.client-secret-key-name-max-length-reached');
          }
          fetchClientSecret();
        }
      );
    setOpenSecretEdit('');

  };

  const onEditSave = () => (e) => {
    if (typeof e !== 'undefined') {
      e.stopPropagation();
      e.preventDefault();
    }
    const orignalSecret = clientSecretList.find((secretData) => secretData.secretId === openedSecretEdit);
    if (!clientSecretData.active && clientSecretData.active !== orignalSecret.active) {
      dispatch(uiShowConfirmDialog('disableSecretKey', () => onUpdateSecret(false), () => onUpdateSecret(true), clientSecretData.name));
    }
    else if (clientSecretData.active) {
      onUpdateSecret(true);
    }
    else {
      onUpdateSecret(orignalSecret && orignalSecret.active);
    }
  };

  React.useEffect(() => {
    if (!mounted.current) {
      fetchClientSecret();
      mounted.current = true;
    }
    else {
      updateEditCTA();
    }
  }, [clientSecretData]);

  //check for create button disable condition whenever secret list is updated
  React.useEffect(() => {
    disableSecretCreation();
  },[clientSecretList]);

  //validate status when new secret is open for edit
  React.useEffect(() =>{
    validateUserInput('status');
  },[openedSecretEdit]);

  return (
    <ClientSecretCreateView
      createModelIsOpen={createModelIsOpen}
      copySecretModelIsOpen={copySecretModelIsOpen}
      openCreateModal={openCreateModal}
      closeCreateModal={closeCreateModal}
      closeCopySecretModal={closeCopySecretModal}
      onNextClick={onNextClick}
      updateNewClientSecret={updateNewClientSecret}
      openedSecretEdit={openedSecretEdit}
      toggleEditMode={toggleEditMode}
      onDelete={onDelete}
      secrets={clientSecretList}
      enableCTA={enableCTA}
      onCopy={onCopy}
      onConfirmSecretCopy={onConfirmSecretCopy}
      statusOnChange={statusOnChange}
      clientSecretData={clientSecretData}
      updateClientSecretName={updateClientSecretName}
      enableEditCTA={enableEditCTA}
      onEditSave={onEditSave}
      errors={errors}
      disableCreateSecret={disableCreateSecret}
      validateUserInput={validateUserInput}
      currentSecretSubHeading={currentSecretSubHeading}
    />
  );
};
ClientSecretCreateContainer.propTypes = {
  client: PropTypes.object.isRequired,
};

export const getValuesFromStore = (state) => {
  return {
    meLocale: meLocale(state),
  };
};

export default ClientSecretCreateContainer;
