import React from 'react';
import PropTypes from 'prop-types';
import TextInput from 'components/text-input-view';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import classes from 'routes/clients/components/clientlist.scss';
import ClientInfoContainer from 'containers/client-info-container';
import errorImage from 'static/images/error-robot.svg';
import st from 'core/shared-translations';
import mt from 'core/message-translations';

const ClientSearch = ({
  clientId,
  clientData,
  clientExist,
  onChange,
  navToClientList,
  onSearch,
  onCopy,
  isLoading,
  error
}) => {
  const intl = useIntl();
  return (
    <div className="row">
      {isLoading &&
        <div
          id="loadingDiv"
          className="text-center"
          style={{ display: 'table', width: '100%', height: '100%' }}>
          <div
            id="center-content"
            style={{ display: 'table-cell', verticalAlign: 'middle', padding: '100px' }}>
            <span className="togo-icon-stack togo-icon-4x togo-icon-spinner-stack">
              <i className="togo-icon-stack-1x togo-icon togo-icon-spinner" />
              <i className="togo-icon-stack-1x togo-icon togo-icon-spinner-segment" />
            </span>
          </div>
        </div>}
      {/* when client does not Exist  */}
      {!clientExist && !isLoading && <div id="client-search-not-found-section">
        <div id="navToAllClients" className={classes['navToAllClients']}>
          <div
            className={classes['navToClients']}
            onClick={navToClientList}
            role="link"
            tabIndex="0"
          >
            <i className="togo-icon togo-icon-arrow-left" />
            <FormattedMessage {...st['shared.nav.all.clients']} />
          </div>
        </div>
        <div className="col col-xs-12 col-sm-6 col-sm-offset-3">
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-xs-9 col-sm-9">
              <TextInput
                id="search-input"
                name="client"
                type="text"
                placeholder={intl.formatMessage(st['shared.search.client-id'])}
                label=""
                onChange={onChange}
                value={clientId}
                error={error.name && intl.formatMessage(st[error.name])}
              />
            </div>
            <div className={`col-xs-3 col-sm-3 ${classes['noPadding']}`}>
              <button
                id="btn-save"
                type="submit"
                disabled={false}
                className="btn btn-default"
                onClick={onSearch}
                title={intl.formatMessage(st['shared.search'])}
                aria-label={intl.formatMessage(st['shared.search'])}
              >
                <FormattedMessage {...st['shared.search']} />
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-offset-2 col-sm-8 col-xs-12" style={{ marginTop: "5%" }}>
          <img src={errorImage} width="286" height="269" alt="" />
        </div>
        <div className="col-sm-offset-2 col-sm-8 col-xs-12" style={{ marginTop: "2%" }}>
          <h3><FormattedMessage {...mt['client-info.client-not-found']} /></h3>
        </div>
      </div>}
      {/* when client exist */}
      {clientExist && !isLoading && <div id="client-search-found-section">
        <div id="navToAllClients" className={classes['navToAllClients']}>
          <div
            className={classes['navToClients']}
            onClick={navToClientList}
            role="link"
            tabIndex="0"
          >
            <i className="togo-icon togo-icon-arrow-left" />
            <FormattedMessage {...st['shared.nav.all.clients']} />
          </div>
        </div>
        <div className="col col-xs-12 col-sm-6 col-sm-offset-3">
          <div className={`row  ${classes['adminSearch']}`}>
            <div className="col-xs-9 col-sm-9">
              <TextInput
                id="search-input"
                name="client"
                type="text"
                placeholder={intl.formatMessage(st['shared.search.client-id'])}
                label=""
                onChange={onChange}
                value={clientId}
                error={error.name && intl.formatMessage(st[error.name])}
              />
            </div>
            <div className={`col-xs-3 col-sm-3 ${classes['noPadding']}`}>
              <button
                id="btn-save"
                type="submit"
                disabled={false}
                className="btn btn-default"
                onClick={onSearch}
                title={intl.formatMessage(st['shared.search'])}
                aria-label={intl.formatMessage(st['shared.search'])}
              >
                <FormattedMessage {...st['shared.search']} />
              </button>
            </div>
          </div>
        </div>
        <ClientInfoContainer
          clientData={clientData}
          onCopy={onCopy}
          isOneColumnLayout
        />
      </div>}
    </div>);
};

ClientSearch.propTypes = {
  clientId: PropTypes.string.isRequired,
  clientData: PropTypes.object.isRequired,
  clientExist: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  navToClientList: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
};

export default ClientSearch;
