import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainNav from 'core/containers/main-nav-container';
import Footer from 'core/containers/footer-container';
import classes from './core-view.scss';
import 'styles/core.scss';
import AlertAuthWarning from 'containers/alert-auth-warning-container';
import AlertAuthExpired from 'containers/alert-auth-expired-container';
import { useIntl, defineMessages } from 'react-intl';
import mt from 'core/message-translations';
import Unauthorized from 'routes/unauthorized/containers/unauthorized-container';
import ErrorView from 'routes/error/containers/error-container';
import ClientInfo from 'routes/clients/containers/client-list-container';
import ClientEdit from 'routes/clients/clientedit/containers/client-edit-container';
import clientSearch from 'routes/clients/clientsearch/containers/client-search-container';
import { showToastrMessage } from 'lib/utility-functions';
import ConfirmDialog from 'containers/confirm-dialog-container';

const t = defineMessages({
  'core.skip.to.main.content': {
    id: 'core.skip.to.main.content',
    defaultMessage: 'Skip to main content',
    description: 'Skip link to main content to bypass navigation links for better accessibility',
  },
});

const Core = (props) => {
  const intl = useIntl();
  return (
    <div
      className="app-container"
      id="app-container">
      {
        props.toastrMessage &&
        props.toastrMessage.type &&
        props.toastrMessage.messageId &&
          showToastrMessage(
            props.toastrMessage.type,
            intl.formatMessage(mt[props.toastrMessage.messageId],
              props.toastrMessage.params),
              props.toastrMessage.messageId
          )
      }
      {
        props.confirmDialog &&
        props.confirmDialog.yesCallbackFn &&
        props.confirmDialog.noCallbackFn &&
          <ConfirmDialog />
      }
      <div className="app-container-inner">
        <div className={classes['skipToMainContent']}>
          <a href="#mainContent">
            {intl.formatMessage(t['core.skip.to.main.content'])}
          </a>
        </div>
        <MainNav />
        {props.alertAuthWarningIsVisible &&
          <AlertAuthWarning />}
        {props.alertAuthExpiredIsVisible &&
          <AlertAuthExpired />}
        <div className="container-fluid">
          <div className="app-content text-center">
            <div
              id="mainContent"
              className={classes.mainContainer}>
              <Switch>
                <Redirect
                  exact
                  from="/"
                  to="/clients"
                />
                <Route
                  exact
                  path="/clients/clientedit/:clientId?"
                  component={ClientEdit}
                />
                <Route
                  exact
                  path="/clients"
                  component={ClientInfo}
                />
                <Route
                  exact
                  path="/clients/clientsearch/:clientId?"
                  component={clientSearch}
                />
                <Route
                  exact
                  path="/unauthorized"
                  component={Unauthorized}
                />
                <Route
                  exact
                  path="/error"
                  component={ErrorView}
                />
              </Switch>
            </div>
          </div>
        </div>
        <div className="footerSpacer" />
      </div>
      <Footer />
    </div>
  );
};

Core.propTypes = {
  alertAuthWarningIsVisible: PropTypes.bool,
  alertAuthExpiredIsVisible: PropTypes.bool,
  toastrMessage: PropTypes.shape({
    type: PropTypes.string,
    messageId: PropTypes.string,
    params: PropTypes.object,
  }),
  confirmDialog: PropTypes.shape({
    yesCallbackFn: PropTypes.func,
    noCallbackFn: PropTypes.func,
    dynamicVal: PropTypes.string
  }),
};

export default Core;
