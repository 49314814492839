import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';

export const popOverBottom = ({fieldName, helpText}) => (
  <Popover id={`${fieldName}_popover`}>
    {helpText}
  </Popover>
);

popOverBottom.propTypes = {
  fieldName: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
};

export default popOverBottom;
