import {defineMessages} from 'react-intl';

const t = defineMessages({
  'copy-to-clipboard.client-id-copied': {
    id: 'copy-to-clipboard.client-id-copied',
    defaultMessage: 'The client ID has been copied to the clipboard.',
    description: 'Success message to be displayed when the Client ID is copied to the clipboard'
  },
  'client-edit.client-update-successful': {
    id: 'client-edit.client-update-successful',
    defaultMessage: 'Your client\'s data was updated.'
  },
  'client-edit.client-update-failed': {
    id: 'client-edit.client-update-failed',
    defaultMessage: 'We could not update your client. Please try again.'
  },
  'client-edit.client-update-failed-dup-name': {
    id: 'client-edit.client-update-failed-dup-name',
    defaultMessage: 'We could not update your client. The Application Name entered already exists. Please try again.'
  },
  'client-edit.client-add-successful': {
    id: 'client-edit.client-add-successful',
    defaultMessage: 'New client created. Copy and store your client secret now. You won\'t be able to access it later.'
  },
  'client-edit.client-add-failed': {
    id: 'client-edit.client-add-failed',
    defaultMessage: 'We could not add your client. Please try again.'
  },
  'client-edit.client-add-failed-dup-name': {
    id: 'client-edit.client-add-failed-dup-name',
    defaultMessage: 'We could not add your client. The Client name entered already exists. Please try again.'
  },
  'client-edit.client-delete-successful': {
    id: 'client-edit.client-delete-successful',
    defaultMessage: 'Your client was deleted.'
  },
  'client-edit.client-delete-failed': {
    id: 'client-edit.client-delete-failed',
    defaultMessage: 'We could not delete your client. Please try again.'
  },
  'client-info.client-not-found': {
    id: 'client-info.client-not-found',
    defaultMessage: 'Client not found'
  },
  'client-edit.client-secret-key-create-already-present': {
    id: 'client-edit.client-secret-key-create-already-present',
    defaultMessage: 'There\'s already a secret with this name. Enter a unique name.',
  }
});

export default t;
