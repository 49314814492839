import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import TextInput from 'components/text-input-view';
import TextArea from 'components/text-area-view';
import CheckboxInput from 'components/checkbox-input-view';
import PermissionItemView from 'components/permission-item-view';
import RedirectURIRow from 'components/redirect-uri-row-view';
import st from 'core/shared-translations';
import classes from './clientedit.scss';
import ClientSecretCreateContainer from "../containers/client-secret-create-container";

const t = defineMessages({
  'client-edit.add.title': {
    id: 'client-edit.add.title',
    defaultMessage: 'Create client',
    description: 'Title for adding a client in client details',
  },
  'client-edit.edit.title': {
    id: 'client-edit.edit.title',
    defaultMessage: 'Edit client',
    description: 'Title for editing a client in client details',
  },
  'client-edit.tab.details': {
    id: 'client-edit.tab.details',
    defaultMessage: 'Details',
    description: 'Details tab text',
  },
  'client-edit.tab.scopes': {
  id: 'client-edit.tab.scopes',
    defaultMessage: 'Scopes',
    description: 'Scopes tab text',
  },
  'client-edit.tab.credentials': {
  id: 'client-edit.tab.credentials',
    defaultMessage: 'Credentials',
    description: 'Credentials tab text',
  },
  'client-edit.tab.advanced': {
    id: 'client-edit.tab.advanced',
    defaultMessage: 'Advanced',
    description: 'Advanced tab text',
  },
  'client-edit.next.tab': {
    id: 'client-edit.next.tab',
    defaultMessage: 'Next tab',
    description: 'Title text for the next tab button',
  },
  'client-edit.previous.tab': {
    id: 'client-edit.previous.tab',
    defaultMessage: 'Previous tab',
    description: 'Title text for the previous tab button',
  },
  'client-edit.field.appName': {
    id: 'client-edit.field.appName',
    defaultMessage: 'Client name',
    description: 'Label for the Name field',
  },
  'client-edit.field.description': {
    id: 'client-edit.field.description',
    defaultMessage: 'Description (optional)',
    description: 'Label for the optional description field',
  },
  'client-edit.edit.redirectUri.title': {
    id: 'client-edit.edit.redirectUri.title',
    defaultMessage: 'Redirect URIs',
    description: 'Label for the Redirect URI title',
  },
  'client-edit.edit.redirectUri.text': {
    id: 'client-edit.edit.redirectUri.text',
    defaultMessage: 'After authorization, the user, along with the access token or authorization code, returns to these pages (ex. http://www.example.com):',
    description: 'Label for the Redirect URI text',
  },
  'client-edit.edit.redirectUri.add-empty': {
    id: 'client-edit.edit.redirectUri.add-empty',
    defaultMessage: 'Add another URI',
    description: 'Label for the link that allows adding another URI value',
  },
  'client-edit.edit.redirectUri.add-populated': {
    id: 'client-edit.edit.redirectUri.add-populated',
    defaultMessage: 'Add URI',
    description: 'Label for the link that allows adding another URI value',
  },
  'client-edit.validation.name-exceeds-max-length': {
    id: 'client-edit.validation.name-exceeds-max-length',
    defaultMessage: 'You cannot enter more than 128 characters.',
    description: 'Content of the error message when the entry in the AppName field exceeds more than 128 characters',
  },
  'client-edit.validation.name-required': {
    id: 'client-edit.validation.name-required',
    defaultMessage: 'Enter a client name.',
    description: 'Content of the error message when the entry in the AppName field exceeds more than 128 characters',
  },
  'client-edit.validation.description-exceeds-max-length': {
    id: 'client-edit.validation.description-exceeds-max-length',
    defaultMessage: 'You cannot enter more than 500 characters.',
    description: 'Content of the error message when the entry in the Description field exceeds more than 500 characters',
  },
  'client-edit.addRedirectUrl-malformed-uri': {
    id: 'client-edit.addRedirectUrl-malformed-uri',
    defaultMessage: 'Enter a valid URI less than 250 characters in length',
    description: 'Content of the error message when the entry in the Add redirect URI field exceeds more than 250 characters',
  },
  'client-edit.addRedirectUrl-matching-uri': {
    id: 'client-edit.addRedirectUrl-matching-uri',
    defaultMessage: 'The URI entered already exist in the Redirect URI list',
    description: 'Content of the error message when the entry in the Add redirect URI field matches another in the list',
  },
  'client-edit.permissions.instructions': {
    id: 'client-edit.permissions.instructions',
    defaultMessage: 'Select the scopes this client will need to request. The user granting access will need those product or admin privileges.',
    description: 'Instruction text for the top of the Permissions tab',
  },
  'client-edit.credentials.client.id.title': {
    id: 'client-edit.credentials.client.id.title',
    defaultMessage: 'Client ID',
    description: 'Title text for Credentials - Client ID',
  },
  'client-edit.credentials.client.secret.title': {
    id: 'client-edit.credentials.client.secret.title',
    defaultMessage: 'Secrets',
    description: 'Title text for Credentials - Secret',
  },
  'client-edit.credentials.client.secret.copy': {
    id: 'client-edit.credentials.client.secret.copy',
    defaultMessage: 'Copy client secret',
    description: 'Title text for Credentials - Client Secret copy button',
  },
  'client-edit.credentials.client.secret.text': {
    id: 'client-edit.credentials.client.secret.text',
    defaultMessage: 'We cannot show you the secret for security reasons. You were shown the secret once when you created the client on {creationDate}.',
    description: 'Body text Credentials - Client secret',
  },
  'client-edit.credentials.client.secret.text.nodate': {
    id: 'client-edit.credentials.client.secret.text.nodate',
    defaultMessage: 'We cannot show you the secret for security reasons. You were shown the secret once when you created the client.',
    description: 'Body text Credentials - Client secret without creation date',
  },
  'client-edit.credentials.client.secret.copy.confirm': {
    id: 'client-edit.credentials.client.secret.copy.confirm',
    defaultMessage: 'I have stored the client secret',
    description: 'Body text Credentials - Text for the confirm secret copy check box label.',
  },
  'client-edit.credentials.client.secret.display.text': {
    id: 'client-edit.credentials.client.secret.display.text',
    defaultMessage: 'The client secret can only be shown once. You will need to store it.',
    description: 'Body text Credentials displayed - Client secret',
  },
  'client-edit.credentials.client.delete.title': {
    id: 'client-edit.credentials.client.delete.title',
    defaultMessage: 'Delete client',
    description: 'Title text for Credentials - Delete client',
  },
  'client-edit.credentials.client.delete.text': {
    id: 'client-edit.credentials.client.delete.text',
    defaultMessage: 'Deleted clients cannot be recovered.',
    description: 'Body text Credentials - Delete client',
  },
  'client-edit.internal.client.message': {
    id: 'client-edit.internal.client.message',
    defaultMessage: 'This is an internal developer client and it cannot be edited here.',
    description: 'This message will be shown for clients that were added on the internal Dev Portal',
  },
  'client-edit.copy.client.secret': {
    id: 'client-edit.copy.client.secret',
    defaultMessage: 'Copy client secret.',
    description: 'Title for copy client secret button',
  }
});

const ClientEdit = ({
  isEditMode,
  client,
  clientStartDate,
  errors,
  tabClick,
  onChange,
  onRedirectUriChange,
  onBlur,
  onSave,
  onCancel,
  onDelete,
  onDoneClick,
  isDone,
  onConfirmSecretCopy,
  enableCTAs,
  canCancel,
  detailsContentsClass,
  permissionsContentsClass,
  credentialsContentsClass,
  detailsButtonClass,
  permissionsButtonClass,
  credentialsButtonClass,
  permissionsList,
  togglePermissionsDetail,
  addRedirectURI,
  isRedirectUriValid,
  onAddUriClick,
  onDeleteUriClick,
  permissionCheckboxClick,
  permissionGroupCheckboxClick,
  onCopy,
  newClientCredentials,
  isLoading,
  navToClientList
}) => {
  const intl = useIntl();
  return (
    <div id="client-edit-container">
      <div id="navToAllClients" className={classes['navToAllClients']}>
        <div
          className={classes['navToClients']}
          onClick={navToClientList}
          role="link"
          tabIndex="0"
        >
          <i className="togo-icon togo-icon-arrow-left" />
          <FormattedMessage {...st['shared.nav.all.clients']} />
        </div>
      </div>
      <div id="client-edit-section" className={classes['clientEditContainer']}>
        <form className={`${classes['ceColumn']}`}>
          <div id="titleRow" className="row">
            <div className="col-xs-12">
              <div className={`${classes['clientEditTitle']}`}>
                <FormattedMessage {...t[isEditMode ? 'client-edit.edit.title' : 'client-edit.add.title']} />
              </div>
            </div>
          </div>
          {!isEditMode &&
            <div id="add-client-tabs" className={`row ${classes['addTabNav']}`}>
              <div className="col-xs-4">
                <div
                  id="detailsStep"
                  className={`${classes['stepIndicator']} ${classes[detailsButtonClass]}`}>
                  {intl.formatMessage(t['client-edit.tab.details'])}
                  <div
                    id="detailStepProgress"
                    className={`${classes['stepProgress']} ${classes['detailStepProgress']}`}>
                    <div>
                      <div className={classes['stepStart']}>&nbsp;</div>
                      <div className={classes['stepComplete']}>&nbsp;</div>
                      <div className={classes['stepMarker']}>&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div
                  id="permissionsStep"
                  className={`${classes['stepIndicator']} ${classes[permissionsButtonClass]}`}>
                  {intl.formatMessage(t['client-edit.tab.scopes'])}
                  <div
                    id="permissionStepProgress"
                    className={`${classes['stepProgress']} ${classes['permissionStepProgress']}`}>
                    <div>
                      <div className={classes['stepStart']}>&nbsp;</div>
                      <div className={classes['stepComplete']}>&nbsp;</div>
                      <div className={classes['stepMarker']}>&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div
                  id="credentialsStep"
                  className={`${classes['stepIndicator']} ${classes[credentialsButtonClass]}`}>
                  {intl.formatMessage(t['client-edit.tab.credentials'])}
                  <div
                    id="credentialStepProgress"
                    className={`${classes['stepProgress']} ${classes['credentialStepProgress']}`}>
                    <div>
                      <div className={classes['stepStart']}>&nbsp;</div>
                      <div className={classes['stepComplete']}>&nbsp;</div>
                      <div className={classes['stepMarker']}>&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          {isEditMode &&
            <div id="edit-client-tabs" className={`row ${classes['editTabNav']}`}>
              <div className="col-xs-4">
                <a
                  id="detailsTab"
                  role="button"
                  href="#"
                  className={`${classes[detailsButtonClass]}`}
                  onClick={tabClick}
                >
                  {intl.formatMessage(t['client-edit.tab.details'])}
                </a>
                <div className={`${classes[detailsButtonClass]}`} />
              </div>
              <div className="col-xs-4">
                <a
                  id="permissionsTab"
                  role="button"
                  href="#"
                  className={`${classes[permissionsButtonClass]}`}
                  onClick={tabClick}
                >
                  {intl.formatMessage(t['client-edit.tab.scopes'])}
                </a>
                <div className={`${classes[permissionsButtonClass]}`} />
              </div>
              <div className="col-xs-4">
                <a
                  id="credentialsTab"
                  role="button"
                  href="#"
                  className={`${classes[credentialsButtonClass]}`}
                  onClick={tabClick}
                >
                  {intl.formatMessage(t['client-edit.tab.advanced'])}
                </a>
                <div className={`${classes[credentialsButtonClass]}`} />
              </div>
            </div>}
          <div id="detailsContents" className={`${detailsContentsClass}`}>
            {client.autoApproved &&
              <div id="autoApproved1" className="row" style={{marginTop: '12px', marginBottom: '18px'}}>
                <div className="col-xs-12">
                  <div id="internalClientInfo1" className="alertLight alertLight-Info">
                    <p>
                      <FormattedMessage {...t['client-edit.internal.client.message']} />
                    </p>
                  </div>
                </div>
              </div>}
            <div className="row">
              <div className="col-xs-12">
                <TextInput
                  name="name"
                  label={intl.formatMessage(t['client-edit.field.appName'])}
                  helpText=""
                  value={client.name}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={client.autoApproved}
                  error={
                    errors.name &&
                    intl.formatMessage(st[errors.name] || t[errors.name])
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <TextArea
                  name="description"
                  label={intl.formatMessage(t['client-edit.field.description'])}
                  helpText=""
                  value={client.description || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  cols="10"
                  rows="5"
                  cssClass="clientDescription"
                  disabled={client.autoApproved}
                  error={
                    errors.description &&
                    intl.formatMessage(st[errors.description] || t[errors.description])
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div
                  id="redirectURITitle"
                  className={classes['fieldLabel']}>
                  <FormattedMessage {...t['client-edit.edit.redirectUri.title']} />
                </div>
                <div
                  id="redirectURISubtitle"
                  className={classes['fieldText']}>
                  <FormattedMessage {...t['client-edit.edit.redirectUri.text']} />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12"
                id="redirect-uri-rows">
                {!!client && !!client.redirectUris && !!client.redirectUris.length &&
                client.redirectUris.map((redirectUri, index) => (
                  <RedirectURIRow
                    key={index}
                    position={index}
                    redirectUri={redirectUri}
                    uriCount={client.redirectUris.length}
                    onDeleteUriClick={onDeleteUriClick}
                    disabled={client.autoApproved}
                  />
                ))}
              </div>
            </div>
            <div
              className={`row ${classes['addURIRow']}`}
              id="redirect-uri-add-row">
              <div className="col-xs-10">
                <TextInput
                  name="addRedirectURI"
                  label=""
                  helpText=""
                  value={addRedirectURI}
                  onChange={onRedirectUriChange}
                  onBlur={onBlur}
                  disabled={client.autoApproved}
                  error={
                    errors.addRedirectURI &&
                    intl.formatMessage(st[errors.addRedirectURI] || t[errors.addRedirectURI])
                  }
                />
              </div>
              <div className={`col-xs-2 ${classes['addURIButton']}`}>
                <div className="form-group">
                  <div className="control-block">
                    <button
                      type="button"
                      onClick={onAddUriClick}
                      disabled={!isRedirectUriValid}
                      title={intl.formatMessage(t[!isRedirectUriValid ? 'client-edit.edit.redirectUri.add-empty' : 'client-edit.edit.redirectUri.add-populated'])}
                    >
                      <i className="togo-icon togo-icon-add" />
                      <FormattedMessage {...st['shared.add']} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="permissionsContents" className={`${permissionsContentsClass}`}>
            {client.autoApproved &&
              <div id="autoApproved2" className="row" style={{marginTop: '12px', marginBottom: '18px'}}>
                <div className="col-xs-12">
                  <div id="internalClientInfo2" className="alertLight alertLight-Info">
                    <p>
                      <FormattedMessage {...t['client-edit.internal.client.message']} />
                    </p>
                  </div>
                </div>
              </div>}
            <div className="row">
              <div id="scopeInstructions" className={`col-xs-12 ${classes['permissionsInstructions']}`}>
                <FormattedMessage {...t['client-edit.permissions.instructions']} />
              </div>
              {Object.keys(permissionsList).map((keyName, i) => (
                <PermissionItemView
                  key={i}
                  permissionData={permissionsList[keyName]}
                  isProfilePermission={permissionsList[keyName].name.toLowerCase() === 'profile'}
                  onPermissionCheckboxClick={permissionCheckboxClick}
                  onPermissionGroupCheckboxClick={permissionGroupCheckboxClick}
                  togglePermissionsDetail={togglePermissionsDetail}
                  disabled={client.autoApproved}
                />
              ))}
            </div>
          </div>
          <div id="credentialsContents" className={`${credentialsContentsClass}`}>
            <div className="row">
              <div className="col-xs-12">
                <div id="clientIDTitle" className={`${classes['credentialsTitle']} ${classes['first']}`}>
                  <FormattedMessage {...t['client-edit.credentials.client.id.title']} />
                </div>
              </div>
            </div>
            <div className="row" id="clientID-value-row">
              <div className={`col-xs-9 ${classes['credentialsValue']}`} id="clientID-value">
                {isEditMode ? client.clientId : (newClientCredentials.id ? newClientCredentials.id : '')}
              </div>
              <div className={`col-xs-3 ${classes['credentialsCopy']}`} id="clientID-copy">
                <button
                  type="button"
                  id="clientIdCopyButton"
                  className={classes['clientCopyCTA']}
                  onClick={onCopy(isEditMode ? client.clientId : (newClientCredentials.id ? newClientCredentials.id : ''))}
                  title={intl.formatMessage(st['shared.copy-client-id'])}
                >
                  <i className="togo-icon togo-icon-copy" />
                  <FormattedMessage {...st['shared.copy']} />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div id="clientSecretTitle" className={`${classes['credentialsTitle']}`}>
                  <FormattedMessage {...t['client-edit.credentials.client.secret.title']} />
                </div>
              </div>
            </div>
            {isEditMode &&
              <div>
                <div className="row">
                  <div className="col-xs-12" id="clientSecret-message">
                    <ClientSecretCreateContainer client={client} />
                  </div>
                </div>
                <div className="col-xs-12 no-padding">
                  <div className={`${classes['credentialsTitle']}`} id="deleteClientTitle">
                    <FormattedMessage {...t['client-edit.credentials.client.delete.title']} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12" id="deleteClientMessage">
                    <FormattedMessage {...t['client-edit.credentials.client.delete.text']} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 no-gutter">
                    <button
                      id="btn-delete"
                      type="button"
                      className={classes['deleteButton']}
                      onClick={onDelete}
                    >
                      <i className="togo-icon togo-icon-trash" />
                      <FormattedMessage {...t['client-edit.credentials.client.delete.title']} />
                    </button>
                  </div>
                </div>
              </div>}
            {!isEditMode && newClientCredentials.secret &&
              <div>
                <div className="row">
                  <div className="col-xs-12" id="saveClientSecretMessage">
                    <FormattedMessage {...t['client-edit.credentials.client.secret.display.text']} />
                  </div>
                </div>
                <div className={`row ${classes['clientsecretDisplay']}`} id="clientSecret-value-row">
                  <div id="client-secret-value" className={`col-xs-9 ${classes['credentialsValue']}`}>
                    {newClientCredentials.secret}
                  </div>
                  <div className={`col-xs-3 ${classes['credentialsCopy']}`}>
                    <button
                      type="button"
                      id="clientSecretCopyButton"
                      className={classes['clientCopyCTA']}
                      onClick={onCopy(newClientCredentials.secret)}
                      title={intl.formatMessage(t['client-edit.credentials.client.secret.copy'])}
                    >
                      <i className="togo-icon togo-icon-copy" />
                      <FormattedMessage {...st['shared.copy']} />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div style={{display: 'inline-block', paddingRight: '12px'}}>
                      <CheckboxInput
                        id="confirmCopy"
                        name="confirmCopy"
                        checked={isDone}
                        onChange={onConfirmSecretCopy}
                        title={intl.formatMessage(t['client-edit.credentials.client.secret.copy.confirm'])}
                      />
                    </div>
                    <div style={{display: 'inline-block', verticalAlign: 'baseline'}}>
                      <FormattedMessage {...t['client-edit.credentials.client.secret.copy.confirm']} />
                    </div>
                  </div>
                </div>
              </div>}
          </div>
          {!isEditMode &&
            <div>
              <div className={`row ${classes['buttonRow']} ${credentialsContentsClass === '' ? 'hidden' : ''}`}>
                <div className={`col-xs-12 col-sm-6 no-gutter ${permissionsContentsClass === '' ? '' : 'hidden'}`}>
                  <button
                    id="detailsTabBtn"
                    type="button"
                    className="btn btn-tertiary ninety-button marginl-5"
                    onClick={tabClick}
                    title={intl.formatMessage(t['client-edit.previous.tab'])}
                  >
                    {intl.formatMessage(st['shared.previous'])}
                  </button>
                </div>
                <div className={`col-xs-12 col-sm-6 no-gutter ${detailsContentsClass === '' ? '' : 'hidden'}`}>
                  &nbsp;
                </div>
                <div
                  className={`col-xs-12 col-sm-6 no-gutter ${detailsContentsClass === '' ? '' : 'hidden'}`}
                  style={{textAlign: 'right'}}>
                  <button
                    id="permissionsTabBtn"
                    type="button"
                    className="btn btn-default ninety-button"
                    style={{marginRight: '0'}}
                    disabled={!(client.name.length && (client.redirectUris.length || addRedirectURI.trim().length))}
                    onClick={tabClick}
                    title={intl.formatMessage(t['client-edit.next.tab'])}
                  >
                    {intl.formatMessage(st['shared.next'])}
                  </button>
                </div>
                <div
                  className={`col-xs-12 col-sm-6 no-gutter ${permissionsContentsClass === '' ? '' : 'hidden'}`}
                  style={{textAlign: 'right'}}>
                  <button
                    id="btn-save"
                    type="button"
                    className="btn btn-default ninety-button"
                    style={{marginRight: '0'}}
                    disabled={!enableCTAs}
                    onClick={onSave}
                  >
                    <FormattedMessage {...st['shared.save']} />
                  </button>
                </div>
              </div>
              <div
                className="row"
                style={{marginTop: '24px'}}>
                <div
                  className={`col-xs-12 ${!credentialsContentsClass ? '' : 'hidden'}`}
                  style={{textAlign: 'left'}}>
                  <button
                    id="btn-done"
                    type="button"
                    className="btn btn-default ninety-button"
                    style={{marginLeft: '0'}}
                    disabled={!isDone}
                    onClick={onDoneClick}
                  >
                    <FormattedMessage {...st['shared.done']} />
                  </button>
                </div>
              </div>
            </div>}
          {isEditMode &&
            <div>
              <div className={`row ${classes['buttonRow']} ${credentialsContentsClass === '' ? 'hidden' : ''}`}>
                <div className="col-xs-12 col-sm-12 no-gutter">
                  <button
                    id="btn-save"
                    type="submit"
                    disabled={!enableCTAs || client.autoApproved}
                    className="btn btn-default ninety-button marginl-5"
                    onClick={onSave}
                  >
                    {isLoading ? <FormattedMessage {...st['shared.saving']} /> :
                    <FormattedMessage {...st['shared.save']} />}
                  </button>
                  <button
                    id="btn-cancel"
                    type="button"
                    className="btn btn-tertiary ninety-button"
                    disabled={!canCancel}
                    onClick={onCancel}
                    title={intl.formatMessage(st['shared.cancel'])}
                  >
                    <FormattedMessage {...st['shared.cancel']} />
                  </button>
                </div>
              </div>
            </div>}
        </form>
      </div>
    </div>);
};

ClientEdit.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  clientStartDate: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  tabClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRedirectUriChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  isDone: PropTypes.bool.isRequired,
  onConfirmSecretCopy: PropTypes.func.isRequired,
  addRedirectURI: PropTypes.string.isRequired,
  onAddUriClick: PropTypes.func.isRequired,
  permissionCheckboxClick: PropTypes.func.isRequired,
  permissionGroupCheckboxClick: PropTypes.func.isRequired,
  isRedirectUriValid: PropTypes.bool.isRequired,
  onDeleteUriClick: PropTypes.func.isRequired,
  enableCTAs: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
  detailsContentsClass: PropTypes.string.isRequired,
  permissionsContentsClass: PropTypes.string.isRequired,
  credentialsContentsClass: PropTypes.string.isRequired,
  detailsButtonClass: PropTypes.string.isRequired,
  permissionsButtonClass: PropTypes.string.isRequired,
  credentialsButtonClass: PropTypes.string.isRequired,
  permissionsList: PropTypes.object.isRequired,
  togglePermissionsDetail: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  newClientCredentials: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  navToClientList: PropTypes.func.isRequired,
};

export default ClientEdit;