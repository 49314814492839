import React from 'react';
import PropTypes from 'prop-types';
import {
  useIntl,
  defineMessages,
} from 'react-intl';
import DocumentTitle from 'react-document-title';

export const App = ({children}) => {
  const t = defineMessages({
    'app.document.title': {
      id: 'app.document.title',
      defaultMessage: 'Client Management Portal',
      description: 'The document title of the application (appears in browser tab)',
    },
  });

  const intl = useIntl();
  return (
    <DocumentTitle title={intl.formatMessage(t['app.document.title'])}>
      {children}
    </DocumentTitle>
  );
};

App.propTypes = {
  children: PropTypes.element.isRequired,
};

export default App;
