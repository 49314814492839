import environment from 'lib/environment';

// We only load the app if they are using a supported browser
if (window.gtie9) {
  environment.fetch().then(() => {
    require('main.js'); // eslint-disable-line
  });
} else {
  // Show text telling the user to use a modern browser since their browser is not supported
  // @IMPROVEMENT - In IE, you can detect the user's language using this code const  language = window.navigator.userLanguage
  // (http://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference)
  // We could use that to display the user's language first in the list
  const languages = [
    // en
    {
      headerText: 'Your browser is not supported. Please use Internet Explorer 10 or newer, or for the best experience, the latest version of any modern browser like Edge, Chrome, Safari, or Firefox.',
      linkText: 'Learn more',
    },
    // de
    {
      headerText: 'Ihr Browser wird nicht unterstützt. Bitte benutzen Sie Internet Explorer 10 oder neuer oder für die beste Erfahrung die neueste Version eines modernen Browsers wie Edge, Chrome, Safari oder Firefox.',
      linkText: 'Mehr erfahren',
    },
    // fr
    {
      headerText: 'Ton navigateur n\'est pas supporté. Utilisez Internet Explorer 10 ou plus récent, ou pour la meilleure expérience, la dernière version de n\'importe quel navigateur moderne comme Edge, Chrome, Safari ou Firefox.',
      linkText: 'Apprendre encore plus',
    },
    // sp
    {
      headerText: 'Tu navegador no es compatible. Utilice Internet Explorer 10 o más reciente, o para obtener la mejor experiencia, la última versión de cualquier navegador moderno como Edge, Chrome, Safari o Firefox.',
      linkText: 'Aprende más',
    },
    // it
    {
      headerText: 'Il tuo browser non è supportato. Utilizza Internet Explorer 10 o versione successiva o per la migliore esperienza, la versione più recente di qualsiasi browser moderno come Edge, Chrome, Safari o Firefox.',
      linkText: 'Per saperne di più',
    },
    // zh
    {
      headerText: '不支持您的浏览器。请使用Internet Explorer 10或更新版本，或者获得最佳体验，即可使用最新版本的任何现代浏览器，如Edge，Chrome，Safari或Firefox。',
      linkText: '学到更多',
    },
    // jp
    {
      headerText: 'あなたのブラウザはサポートされていません。 Internet Explorer 10以降を使用するか、最先端の最新版のEdge、Chrome、Safari、Firefoxなど最新のブラウザを使用してください。',
      linkText: 'もっと詳しく知る',
    },
    // ko
    {
      headerText: '브라우저가 지원되지 않습니다. Internet Explorer 10 이상을 사용하거나 Edge, Chrome, Safari 또는 Firefox와 같은 최신 브라우저의 최신 버전을 사용하십시오.',
      linkText: '더 알아보기',
    },
    // pt
    {
      headerText: 'Seu navegador não é suportado. Use o Internet Explorer 10 ou mais recente, ou para obter a melhor experiência, a versão mais recente de qualquer navegador moderno como Edge, Chrome, Safari ou Firefox.',
      linkText: 'Saber mais',
    },
  ];

  const centerContentContainer = document.getElementById('center-content');
  centerContentContainer.innerHTML = ''; // Clear out the loading spinner since we aren't going to load the app

  languages.forEach((language) => {
    const div = document.createElement('div');
    const headerTag = document.createElement('h4');
    const aTag = document.createElement('a');
    headerTag.innerHTML = language.headerText;
    aTag.innerHTML = language.linkText;
    aTag.setAttribute('href', ''); // @TODO link to support / care article once it's been written
    div.appendChild(headerTag);
    div.appendChild(aTag);
    div.style.paddingBottom = '50px';
    centerContentContainer.appendChild(div);
  });
}
