import React from 'react';
import PropTypes from 'prop-types';

export const CloseButton = ({
  onClick,
  ariaLabel,
}) => (
  <div style={{textAlign: 'right'}}>
    <button
      type="button"
      className="close"
      onClick={onClick}
      aria-label={ariaLabel}>
      <i className="togo-icon togo-icon-closes" />
    </button>
  </div>
);

CloseButton.propTypes = {
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
