import api from "dao/providers/axios";
import appconfig from "config/appconfig";
import environment from "lib/environment";

const portalBaseUrl = { baseURL: environment.get().api.portalURL };

const clientSecretKeysByIdEndPoint = appconfig.endpoints.clientSecretKeysById;
const clientSecretKeyBySecretIdEndPoint = appconfig.endpoints.clientSecretKeyBySecretId;

export const daoClientSecretKeyListGet = (clientId) => api.get(clientSecretKeysByIdEndPoint(clientId), portalBaseUrl);

export const daoClientSecretKeyPost = (clientId, payload) => api.post(clientSecretKeysByIdEndPoint(clientId),payload, portalBaseUrl);

export const daoClientSecretKeyPatch = (clientId, secretId, payload) => api.patch(clientSecretKeyBySecretIdEndPoint(clientId, secretId), payload, portalBaseUrl);

export const daoClientSecretKeyDelete = (clientId, secretId) => api.delete(clientSecretKeyBySecretIdEndPoint(clientId, secretId), portalBaseUrl);
