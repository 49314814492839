// Filter for illegal characters
export const hasIllegalCharacters = (inputText, isUriValidation = false) => {
  // Illegal characters in standard user input fields (name, email, etc.)
  const illegalFieldInputCharacters = ['%', '<', '>', '[', ']', '{', '}'];

  if (isUriValidation) {
    illegalFieldInputCharacters.push("*"); // disallow '*' for URI values, allow '&'.
  } else {
    illegalFieldInputCharacters.push("&"); // disallow '&' for non-URI values, allow '*'
  }

  return illegalFieldInputCharacters.some((char) => inputText.indexOf(char) > -1);
};

// URI validation
export const isValidURI = require( 'validate.io-uri' );


