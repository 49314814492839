import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/Overlay';
import popOverBottom from './popover-bottom-view';

export const TextInput = ({
  name,
  label,
  onChange,
  onBlur,
  placeholder,
  value='',
  error = '',
  helpText,
  infoText,
  isShallowGroup = false,
  disabled = false,
  showCounter = false,
  maxcount = 25,
  dangerCounter = false
}) =>
  <div className={`form-group${isShallowGroup ? ' shallow' : ''}${error.length ? ' has-error' : ''}`}>
    <div className="control-block">
      <label id={`${name}-label`} htmlFor={`${name}-input`} className={label.length ? '' : ' hidden'}>
        {label}
        {helpText &&
          <OverlayTrigger
            container={this}
            trigger="click"
            rootClose
            placement="bottom"
            overlay={popOverBottom(name, helpText)}>
            <i className="togo-icon togo-icon-question-circle togo-icon-question-circle-leftpadding help-icon-move-down" />
          </OverlayTrigger>}
      </label>
      <input
        id={`${name}-input`}
        type="text"
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && !showCounter &&
        <p id={`${name}-error`} className="help-block">{error}</p>}
      {infoText &&
        <p id={`${name}-infoText`} className="infoText-block">{infoText}</p>}
      {showCounter && 
        <div className="row">
          <div className="col-xs-9 col-md-10 noRigthSpacing">
            <p id={`${name}-error`} className="noRigthSpacing help-block transparentError">{dangerCounter ? '' : error}</p>
          </div>
          <div className="col-xs-3 col-md-2" style={{ paddingLeft: 0 }}>
            <p id={`${name}-counter`} className={`counter-block ${dangerCounter ? 'counter' : ''}`}>
              {`${dangerCounter ? maxcount : value.length }/${maxcount}`}
            </p>
          </div>
        </div>}
    </div>
  </div>;

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  infoText: PropTypes.string,
  error: PropTypes.string,
  isShallowGroup: PropTypes.bool,
  disabled: PropTypes.bool,
  maxcount: PropTypes.number,
  showCounter: PropTypes.bool,
  dangerCounter: PropTypes.bool
};

export default TextInput;
