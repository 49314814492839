import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert-view';
import {
  FormattedMessage,
  defineMessages,
} from 'react-intl';
import classes from './alert.scss';

const t = defineMessages({
  'alert.auth.expired.message': {
    id: 'alert.auth.expired.message',
    defaultMessage: 'Your session has expired.',
  },
  'alert.auth.expired.buttonLogin': {
    id: 'alert.auth.expired.buttonLogin',
    defaultMessage: 'To continue, sign in to your account.',
  },
});

export const AlertAuthExpired = (props) => (
  <Alert
    type="danger"
    icon={
      <i className={`togo-icon togo-icon-hand-raise text-warning ${classes.bannerMessageIcon}`} />
    }
    body={
      <span className={classes.bannerMessageText}>
        <FormattedMessage {...t['alert.auth.expired.message']} />
      </span>
    }
    buttons={
      <button id="auth-expired-login" className={classes.bannerMessageCTA} onClick={props.handleLogin}>
        <FormattedMessage {...t['alert.auth.expired.buttonLogin']} />
      </button>
    }
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertAuthExpired.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
};

export default AlertAuthExpired;
