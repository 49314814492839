import {defineMessages} from 'react-intl';

const dt = defineMessages({
  'confirmDialog.hasEdits.title': {
    id: 'confirmDialog.hasEdits.title',
    defaultMessage: 'You\'ve made changes',
    description: 'Title for the save changes modal dialog',
  },
  'confirmDialog.hasEdits.text': {
    id: 'confirmDialog.hasEdits.text',
    defaultMessage: 'Would you like to continue editing?',
    description: 'Text content for the hasEdits modal dialog',
  },
  'confirmDialog.hasEdits.yesButtonText': {
    id: 'confirmDialog.hasEdits.yesButtonText',
    defaultMessage: 'Continue editing',
    description: 'Text for the hasEdits modal dialog yes option button',
  },
  'confirmDialog.hasEdits.noButtonText': {
    id: 'confirmDialog.hasEdits.noButtonText',
    defaultMessage: 'Discard changes',
    description: 'Text for the hasEdits modal dialog no option button',
  },
  'confirmDialog.deleteClient.title': {
    id: 'confirmDialog.deleteClient.title',
    defaultMessage: 'Delete client?',
    description: 'Title for the delete client modal dialog',
  },
  'confirmDialog.deleteClient.text': {
    id: 'confirmDialog.deleteClient.text',
    defaultMessage: 'Integrations using this client will stop working immediately.',
    description: 'Text content for the delete client modal dialog',
  },
  'confirmDialog.deleteClient.yesButtonText': {
    id: 'confirmDialog.deleteClient.yesButtonText',
    defaultMessage: 'Delete',
    description: 'Text for the delete client modal dialog yes option button',
  },
  'confirmDialog.deleteClient.noButtonText': {
    id: 'confirmDialog.deleteClient.noButtonText',
    defaultMessage: 'Cancel',
    description: 'Text for the delete client modal dialog no option button',
  },
  'confirmDialog.deleteSecretKey.title': {
    id: 'confirmDialog.deleteSecretKey.title',
    defaultMessage: 'Delete secret?',
    description: 'Title for the delete client modal dialog',
  },
  'confirmDialog.deleteSecretKey.text': {
    id: 'confirmDialog.deleteSecretKey.text',
    defaultMessage: 'Once you delete the secret <b>{dynamicVal}</b>, it can\'t be recovered.',
    description: 'Text content for the delete client modal dialog',
  },
  'confirmDialog.deleteSecretKey.yesButtonText': {
    id: 'confirmDialog.deleteSecretKey.yesButtonText',
    defaultMessage: 'Delete',
    description: 'Text for the delete client modal dialog yes option button',
  },
  'confirmDialog.deleteSecretKey.noButtonText': {
    id: 'confirmDialog.deleteSecretKey.noButtonText',
    defaultMessage: 'Cancel',
    description: 'Text for the delete client modal dialog no option button',
  },
  'confirmDialog.disableSecretKey.title': {
    id: 'confirmDialog.disableSecretKey.title',
    defaultMessage: 'Disable secret?',
    description: 'Title for the disable client modal dialog',
  },
  'confirmDialog.disableSecretKey.text': {
    id: 'confirmDialog.disableSecretKey.text',
    defaultMessage: 'Once you disable the secret <b>{dynamicVal}</b>, any integrations using it will stop working immediately',
    description: 'Text content for the disable client modal dialog',
  },
  'confirmDialog.disableSecretKey.yesButtonText': {
    id: 'confirmDialog.disableSecretKey.yesButtonText',
    defaultMessage: 'Disable',
    description: 'Text for the disable client modal dialog yes option button',
  },
  'confirmDialog.disableSecretKey.noButtonText': {
    id: 'confirmDialog.disableSecretKey.noButtonText',
    defaultMessage: 'Cancel',
    description: 'Text for the disable client modal dialog no option button',
  },
});

export default dt;
