import React from 'react';
import { createRoot } from 'react-dom/client';
import createStore from 'store/create-store'; 
import initialState from 'store/initial-state';
import AppContainer from 'core/containers/app-container';
import developerAuth from 'lib/developer-auth.js';
import { meLocale, meRequestGet, meIsOrganizationMember } from 'modules/me-module';
import { orgServiceMeRequestGet } from 'modules/organization-module';

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.
const store = createStore(initialState);

// ========================================================
// Developer Tools Setup
// ========================================================
if (__DEV__) {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    // @NOTE commented this out because we found the auto-opening of the devtools
    //       in a new window upon any reload/refresh to be very annoying;
    // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__.open()
  }
}

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');
const root = createRoot(MOUNT_NODE);

let render = () => {
  // Get logged in user, switch to proper locale while fetching full profile account, then render the App
  store.dispatch(meRequestGet()).then(() => {
    const isOrganizationMember = meIsOrganizationMember(store.getState());
    const promises = [];
    if (isOrganizationMember) {
      promises.push(store.dispatch(orgServiceMeRequestGet()));
    }

    return Promise.all(promises);
  }).then(() => {
    const locale = meLocale(store.getState());
      root.render(
        <AppContainer
          locale={locale}
          store={store}
        />);
    });
};

// ========================================================
// HMR (Hot Module Rendering) Setup
// ========================================================
if (__DEV__) { // This code is excluded from production bundle
  if (module.hot) {
    // Development render functions
    const renderApp = render;
    const renderError = (error) => {
      const RedBox = require('redbox-react').default;
      root.render(<RedBox error={error} />);
    };

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp();
      } catch (error) {
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept(['./routes/index'], () => {
      setTimeout(() => {
        root.unmount();
        render();
      });
    });
  }
}

const runApp = () => {
  // ========================================================
  // Go!
  // @NOTE We need runApp so we can separate developerAuth.init() from render(), since we only
  // want to call developerAuth.init() once, but HMR may call render() many times
  // ========================================================
  developerAuth.init(store).then(render);
};

runApp();
