import Auth from '@getgo/auth-client';
import environment from 'lib/environment';
import appconfig from 'config/appconfig';
import Session from 'lib/session';
import {
  uiAlertAuthenticationWarningToggle,
  uiAlertAuthenticationExpiredToggle,
} from 'modules/ui-module';

/**
 * @typedef {object} OauthConfig
 *
 * The configuration required for the live oauth library
 *
 * @property {string} client_id: The client ID for the OAuth implicit grant flow
 * @property {string} url: The URL for the authentication service to use
 * @property {string} redirect_url: The URL to redirect to after successful authentication
 */

/**
 * @typedef {object} OauthOptions
 *
 * The options that can be passed to the live oauth library
 *
 * @property {boolean} enableTokenRefresh: Whether or not to let the library take care of requesting a new access token when needed;
 *                                         If this is true, then at the timestamp returned by tokenRefreshTimestamp(), run the specified
 *                                         onTokenRefresh function
 * @property {function} onTokenRefresh: What to do on token refresh
 * @property {function} tokenRefreshTimestamp: When the token should be refreshed
 */

/**
 * Client Management Portal authentication services. Retrieves, stores, and validates an API access token,
 * checks for token refresh, stores token in session storage, and handles logging out.
 */
export class DeveloperAuth {
  /**
   * @constructor
   * @param {Auth} AuthClass - External oauth library to use
   * @param {OauthConfig} config
   * @param {*} [storage]
   * @param {string} [storageKey]
   */
  constructor(AuthClass, config, storage, storageKey = appconfig.storage.oauth) {
    this.storage = storage;
    this.storageKey = storageKey;
    this.token = this.auth;
    this.warning_offset = 180000; // time (in ms) to warn user before the token expires
    this.auth_warning_timer = undefined;
    this.auth_expired_timer = undefined;

    const expirationOffset = 30000;

    /**
     * @type {OauthOptions}
     */
    const options = {
      enableTokenRefresh: false,

      onTokenRefresh: () => {
        this.clear();
        this.storage.setOriginalTarget();
        return Promise.resolve(); // returning resolved promise lets library do the rest of the refresh operation
      },

      // token.expires is in milliseconds; refresh 30 seconds before it expires
      tokenRefreshTimestamp: (token) => token.expires - expirationOffset,
    };

    this.oauth = new AuthClass({...config, ...options});
  }

  /**
   * Retrieves the auth data from the storage interface
   */
  get auth() {
    let localToken = this.storage.getItem(this.storageKey) || undefined;
    if (localToken) {
      try {
        localToken = JSON.parse(localToken);
      } catch (e) {
        localToken = undefined;
      }
    }
    return localToken;
  }

  /**
   * Clears out the auth-related session storage values and removes the token from memory
   */
  clear() {
    this.storage.removeItem(this.storageKey);
    this.storage.removeOriginalTarget();
    window.clearTimeout(this.auth_warning_timer);
    window.clearTimeout(this.auth_expired_timer);
    this.token = undefined;
  }

  /**
   * Sets up the notification timer that will warn the user when the token is about to expire which will redirect them
   */
  setNotificationTimers() {
    // clear existing timers
    window.clearTimeout(this.auth_warning_timer);
    window.clearTimeout(this.auth_expired_timer);

    // This will alert the user when their token has expired
    const expiredTime = Math.max(0, this.token.expires - Date.now());
    // This will warn the user X seconds before the expired alert takes place, where x is this.warning_offset
    const warningTime = expiredTime - this.warning_offset;
    // Use warning offset only if we have the full offset remaining, otherwise use the difference between the offset and warning time
    const timeLeftBeforeExpiration = (warningTime < 0) ? this.warning_offset + warningTime : this.warning_offset;

    this.auth_warning_timer = window.setTimeout(() => this.store.dispatch(uiAlertAuthenticationWarningToggle(timeLeftBeforeExpiration)), warningTime);
    this.auth_expired_timer = window.setTimeout(() => this.store.dispatch(uiAlertAuthenticationExpiredToggle()), expiredTime);
  }

  /**
   * Check for an existing token in localStorage, and if expired/invalid or not found, redirect to login/auth flow
   *
   * @param {object} store An instance of a data store that provides a method for dispatching actions
   * @returns {Promise} Resolves if valid token is found; remains unresolved if the oauth library is redirecting to login/auth flow
   */
  init(store) {
    this.store = store;
    this.storage.setOriginalTarget();

    if (this.token) {
      this.setNotificationTimers();
      this.storage.setItem(this.storageKey, JSON.stringify(this.token));
      return Promise.resolve();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const stateFromLocation = urlParams.get("state");
    if (stateFromLocation) {
      if (this.storage.getItem("state") !== stateFromLocation){
        throw Error("Probable session hijacking attack!");
      }
      const authorizationCode = urlParams.get("code");
      if (!authorizationCode) {
        throw Error("Authorization failed");
      }
      const initialCodeVerifier = this.storage.getItem("code_verifier");
      const config = {
        state: stateFromLocation,
        code: authorizationCode,
        code_verifier: initialCodeVerifier,
      };
      return this.oauth.requestPKCEToken(config).then(
        (token) => {
          this.oauth.storeTokenObject(token);
          this.token = token;
          this.setNotificationTimers();
          this.storage.setItem(this.storageKey, JSON.stringify(token));
          return Promise.resolve();
        },
        () => {
          throw Error("Authorization failed.");
        }
      );
    }

    this.oauth.options.state = this.oauth.randomString(32);
    this.oauth.options.code_verifier = this.oauth.randomString(64);

    this.storage.removeItem("state");
    this.storage.removeItem("code_verifier");
    this.storage.setItem("state", this.oauth.options.state);
    this.storage.setItem("code_verifier", this.oauth.options.code_verifier);

    return this.oauth.loginWithPKCE();
  }

  /**
   * Log the user out and redirect to login screen
   */
  logout() {
    this.clear();
    this.oauth.logout();
  }
}

export default new DeveloperAuth(Auth, environment.get().oauth, Session);
