import React, { useEffect, useState } from "react";
import config from "config/appconfig";
import environment from "lib/environment";
import UnauthorizedView from "../components/unauthorized-view";
import { scheduledIntervalCounter } from "lib/scheduled-interval-counter";
import developerAuth from "lib/developer-auth.js";

// Environment variables
export const accountUrl = environment.get().unauthorized_redirect_url;
export const durationInSeconds = config.unauthorized.redirect_time;

const UnauthorizedContainer = () => {
  let [percent, setPercent] = useState(0);
  const updatePercent = (count, timesCounted) => {
    const percent = Math.floor((timesCounted / count) * 100);
    setPercent(percent);
  };

  const redirectUrl = (currentWindow) => {
    return () => currentWindow.location.assign(accountUrl);
  };
  useEffect(() => {
    const intervalInMs = 100;
    const timesCalled = (durationInSeconds * 1000) / intervalInMs;

    // Since the user is unauthorized and will be moved away from the app, we want to clear out any auth-related information.
    // Doing so here will properly clear the information no matter how the user navigates away (waiting for the scheduled timer
    // to end, or directly clicking the account profile link before-hand).
    developerAuth.clear();

    // schedule a redirect after a countdown
    scheduledIntervalCounter(
      timesCalled,
      intervalInMs,
      updatePercent,
      redirectUrl(window)
    );
  }, []);

  return <UnauthorizedView percent={percent} accountUrl={accountUrl} />;
};
export default UnauthorizedContainer;
