import React from 'react';
import PropTypes from 'prop-types';
import AlertBootstrap from 'react-bootstrap/Alert';
import classes from './alert.scss';
export const Alert = (props) => {
  const [alertVisible, setAlertVisible] = React.useState(true);

  const handleClose = () => {
    setAlertVisible(false);
    props.handleDismiss;
  };
  return (
    <div>
      <AlertBootstrap
        key={props.type}
        variant={props.type}
        transition={false}
        show={alertVisible}

      >
        <div className={classes.alertContainer}>
          <div className={classes.content}>
            {props.icon}
            {props.body}
            {props.buttons}
          </div>
          <div
            className={classes.closeContainer}
            onClick={handleClose}
            role="presentation"
          >
            <i className={`togo-icon togo-icon-closes ${classes.closeIcon}`} />
          </div>
        </div>
      </AlertBootstrap>
    </div>
  );
};

Alert.defaultProps = {
  type: 'warning',
  handleDismiss: () => { },
};

Alert.propTypes = {
  type: PropTypes.oneOf([
    'warning',
    'danger',
    'success',
    'info',
  ]),
  icon: PropTypes.any,
  body: PropTypes.any.isRequired,
  buttons: PropTypes.any,
  handleDismiss: PropTypes.func,
};

export default Alert;
