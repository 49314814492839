import React from 'react';
import {
  FormattedMessage,
  defineMessages,
  FormattedHTMLMessage,
} from 'react-intl';
import AppLink from 'containers/app-link-container';
import getStartedImage from 'static/images/svg/GetStarted.svg';
import classes from './get-started.scss';
import environment from "lib/environment";

const apiReferenceUrl = environment.get().api_reference_url;


const t = defineMessages({
  'get.started.title': {
    id: 'get.started.title',
    defaultMessage: 'Get started',
    description: 'Title for the get started screen',
  },
  'get.started.headline': {
    id: 'get.started.headline',
    defaultMessage: 'Ready to create your first client?',
    description: 'Headline for the get started screen',
  },
  'get.started.subtitle': {
    id: 'get.started.subtitle',
    defaultMessage: 'Create client credentials for your development projects',
    description: 'Subtitle for the get started screen',
  },
  'get.started.api.documentation': {
    id: 'get.started.api.documentation',
    defaultMessage: 'Browse the <a href="{apiURL}">Product APIs</a>',
    description: 'Text and link to API documentation',
  },
  'get.started.oauth.clients': {
    id: 'get.started.oauth.clients',
    defaultMessage: '<a href="{oauthURL}" target="_blank" rel="noopener noreferrer">Learn about OAuth</a>',
    description: 'Text and link to understanding OAuth clients',
  },
  'get.started.create.client': {
    id: 'get.started.create.client',
    defaultMessage: 'Create a client',
    description: 'Button text for create client',
  },
});

const GetStartedView = () => {
  return (
    <div
      id="getStartedContainer"
      className={`row ${classes['getStartedContainer']}`}>
      <div
        id="getStartedImage"
        className={`col-md-6 col-xs-12 ${classes['getStartedImage']}`}>
        <img src={getStartedImage} alt="" />
      </div>
      <div
        id="getStartedContent"
        className={`col-md-6 col-xs-12 ${classes['getStartedContent']}`}>
        <div
          id="gsTitle"
          className={classes['getStartedTitle']}>
          <FormattedMessage {...t['get.started.title']} />
        </div>
        <div
          id="gsHeadline"
          className={classes['getStartedHeadline']}>
          <FormattedMessage {...t['get.started.headline']} />
        </div>
        <div
          id="gsSubtitle"
          className={classes['getStartedSubtitle']}>
          <FormattedMessage {...t['get.started.subtitle']} />
        </div>
        <div
          id="gsLinks"
          className={classes['getStartedLinksToDocs']}>
          <ul>
            <li><FormattedHTMLMessage {...t['get.started.api.documentation']} values={{apiURL: apiReferenceUrl}} /></li>
            <li><FormattedHTMLMessage {...t['get.started.oauth.clients']} values={{oauthURL: "https://oauth.net/"}} /></li>
          </ul>
        </div>
        <div
          id="gsCTA"
          className={classes['getStartedCTA']}>
          <AppLink to="clients.clientedit.add">
            <FormattedMessage {...t['get.started.create.client']} />
          </AppLink>
        </div>
      </div>
    </div>
);};

export default GetStartedView;
