import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, useIntl, FormattedHTMLMessage,} from 'react-intl';
import Modal from 'react-modal';
import classes from './confirm-dialog.scss';
import st from "core/shared-translations";
import dt from "core/dialog-translations";
import CloseButton from 'components/close-button-view';
import { getModalParent } from 'lib/utility-functions';

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

const ConfirmDialogView = ({
  textResourceId,
  closeConfirmModalYesFn,
  closeConfirmModalNoFn,
  closeModal,
  modalIsOpen,
  dynamicVal=''
}) => {
  const intl = useIntl();
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="ConfirmDialog"
        shouldCloseOnOverlayClick={false}
        className={classes['modal']}
        overlayClassName={classes['overlay']}
        parentSelector={getModalParent}
      >
        <div className="row">
          <div
            id="confirmDialogTitle"
            className={`col-xs-11 ${classes['confirmDialogTitle']}`}>
            <FormattedMessage {...dt[`confirmDialog.${textResourceId}.title`]} />
          </div>
          <div className="col-xs-1">
            <CloseButton
              ariaLabel={intl.formatMessage(st['shared.close'])}
              onClick={closeModal}
            />
          </div>
        </div>
        <div
          id="confirmDialogBody"
          className={classes['confirmDialogBody']}>
          <FormattedHTMLMessage {...dt[`confirmDialog.${textResourceId}.text`]} values={{dynamicVal: dynamicVal}} />
        </div>
        <div
          id="confirmDialogButtonRow"
          className="row">
          <div className="col-xs-12 no-gutter text-right">
            <button
              id="dialog-no-button"
              type="button"
              className={`btn btn-tertiary ninety-button ${classes['dialogNoButton']}`}
              onClick={closeConfirmModalNoFn}
            >
              <FormattedMessage {...dt[`confirmDialog.${textResourceId}.noButtonText`]} />
            </button>
            <button
              id="dialog-yes-button"
              type="button"
              className={`btn btn-default ninety-button marginl-5 ${classes['dialogYesButton']}`}
              onClick={closeConfirmModalYesFn}
            >
              <FormattedMessage {...dt[`confirmDialog.${textResourceId}.yesButtonText`]} />
            </button>
          </div>
        </div>
      </Modal>
    </div>);
};

ConfirmDialogView.propTypes = {
  textResourceId: PropTypes.string.isRequired,
  closeConfirmModalYesFn: PropTypes.func.isRequired,
  closeConfirmModalNoFn: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  dynamicVal: PropTypes.string
};

export default ConfirmDialogView;
