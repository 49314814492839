/* eslint-env _castle */
import React from "react";
import appconfig from "config/appconfig";
import environment from "lib/environment";
import { cssTransition, toast } from "react-toastify";

// Callback function to return the div for modal content placement
export const getModalParent = () =>
  document.querySelector(".container-fluid") || document.querySelector("body");

// Function to return query string values from a URL's location.search as an object.
export const getQueryStringParams = (searchStr) => {
  const queryValues = {};
  if (searchStr) {
    const searchString =
      searchStr.charAt(0) === "?" ? searchStr.substring(1) : searchStr;
    const paramArray = searchString.split("&");
    paramArray.forEach((param) => {
      const keyValuePair = param.split("=");
      if (keyValuePair.length === 2) {
        let val =
          keyValuePair[1].toLowerCase() === "true" ? true : keyValuePair[1];
        val = val === "false" ? false : val;
        queryValues[keyValuePair[0]] = val;
      }
    });
  }
  return queryValues;
};

// Function to display toastr messages (react-toastify) in core-view.js - https://www.npmjs.com/package/react-toastify
export const showToastrMessage = (
  toastrType,
  toastrMessage,
  toastrMessageId
) => {
  const messageType = toastrType.trim().toLowerCase();
  if (
    messageType === "success" ||
    messageType === "info" ||
    messageType === "error"
  ) {
    toast[messageType](toastrMessage, {
      toastId: toastrMessageId,
      autoClose: 6000,
      draggable: false,
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      newestOnTop: true,
      closeButton: true,
      transition: cssTransition({
        enter: "fadeIn",
        exit: "fadeOut",
        duration: 350,
      }),
      className: `toastifyToast ${messageType}`,
    });
  }
  return true;
};

// eslint-disable-next-line react/prop-types
export const ToastifyCloseButton = ({ closeToast }) => (
  <button onClick={closeToast}>
    <i className="togo-icon togo-icon-closes" />
  </button>
);

// Copy the text to this method to the clipboard
export const copyTextToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  // execCommand is depreciated
  document.execCommand("copy");
  document.body.removeChild(el);
  return true;
};

// Stop event
export const stopEvent = (e) => {
  if (typeof e !== "undefined") {
    e.stopPropagation();
    e.preventDefault();
  }
};

// Get Error object from API response. Some APIs send an 'error' array, others 'errors' array.
export const getAPIError = (errorResponse) => {
  return (
    errorResponse &&
    errorResponse.data &&
    (errorResponse.data.error
      ? errorResponse.data.error
      : errorResponse.data.errors
      ? errorResponse.data.errors
      : null)
  );
};

// Function to inject the user's language into the given URL - if not english.
// URL must have a placeholder "{0}" at the place where the language should be inserted.
export const getLocalizedUrl = (url = "", lang) => {
  if (!url) {
    return "";
  }
  const language = lang ? lang.toLowerCase().split("_")[0] : "en";
  const langString = language !== "en" ? lang + "/" : "";
  return url.replace("{0}", langString);
};
