import React from 'react';
import PropTypes from 'prop-types';
import Switch from "react-switch";

export const ReactSwitch = ({onChange,checked}) =>
  <Switch
    onChange={onChange}
    checked={checked}
    onColor="#006aff"
    onHandleColor="#FFFFFF"
    handleDiameter={20}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={40}
/>;

ReactSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange:PropTypes.func.isRequired
  
};

export default ReactSwitch;
