import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux';
import thunk from 'redux-thunk';
import makeRootReducer from './reducers';

// eslint-disable-next-line no-shadow
export const createStoreFactory = ({applyMiddleware, compose, reduxCreateStore, thunk, makeRootReducer}) =>
  (initialState = {}) => {
    // ======================================================
    // Middleware Configuration
    // ======================================================
    const middleware = [thunk];

    // ======================================================
    // Store Enhancers
    // ======================================================
    const enhancers = [];
    /* istanbul ignore next */
    if (__DEV__) {
      const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
      if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
      }
    }

    // ======================================================
    // Store Instantiation and HMR Setup
    // ======================================================
    const store = reduxCreateStore(
      makeRootReducer(),
      initialState,
      compose(
        applyMiddleware(...middleware),
        ...enhancers,
      ),
    );
    store.asyncReducers = {};

    /* istanbul ignore next */
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        const reducers = require('./reducers').default; // eslint-disable-line global-require
        store.replaceReducer(reducers(store.asyncReducers));
      });
    }

    return store;
  };

const createStore = createStoreFactory({
  applyMiddleware,
  compose,
  reduxCreateStore,
  thunk,
  makeRootReducer,
});

export default createStore;
