import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import st from 'core/shared-translations';
import classes from './client-info.scss';
import { Link } from 'react-router-dom';
import resolveRoute from "lib/resolve-route";

const t = defineMessages({
  'client-info-view.selection.count': {
    id: 'client-info-view.selection.count',
    defaultMessage: '{product} ({selected} of {total})',
    description: 'Label to show how many permissions are selected if not all were',
  },
  'client-info-view.redirectUris.count': {
    id: 'client-info-view.redirectUris.count',
    defaultMessage: '{firstURI} and {remainingCount} more',
    description: 'Label to show the first URI and how many redirect URIs are not shown',
  },
  'client-info-view.no.description': {
    id: 'client-info-view.no.description',
    defaultMessage: 'No description',
    description: 'Text to display when no description is set for a client',
  },
  'client-info-view.no.clients': {
    id: 'client-info-view.no.clients',
    defaultMessage: 'No clients',
    description: 'Text to display when no scopes are specified for a client',
  },
  'client-info-view.no.redirectURIs': {
    id: 'client-info-view.no.redirectURIs',
    defaultMessage: 'No redirect URIs',
    description: 'Text to display when no redirect URIs are specified for a client',
  },
  'client-info-view.no.scopes': {
    id: 'client-info-view.no.scopes',
    defaultMessage: 'No scopes',
    description: 'Text to display when no scopes are specified for a client',
  },
  'client-info-view.creationdate': {
    id: 'client-info-view.creationdate',
    defaultMessage: 'Created {creationDate}',
    description: 'Label for client creation date',
  },
  'client-info-view.edit-client': {
    id: 'client-info-view.edit-client',
    defaultMessage: 'Edit client: {client}',
    description: 'Title for the edit link in the client tile',
  }
});

const ClientInfo = ({
  clientData,
  isOneColumnLayout,
  onCopy
}) => {
  const intl = useIntl();
  return (
    <div
      className={`col-xs-12 col-sm-6${isOneColumnLayout ? " col-sm-offset-3" : ""}`}
      id={`clientInfo-${clientData.clientId}`}>
      <div className={classes['clientInfoTile']}>
        <div className={classes['clientTitleDiv']}>
          <span>
            {clientData.name}
          </span>
          <Link
            className={classes['clientEditCTA']}
            // passing current locatin in state to edit route in order to make a judgement on where we should redirect after edit
            to={{ pathname: resolveRoute('clients.clientedit', {routeParams: [clientData.clientId]}), state: { prevPath:location.pathname }}}
            title={intl.formatMessage(t['client-info-view.edit-client'], {client: clientData.name})}
            aria-label={intl.formatMessage(t['client-info-view.edit-client'], {client: clientData.name})}
          >
            <i className="togo-icon togo-icon-edit" />
            <FormattedMessage {...st['shared.edit']} />
          </Link>
        </div>
        <div
          className={classes['clientCreationDate']}>
          <FormattedMessage
            {...t['client-info-view.creationdate']}
            values={{creationDate: clientData.clientStartDate}} />
        </div>
        <div
          className={classes['clientDescription']}
          title={clientData.description ?
            clientData.description : intl.formatMessage(t['client-info-view.no.description'])}>
          {clientData.description &&
          clientData.description}
          {!clientData.description &&
            <span className={classes['noValueText']}>
              {intl.formatMessage(t['client-info-view.no.description'])}
            </span>}
        </div>
        <div className={classes['clientPropTitle']}>
          <FormattedMessage {...st['shared.redirect-uri']} />
        </div>
        <div
          className={classes['clientPropValue']}
          title={(clientData.redirectUris && clientData.redirectUris.length) ?
            clientData.redirectUris.join("\n") : intl.formatMessage(t['client-info-view.no.redirectURIs'])}>
          {clientData.redirectUris && clientData.redirectUris.length < 2 &&
          clientData.redirectUris[0]}
          {clientData.redirectUris && clientData.redirectUris.length > 1 &&
            <FormattedMessage
              {...t['client-info-view.redirectUris.count']}
              values={{firstURI: clientData.redirectUris[0], remainingCount: clientData.redirectUris.length - 1}} />}
          {!clientData.redirectUris || !clientData.redirectUris.length &&
            <span className={classes['noValueText']}>
              <FormattedMessage {...t['client-info-view.no.redirectURIs']} />
            </span>}
        </div>
        <div className={classes['clientPropTitle']}>
          <FormattedMessage {...st['shared.scopes']} />
        </div>
        <div
          className={classes['clientPropValue']}
          title={Object.keys(clientData.permissionsList).map((keyName) =>
            intl.formatMessage(st[clientData.permissionsList[keyName].parentScopeTranslationId])).join(" | ")}>
          {clientData.permissionsList && !!Object.keys(clientData.permissionsList).length &&
          Object.keys(clientData.permissionsList).map((keyName, index) => (
            <div
              key={index}
              id={`permission_${clientData.permissionsList[keyName].parentScopeKey}`}
              className={classes['permissionScope']}
            >
              <span className={classes['permissionTitle']}>
                {clientData.permissionsList[keyName].allScopesSelected &&
                  <FormattedMessage {...st[clientData.permissionsList[keyName].parentScopeTranslationId]} />}
                {!clientData.permissionsList[keyName].allScopesSelected &&
                  <FormattedMessage
                    {...t['client-info-view.selection.count']}
                    values={{
                      product: intl.formatMessage(st[clientData.permissionsList[keyName].parentScopeTranslationId]),
                      selected: clientData.permissionsList[keyName].numberOfScopesSelected,
                      total: clientData.permissionsList[keyName].numberOfScopesInProduct
                    }} />}
              </span>
            </div>
          ))}
          {!Object.keys(clientData.permissionsList).length &&
            <span className={classes['noValueText']}>
              <FormattedMessage {...t['client-info-view.no.scopes']} />
            </span>}
        </div>
        <div className={classes['clientPropTitle']}>
          <FormattedMessage {...st['shared.client-id']} />
        </div>
        <div
          id="clientId"
          className={classes['clientPropValue']}
          title={clientData.clientId}>
          <span className={classes['clientID']}>
            {clientData.clientId}
          </span>
          <button
            type="button"
            className={classes['clientCopyCTA']}
            onClick={onCopy(clientData.clientId)}
            title={intl.formatMessage(st['shared.copy-client-id'])}
            aria-label={intl.formatMessage(st['shared.copy-client-id'])}
          >
            <i className="togo-icon togo-icon-copy" />
            <FormattedMessage {...st['shared.copy']} />
          </button>
        </div>
      </div>
    </div>);
};


ClientInfo.propTypes = {
  clientData: PropTypes.object.isRequired,
  isOneColumnLayout: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
};

export default ClientInfo;
