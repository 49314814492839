import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ClientList from '../components/client-list-view';
import { uiShowToastrMessage } from 'modules/ui-module';
import {
  clientList,
  clientsIsLoading,
  clientListRequestGet,
  clientIsAdmin,
  clientAdminRequestGet,
} from 'modules/portal-module';
import { meId, meUserName } from 'modules/me-module';
import {
  copyTextToClipboard,
  stopEvent
} from 'lib/utility-functions';
import castleIOWrapper from 'lib/castleio-tracking';

export const ClientListContainer = (props) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [error, setError] = React.useState({ name: '', showError: false });

  const externalProps = useSelector(getValuesFromStore);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (castleIOWrapper && castleIOWrapper.identifyUserAndTrackPageInCastle) {
      castleIOWrapper.identifyUserAndTrackPageInCastle(externalProps.meId, externalProps.meUserName, 'Client Management Portal - Clients');
    }
    Promise.all([dispatch(clientListRequestGet()), dispatch(clientAdminRequestGet())]);
  }, [dispatch, externalProps.meId, externalProps.meUserName]);

  const displayToastrMessage = (toastrType, toastrMessageId, toastrParams = null) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams));
  };

  const onCopy = (str) => (e) => {
    stopEvent(e);
    if (copyTextToClipboard(str)) {
      displayToastrMessage('success', 'copy-to-clipboard.client-id-copied');
    }
    return false;
  };

  const onChange = (e) => {
    setError({ error: {} });
    const clientId = e.target.value;
    stopEvent(e);
    setSearchValue(clientId);
  };

  const onSearch = () => {
    //check if client is valid or not 
    const clientIsValid = searchValue.trim().length !== 0;
    if (!clientIsValid)
      setError({ name: 'shared.client-id.validation.error', showError: true });
    else
      props.history.push(`/clients/clientsearch/${searchValue}`);
  };

  return (
    <ClientList
      clientList={externalProps.clientList}
      isOneColumnLayout={externalProps && externalProps.clientList && externalProps.clientList.length === 1}
      hasClients={!!externalProps.clientList.length}
      onCopy={onCopy}
      isLoading={externalProps.isLoading}
      showClientIdSearchInput={externalProps.clientIsAdmin && externalProps.clientIsAdmin}
      onChange={onChange}
      searchValue={searchValue}
      onSearch={onSearch}
      error={error}
    />
  );
};


ClientListContainer.propTypes = {
  history: PropTypes.object.isRequired
};

export const getValuesFromStore = (state) => {
  return {
    meId: meId(state) || '',
    meUserName: meUserName(state) || '',
    clientList: clientList(state) || [],
    isLoading: clientsIsLoading(state),
    clientIsAdmin: clientIsAdmin(state) || false,
  };
};

export default ClientListContainer;
