import { combineReducers } from 'redux';
import { intlReducer as intl } from 'react-intl-redux';

import me from 'modules/me-module';
import organization from 'modules/organization-module';
import identity from 'modules/identity-module';
import ui from 'modules/ui-module';
import billing from 'modules/billing-module';
import clients from 'modules/portal-module';
import mtgService from 'modules/meeting-module';
import clientSecretKey from 'modules/client-secret-key-module';
import { reducer as formReducer } from 'redux-form';

// eslint-disable-next-line no-shadow
export const makeRootReducerFactory = ({ combineReducers, intl, me, organization, identity, ui, billing, clients, mtgService, clientSecretKey }) =>
  (asyncReducers) => combineReducers({
      // Add sync reducers here
      intl,
      me,
      organization,
      identity,
      ui,
      billing,
      clients,
      mtgService,
      clientSecretKey,
      ...asyncReducers,
    });

export const makeRootReducer = makeRootReducerFactory({
  combineReducers,
  intl,
  me,
  organization,
  identity,
  ui,
  billing,
  clients,
  mtgService,
  clientSecretKey,
  form: formReducer,
});

// eslint-disable-next-line no-shadow
export const injectReducerFactory = (makeRootReducer) =>
  (store, { key, reducer }) => {
    store.asyncReducers[key] = reducer; // eslint-disable-line no-param-reassign
    store.replaceReducer(makeRootReducer(store.asyncReducers));
  };

export const injectReducer = injectReducerFactory(makeRootReducer);

export default makeRootReducer;
