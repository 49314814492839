import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ConfirmDialogView from "components/confirm-dialog-view";
import { stopEvent } from "lib/utility-functions";
import { uiRemoveConfirmDialog, uiConfirmDialog } from "modules/ui-module";

export const ConfirmDialogContainer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let { confirmDialog } = useSelector(getValuesFromStore);
  let dispatch = useDispatch();
  useEffect(() => {
    if (
      confirmDialog.textResourceId &&
      confirmDialog.yesCallbackFn &&
      confirmDialog.noCallbackFn
    ) {
      openModal();
    }
  }, [confirmDialog.textResourceId, confirmDialog.yesCallbackFn, confirmDialog.noCallbackFn]);

  const openModal = (e) => {
    stopEvent(e);
    setModalIsOpen(true);
  };

  const closeModal = (e) => {
    stopEvent(e);
    setModalIsOpen(false);
    dispatch(uiRemoveConfirmDialog());
  };

  const closeConfirmModalYesFn = (e) => {
    stopEvent(e);
    setModalIsOpen(false);
    confirmDialog.yesCallbackFn();
    dispatch(uiRemoveConfirmDialog());
  };

  const closeConfirmModalNoFn = (e) => {
    stopEvent(e);
    setModalIsOpen(false);
    confirmDialog.noCallbackFn();
    dispatch(uiRemoveConfirmDialog());
  };

  return (
    <div>
      <ConfirmDialogView
        closeModal={closeModal}
        textResourceId={confirmDialog.textResourceId}
        closeConfirmModalYesFn={closeConfirmModalYesFn}
        closeConfirmModalNoFn={closeConfirmModalNoFn}
        modalIsOpen={modalIsOpen}
        dynamicVal={confirmDialog.dynamicVal}
      />
    </div>
  );
};

ConfirmDialogContainer.propTypes = {
  confirmDialog: PropTypes.shape({
    textResourceId: PropTypes.string,
    yesCallbackFn: PropTypes.func,
    noCallbackFn: PropTypes.func,
  }),
};

export const getValuesFromStore = (state) => {
  return {
    confirmDialog: uiConfirmDialog(state),
  };
};

export default ConfirmDialogContainer;
