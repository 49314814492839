import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Core from "../components/core-view";

import {
  uiAlertAuthenticationWarningVisible,
  uiAlertAuthenticationExpiredVisible,
  uiToastrMessage,
  uiConfirmDialog,
} from "modules/ui-module";

export const getValuesFromStore = (state) => ({
  alertAuthWarningIsVisible: uiAlertAuthenticationWarningVisible(state),
  alertAuthExpiredIsVisible: uiAlertAuthenticationExpiredVisible(state),
  toastrMessage: uiToastrMessage(state),
  confirmDialog: uiConfirmDialog(state),
});

export const CoreContainer = () => {
  const {
    alertAuthWarningIsVisible,
    alertAuthExpiredIsVisible,
    toastrMessage,
    confirmDialog,
  } = useSelector(getValuesFromStore);
  return (
    <Core
      alertAuthWarningIsVisible={alertAuthWarningIsVisible}
      alertAuthExpiredIsVisible={alertAuthExpiredIsVisible}
      toastrMessage={toastrMessage}
      confirmDialog={confirmDialog}
    />
  );
};

CoreContainer.propTypes = {
  toastrMessage: PropTypes.shape({
    type: PropTypes.string,
    messageId: PropTypes.string,
    params: PropTypes.object,
  }),
  confirmDialog: PropTypes.shape({
    textResourceId: PropTypes.string,
    yesCallbackFn: PropTypes.func,
    noCallbackFn: PropTypes.func,
  }),
};

export default CoreContainer;
