import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/Overlay';
import popOverBottom from './popover-bottom-view';

export const TextArea = ({
  name,
  label,
  onChange,
  onBlur,
  placeholder,
  value,
  error = '',
  helpText,
  infoText,
  cols,
  rows,
  cssClass= '',
  isShallowGroup = false,
  disabled = false,
}) =>
  <div className={`form-group${isShallowGroup ? ' shallow' : ''}${error.length ? ' has-error' : ''}`}>
    <div className="control-block">
      <label id={`${name}-label`} htmlFor={`${name}-input`}>
        {label}
        {helpText &&
          <OverlayTrigger
            container={this}
            trigger="click"
            rootClose
            placement="bottom"
            overlay={popOverBottom(name, helpText)}>
            <i className="togo-icon togo-icon-question-circle togo-icon-question-circle-leftpadding help-icon-move-down" />
          </OverlayTrigger>}
      </label>
      <textarea
        id={`${name}-input`}
        type="textarea"
        className={`form-control ${cssClass}`}
        style={{resize: "none"}}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        cols={cols}
        rows={rows}
        value={value}
        disabled={disabled}
      />
      {error &&
        <p id={`${name}-error`} className="help-block">{error}</p>}
      {infoText &&
        <p id={`${name}-infoText`} className="infoText-block">{infoText}</p>}
    </div>
  </div>;

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  infoText: PropTypes.string,
  error: PropTypes.string,
  isShallowGroup: PropTypes.bool,
  cols: PropTypes.string.isRequired,
  rows: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextArea;
