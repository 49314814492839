import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  defineMessages,
  useIntl,
} from 'react-intl';
import AppLink from 'containers/app-link-container';
import st from 'core/shared-translations';
import environment from 'lib/environment';
import ProductLink from './product-link-view';
import { Link } from 'react-router-dom';
import appconfig from 'config/appconfig';

const myAccountUrl = environment.get().myAccount_url;
const adminCenterUrl = environment.get().admin_url;
const billingUrl = environment.get().billing_url;
const apiReferenceUrl = environment.get().api_reference_url;
const personalInfoUrl = environment.get().personal_info_url;
const singInSecurityUrl = environment.get().signin_security_url;
const supportUrl = `${apiReferenceUrl}${appconfig.infoURLs.supportUrl}`;
const faqUrl = `${apiReferenceUrl}${appconfig.infoURLs.faqUrl}`;

const t = defineMessages({
  'nav.navbar.app-title': {
    id: 'nav.navbar.app-title',
    defaultMessage: 'Developers',
    description: 'The Client Management Portal application title shown next to the GoTo logo',
  },
  'nav.hamburgertoggle': {
    id: 'nav.hamburgertoggle',
    defaultMessage: 'Toggle navigation',
    description: 'The nav hamburger toggle which shows and hides nav menu for mobile devices',
  },
  'nav.navbar.clients': {
    id: 'nav.navbar.clients',
    defaultMessage: 'OAuth Clients',
    description: 'The nav bar Clients link',
  },
  'nav.navbar.apidocumentation': {
    id: 'nav.navbar.apidocumentation',
    defaultMessage: 'Product APIs',
    description: 'The nav bar Product APIs link',
  },
  'nav.navbar.personal.info': {
    id: 'nav.navbar.personal.info',
    defaultMessage: 'Personal Info',
    description: 'The nav bar Personal Info link',
  },
  'nav.navbar.signin.security': {
    id: 'nav.navbar.signin.security',
    defaultMessage: 'Sign In & Security',
    description: 'The nav bar Sign In & Security link',
  },
  'nav.navbar.help': {
    id: 'nav.navbar.help',
    defaultMessage: 'Help',
    description: 'Help icon label',
  },
  'nav.navbar.faq': {
    id: 'nav.navbar.faq',
    defaultMessage: 'FAQ',
    description: 'FAQ menu label',
  },
  'nav.navbar.new-features': {
    id: 'nav.navbar.new-features',
    defaultMessage: 'New Features',
    description: 'New Features menu label',
  },
  'nav.navbar.sign-out': {
    id: 'nav.navbar.sign-out',
    defaultMessage: 'Sign Out',
    description: 'Sign Out menu label',
  },
  'nav.navbar.my-account': {
    id: 'nav.navbar.my-account',
    defaultMessage: 'My Account',
    description: 'My Account menu label',
  },
  'nav.navbar.admin-center': {
    id: 'nav.navbar.admin-center',
    defaultMessage: 'Admin Center',
    description: 'Admin Center menu label',
  },
  'nav.navbar.billing': {
    id: 'nav.navbar.billing',
    defaultMessage: 'Billing',
    description: 'Billing menu label',
  },
  'nav.lmi.account': {
    id: 'nav.lmi.account',
    defaultMessage: 'GoTo Account: {name}',
    description: 'Hover text to be displayed over the Nav Bar\'s small avatar image',
  },
});

const onDropdownTriggerClick = (e) => { e.preventDefault; e.target.parentNode.style.outlineWidth='0px'; };
const onDropdownTriggerBlur = (e) => { e.preventDefault; e.target.style.outlineWidth='5px'; };

export const MainNav = ({
  logout,
  displayName,
  meIsAccountAdmin,
  myProducts,
  avatarImageUrl,
  meIsBillingAdmin,
  displayEmail,
  showSupportLink,
  gotoAppEnabled,
}) => {
  const intl = useIntl();
  return (
    <div>
      <nav id="navbar" className="navbar navbar-lite" role="navigation">
        <div className="container-fluid">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle no-padding"
              data-toggle="collapse"
              data-target="#navbar-collapse-1">
              <span className="sr-only">
                <FormattedMessage {...t['nav.hamburgertoggle']} />
              </span>
              <i className="togo-icon togo-icon-hamburger" />
            </button>
            <a className="navbar-brand-lite" href="#" title="GoTo"><FormattedMessage {...t['nav.navbar.app-title']} /></a>
          </div>

          <div className="collapse navbar-collapse" id="navbar-collapse-1">
            <ul className="nav navbar-nav">
              <li>
                <a
                  id="mainApiRef"
                  href={apiReferenceUrl}>
                  <FormattedMessage {...t['nav.navbar.apidocumentation']} />
                  <div />
                </a>
              </li>
              <li>
                <AppLink to="clients" activeClassName="active">
                  <FormattedMessage {...t['nav.navbar.clients']} />
                  <div />
                </AppLink>
              </li>
            </ul>
            <hr className="nav-mobile-separator-line" />
            <ul className="nav navbar-nav navbar-right">
              {showSupportLink &&
                <li className="dropdown-lite">
                  <a
                    role="button"
                    className="dropdown-toggle dropdown-toggle-icon"
                    id="dropdownHelpMenu"
                    data-toggle="dropdown"
                    tabIndex="0"
                    onClick={onDropdownTriggerClick}
                    onBlur={onDropdownTriggerBlur}
                  >
                    <i className="togo-icon togo-icon-question-circle" />
                    <span className="icon-label-lite"><FormattedMessage {...t['nav.navbar.help']} /></span>
                  </a>
                  <ul className="dropdown-menu nav-dropdown-menu" role="menu" aria-labelledby="dropdownHelpMenu">
                    <li>
                      <a href={faqUrl} tabIndex="-1">
                        <i className="togo-icon togo-icon-list togo-dropdown-link-icon" />
                        <FormattedMessage {...t['nav.navbar.faq']} />
                      </a>
                    </li>
                    <li>
                      <a href={supportUrl} tabIndex="-1">
                        <i className="togo-icon togo-icon-file-txt togo-dropdown-link-icon" />
                        <FormattedMessage {...st['shared.help.support']} />
                      </a>
                    </li>
                  </ul>
                </li>}
              <li className="dropdown-lite">
                <a
                  role="button"
                  className="dropdown-toggle dropdown-toggle-avatar"
                  data-toggle="dropdown"
                  id="navPersonalMenu"
                  tabIndex="0"
                  onClick={onDropdownTriggerClick}
                  onBlur={onDropdownTriggerBlur}
                >
                  <img
                    className="dropdown-avatar"
                    id="navAvatar"
                    src={avatarImageUrl}
                    alt=""
                    title={intl.formatMessage(t['nav.lmi.account'], {name: displayName})} />
                  <div className="dropdown-avatar-user-info">
                    <div className="user-name" id="avatarUserName" title={displayName}>{displayName}</div>
                    <div className="user-email" id="avatarUserEmail" title={displayEmail}>{displayEmail}</div>
                  </div>
                </a>
                <ul className="dropdown-menu nav-dropdown-menu" role="menu" aria-labelledby="navPersonalMenu">
                  <li className="dropdown-menu-user-info">
                    <img id="navMenuAvatar" src={avatarImageUrl} alt="" />
                    <div>
                      <div className="user-name" id="navMenuUserName" title={displayName}>{displayName}</div>
                      <div className="user-email" id="navMenuUserEmail" title={displayEmail}>{displayEmail}</div>
                    </div>
                  </li>
                  <li className="divider dropdown-user-info" />
                  <li className="dropdown-first-menu-item">
                    <a href={myAccountUrl} tabIndex="-1">
                      <i className="togo-icon togo-icon-person togo-dropdown-link-icon" />
                      <FormattedMessage {...t['nav.navbar.my-account']} />
                    </a>
                  </li>
                  <li>
                    <Link to={appconfig.routes.clients()} className="selected" tabIndex="-1">
                      <i className="togo-icon togo-icon-code togo-dropdown-link-icon" />
                      <FormattedMessage {...t['nav.navbar.clients']} />
                    </Link>
                  </li>
                  <li>
                    <a href={apiReferenceUrl} tabIndex="-1">
                      <i className="togo-icon togo-icon-reader togo-dropdown-link-icon" />
                      <FormattedMessage {...t['nav.navbar.apidocumentation']} />
                    </a>
                  </li>
                  <li>
                    <a href={personalInfoUrl} tabIndex="-1">
                      <i className="togo-icon togo-icon-id-card togo-dropdown-link-icon" />
                      <FormattedMessage {...t['nav.navbar.personal.info']} />
                    </a>
                  </li>
                  <li>
                    <a href={singInSecurityUrl} tabIndex="-1">
                      <i className="togo-icon togo-icon-lock-closed togo-dropdown-link-icon" />
                      <FormattedMessage {...t['nav.navbar.signin.security']} />
                    </a>
                  </li>
                  {meIsAccountAdmin &&
                    <li>
                      <a href={adminCenterUrl} tabIndex="-1">
                        <i className="togo-icon togo-icon-people togo-dropdown-link-icon" />
                        <FormattedMessage {...t['nav.navbar.admin-center']} />
                      </a>
                    </li>}
                  {meIsBillingAdmin &&
                    <li>
                      <a href={billingUrl} tabIndex="-1">
                        <i className="togo-icon togo-icon-money togo-dropdown-link-icon" />
                        <FormattedMessage {...t['nav.navbar.billing']} />
                      </a>
                    </li>}
                  {!!myProducts.length && <li className="divider" />}
                  {!!myProducts.length &&
                  myProducts.map(product => <ProductLink key={product} product={product} gotoAppEnabled={gotoAppEnabled} />)}
                  <li className="divider" />
                  <li><a href="#" id="main-nav-logout" onClick={logout} tabIndex="-1">
                    <i className="togo-icon togo-icon-leave-meeting togo-dropdown-link-icon" />
                    <FormattedMessage {...t['nav.navbar.sign-out']} /></a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>);
};

MainNav.propTypes = {
  logout: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  meIsAccountAdmin: PropTypes.bool.isRequired,
  myProducts: PropTypes.array.isRequired,
  avatarImageUrl: PropTypes.string.isRequired,
  meIsBillingAdmin: PropTypes.bool.isRequired,
  displayEmail: PropTypes.string.isRequired,
  showSupportLink: PropTypes.bool.isRequired,
  gotoAppEnabled: PropTypes.bool.isRequired,
};

export default MainNav;
