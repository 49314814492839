import React from 'react';
import PropTypes from 'prop-types';
import prodSpecs from 'lib/nav-product-specs';
import environment from 'lib/environment';

export const getProduct = (product, gotoAppEnabled) => {
  const productName = (product === 'g2m' || product === 'g2mfree') ?
    `${product}${gotoAppEnabled ? 'App' : ''}` :
    product;
  return prodSpecs[productName];
};

const RenderProductLink = (props) => {
  const prod = getProduct(props.product, props.gotoAppEnabled);
  if (prod) {
    const prodName = prod.product;
    const prodClass = prod.class;
    const prodUrl = environment.get()[prod.productUrlRef];
    return (
      <li id={`${prodName}_li`}>
        <a href={prodUrl} id={`${prodName}-nav`} tabIndex="-1">
          <i className={`${prodClass}`} />
          {prodName}
        </a>
      </li>
    );
  }
  return null;
};

RenderProductLink.propTypes = {
  product: PropTypes.string.isRequired,
  gotoAppEnabled: PropTypes.bool.isRequired,
};

export default RenderProductLink;
