/**
 * Error message handling for HTTP Response Codes of type 400;
 *   allows application to specify error codes that are handled by the UI,
 *   so that the default Error page does not get called in the event
 *   when those specified error code types occur.
 *
 *   NOTE: In the event when multiple errors are found in the error object
 *   the 'isErrorHandledByUI()' method will always return false. (Most often we only receive one error object from the API).
 *
 *   NOTE: If the same API request is being made for the same data from multiple modules in the application,
 *   and it's 400 error codes are to be handled by the UI (e.g., updating the user name from multiple screens),
 *   then make sure that 'setHandledErrorType()' is called with the error code before the API call, and all related
 *   promises for the API call handle the specified error messages on the UI.
 */

/**
 * ErrorCodesHandledByUI - object to contain keys for error codes handled by the UI.
 */
export const ErrorCodesHandledByUI = {};

/**
 * setHandledErrorCodes - Method that updates the ErrorCodesHandledByUI object with unique error codes handled by the UI code.
 *
 * @param {array | string | number} errorCodes - a string or number of a single error code or status, or an array of error codes to store in the ErrorCodesHandledByUI object.
 */
export const setHandledErrorCodes = (errorCodes) => {
  if (typeof errorCodes === 'string' || typeof errorCodes === 'number') {
    errorCodes = [errorCodes];
  }
  if (Array.isArray(errorCodes)) {
    errorCodes.forEach((errorCode) => {
      errorCode = errorCode + '';
      ErrorCodesHandledByUI[errorCode] = true;
    });
  }
};

/**
 * removeHandledErrorCode - Method that removes error codes from the ErrorCodesHandledByUI object.
 *
 * @param {array | string | number} errorCodes - a string or number of a single error code or status, or an array of error codes to remove from the ErrorCodesHandledByUI object.
 */
export const removeHandledErrorCodes = (errorCodes) => {
  if (typeof errorCodes === 'string' || typeof errorCodes === 'number') {
    errorCodes = [errorCodes];
  }
  if (Array.isArray(errorCodes)) {
    errorCodes.forEach((errorCode) => {
      errorCode = errorCode + '';
      if (ErrorCodesHandledByUI[errorCode]) {
        delete ErrorCodesHandledByUI[errorCode];
      }
    });
  }
};

/**
 * isErrorHandledByUI - Method that checks if the error code is among the list handled by the UI
 *
 * @param {object} errorResponse - the error response object returned from the API to check.
 * @returns {boolean} true|false - to indicate if the error is in the list of error codes handled by the UI.
 */
export const isErrorHandledByUI = (errorResponse) => {
  let isHandledErrorCode = false;
  if (errorResponse && errorResponse.status) {
    const statusCode = errorResponse.status + '';
    if (!!ErrorCodesHandledByUI[statusCode]) { // option to allow a specific status code to pass through.
      isHandledErrorCode = true;
    } else if ((
        statusCode === '400' ||
        statusCode === '422' ||
        statusCode === '404' ||
        statusCode === '409') &&
      errorResponse.data) {
      let errorObj = errorResponse.data.error ? errorResponse.data.error : (errorResponse.data.errors ? errorResponse.data.errors : undefined);
      // email verification does not return an error object, only the status code and error code.
      if (typeof errorObj === 'undefined' && statusCode === '422' && errorResponse.data && errorResponse.data.code) {
        errorObj = [errorResponse.data];
      }
      if (
        errorObj &&
        errorObj.length &&
        errorObj.length === 1 &&
        errorObj[0].code
      ) {
        isHandledErrorCode = !!ErrorCodesHandledByUI[errorObj[0].code];
      }
    }
  }
  return isHandledErrorCode;
};

export default {
  ErrorCodesHandledByUI, // need to export ErrorCodesHandledByUI ojbect to be able to clear it in unit testing.
  setHandledErrorCodes,
  removeHandledErrorCodes,
  isErrorHandledByUI,
};
