import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert-view';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
} from 'react-intl';
import classes from './alert.scss';

const t = defineMessages({
  'alert.auth.warning.message': {
    id: 'alert.auth.warning.message',
    defaultMessage: 'Your session is about to expire. Save your work and reload the page.',
  },
  'alert.auth.warning.time': {
    id: 'alert.auth.warning.time',
    defaultMessage: '<strong>{timeLeft}</strong> seconds remaining.',
  },
  'alert.auth.warning.buttonReload': {
    id: 'alert.auth.warning.buttonReload',
    defaultMessage: 'Reload',
  },
});

export const AlertAuthWarning = (props) => (
  <Alert
    icon={
      <i className={`togo-icon togo-icon-alert text-warning ${classes.bannerMessageIcon}`} />
    }
    body={
      <span className={classes.bannerMessageText} role="timer" aria-live="off">
        <FormattedMessage {...t['alert.auth.warning.message']} />&nbsp;
        {props.timeLeft && <FormattedHTMLMessage {...t['alert.auth.warning.time']} values={{timeLeft: props.timeLeft}} />}
      </span>
    }
    buttons={
      <button id="auth-warning-reload" className={classes.bannerMessageCTA} onClick={props.handleReload}>
        <FormattedMessage {...t['alert.auth.warning.buttonReload']} />
      </button>
    }
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertAuthWarning.propTypes = {
  timeLeft: PropTypes.number,
  handleAlertDismiss: PropTypes.func.isRequired,
  handleReload: PropTypes.func.isRequired,
};

export default AlertAuthWarning;
