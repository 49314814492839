import React from 'react';
import PropTypes from 'prop-types';
import classes from './checkbox-input.scss';

const onClick = (e) => { e.target.nextSibling.style.outline='none'; };
const onBlur = (e) => { e.target.nextSibling.style.removeProperty('outline'); };

export const CheckBoxInput = ({
  id,
  onChange,
  value = '',
  disabled = false,
  checked = false,
  indeterminate = false,
  title = "",
}) =>
  <div className="control-block">
    <label
      id={`${id}-label`}
      className={classes['checkbox-label']}
      htmlFor={id}
    >
      <input
        id={id}
        title={title}
        name={title}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
      />
      <span
        className={`${indeterminate ? classes['checkbox-chameleon-indeterminate'] : classes['checkbox-chameleon']} ${disabled ? classes['disabled'] : ''}`}
        title={title} />
    </label>
  </div>;

CheckBoxInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  title: PropTypes.string,
};

export default CheckBoxInput;
