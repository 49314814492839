import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ClientEdit from '../components/client-edit-view';
import {
  hasIllegalCharacters,
  isValidURI,
} from 'core/input-validations';
import { uiShowToastrMessage } from 'modules/ui-module';
import { meLocale } from 'modules/me-module';
import {
  getClientById,
  portalClientRequestPatch,
  portalClientRequestPost,
  getNewClientCredentials,
  portalClientRequestDelete,
  clientsIsLoading,
} from 'modules/portal-module';
import { setHandledErrorCodes } from 'lib/error-message-handling';
import { isEqual, cloneDeep } from 'lodash';
import {
  copyTextToClipboard,
  stopEvent,
  getAPIError,
} from 'lib/utility-functions';
import appconfig from 'config/appconfig';
import { uiShowConfirmDialog } from 'modules/ui-module';
import moment from 'moment';

export const ClientEditContainer = (props) => {
  
  const getValuesFromStore = (state) => {
    let client = {};
    let isEditMode = true;
    const { clientId } = props.match.params ||'';
    if (clientId) {
      client = getClientById(state, clientId);
    }
    if (isEqual(client, {})) {
      isEditMode = false;
      client = {
        name: '',
        redirectUris: [],
        description: '',
        scopes: []
      };
    }
    return {
      clientId,
      client,
      meLocale: meLocale(state),
      isEditMode,
      newClientCredentials: getNewClientCredentials(state),
      isLoading: clientsIsLoading(state),
    };
  };
  const dispatch = useDispatch();
  const externalProps = useSelector(getValuesFromStore);

  const [client, setClient] = useState({ ...externalProps.client });
  const [isEditMode, setEditMode] = useState(externalProps.isEditMode);
  const [errors, setErrors] = useState({});
  const [addRedirectURI, setAddRedirectURI] = useState('');
  const [detailsContentsClass, setDetailsContentsClass] = useState('');
  const [permissionsContentsClass, setPermissionsContentsClass] = useState('hidden');
  const [credentialsContentsClass, setCredentialsContentsClass] = useState('hidden');
  const [detailsButtonClass, setDetailsButtonClass] = useState('active');
  const [permissionsButtonClass, setPermissionsButtonClass] = useState('');
  const [credentialsButtonClass, setCredentialsButtonClass] = useState('');
  const [permissionsList, setPermissionsList] = useState({});
  const [existingPermissionsNotControlledByDevPortal, setExistingPermissionsNotControlledByDevPortal] = useState([]);
  const [selectedPermissionsControlledByDevPortal, setSelectedPermissionsControlledByDevPortal] = useState([]);
  const [secretCopied, setSecretCopied] = useState(false);
  const [previousUrlPath, setPreviousUrlPath] = useState('');

  const origState = React.useRef();
  const storeOrigStateNow = React.useRef(false);

  const createOriginalState = () => {
    if(!origState.current){
      origState.current = {
        client: cloneDeep(client),
        errors: cloneDeep(errors),
        isEditMode,
        addRedirectURI,
        detailsContentsClass,
        permissionsContentsClass,
        credentialsContentsClass,
        detailsButtonClass,
        permissionsButtonClass,
        credentialsButtonClass,
        permissionsList: cloneDeep(permissionsList),
        existingPermissionsNotControlledByDevPortal,
        selectedPermissionsControlledByDevPortal,
        secretCopied,
      };
    }
  };

  const setPermissions = () => {
    const permissions = {};
    let clientScopes = client && client.scopes && client.scopes.length ? [...client.scopes] : [];
    if (clientScopes.indexOf(appconfig.defaultProfileScope) < 0) {
      clientScopes.unshift(appconfig.defaultProfileScope);
    }
    const permissionsControlledByDevPortal = [];
    for (let [key, permissionScopes] of Object.entries(appconfig.permissionsList)) {
      permissionScopes = permissionScopes || [];
      permissionsControlledByDevPortal.push(...permissionScopes);
      const selectedScopes = clientScopes.filter(el => permissionScopes.includes(el));
      permissions[key] = {
        name: key,
        scopes: permissionScopes,
        isGranularView: false,
        hasChildScopes: permissionScopes.length > 1,
        selectedScopes: selectedScopes,
        intermediate: (selectedScopes.length > 1) && (selectedScopes.length < permissionScopes.length),
      };
      if (key.toLowerCase() === 'profile') {
        if (permissionScopes.indexOf(appconfig.defaultProfileScope) < 0) {
          permissions[key].scopes.unshift(appconfig.defaultProfileScope);
        }
        permissions[key].isGranularView = true;
      }
    }
    // identify the selected scopes by dev portal
    const selectedPermissionsControlledByDevPortalValue = getSelectedPermisisonsControlledByDevPortal(permissions);
    // identify and store all permissions (scopes) not controlled by dev portal to include in submission
    const filteredExistingPermissionsNotControlledByDevPortal = clientScopes.filter(el => !permissionsControlledByDevPortal.includes(el)) || [];
    const existingPermissionsNotControlledByDevPortalVal = filteredExistingPermissionsNotControlledByDevPortal.slice();
    const selectedPermissionsControlledByDevPortalVal = selectedPermissionsControlledByDevPortalValue.slice();

    setPermissionsList(permissions);
    setExistingPermissionsNotControlledByDevPortal(existingPermissionsNotControlledByDevPortalVal);
    setSelectedPermissionsControlledByDevPortal(selectedPermissionsControlledByDevPortalVal);
    return true;
  };

  //on change of permissions set orignal state
  React.useEffect( () => {
    //avoid calling createOriginalState on first render 
    if(storeOrigStateNow.current){
      createOriginalState();
    }
    else {
      storeOrigStateNow.current = true;
    }
  },[permissionsList, existingPermissionsNotControlledByDevPortal, selectedPermissionsControlledByDevPortal]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    // setTimeout(() => { $('#name-input').focus(); }, 250);
    setPreviousUrlPath(props.location.state && props.location.state.prevPath);
    // redirect to the client list if the clientId is defined in the URL, but the client was not found
    //     - e.g., the page was reloaded by the client
    if (typeof externalProps.clientId !== 'undefined' && (!externalProps.client || !externalProps.client.hasOwnProperty('clientId'))) {
      return navToClientList();
    }
    setPermissions();
  }, []);

  const contentsHaveChanged = () => {
    if (origState && origState.current &&
       origState.current.client && client &&
       origState.current.selectedPermissionsControlledByDevPortal && selectedPermissionsControlledByDevPortal) {
      return !isEqual(origState.current.client, client) ||
        !isEqual(origState.current.selectedPermissionsControlledByDevPortal, selectedPermissionsControlledByDevPortal);
    }
    return false;
  };

  const validateUserInput = (field, value, onBlurCheck) => {
    let errorText = '';
    const foundErrors = { ...errors };

    if (foundErrors[field]) {
      delete foundErrors[field];
    }

    if (field === 'name') {
      if (value.length > 128) {
        errorText = 'client-edit.validation.name-exceeds-max-length';
      }
      if (onBlurCheck && isEditMode && !value.length) {
        errorText = 'client-edit.validation.name-required';
      }
    }

    if (field === 'description' && value.length > 500) {
      errorText = 'client-edit.validation.description-exceeds-max-length';
    }

    if (field === 'addRedirectURI' && (value.length > 250 || (onBlurCheck && value && !isValidURI(value)))) {
      errorText = 'client-edit.addRedirectUrl-malformed-uri';
    }

    if (!errorText.length && field === 'addRedirectURI' && hasIllegalCharacters(value, true)) {
      errorText = 'shared.illegal-URI-character-entry';
    }

    if (!errorText.length && field !== 'addRedirectURI' && hasIllegalCharacters(value)) {
      errorText = 'shared.illegal-character-entry';
    }

    if (errorText.length) {
      foundErrors[field] = errorText;
    }

    setErrors(foundErrors);

    return !errorText.length;
  };

  const validateEntryOnBlur = (e) => {
    const foundClient = { ...client };
    const field = e.target.id.split('-')[0];
    const value = e.target.value.trim();
    foundClient[field] = value;
    if (validateUserInput(field, value, true)) {
      setClient(foundClient);
    }
    return true;
  };

  const enableSubmit = () => {
    return (
      Object.keys(errors).length === 0 &&
      contentsHaveChanged() &&
      !addRedirectURI &&
      !externalProps.isLoading
    );
  };

  const isRedirectUriValid = () => {
    return (
      Object.keys(errors).length === 0 &&
      addRedirectURI.trim().length > 8
    );
  };

  const updateClientState = (e) => {
    const foundClient = { ...client };
    const field = e.target.id.split('-')[0];
    const value = e.target.value;

    foundClient[field] = value;
    if (validateUserInput(field, value, false)) {
      setClient(foundClient);
    }
    return true;
  };

  const updateRedirectUriInState = (e) => {
    const field = e.target.id.split('-')[0];
    const value = e.target.value;
    const addRedirectURIValue = value;
    if (validateUserInput(field, value, false)) {
      setAddRedirectURI(addRedirectURIValue);
    }
    return true;
  };

  const displayToastrMessage = (toastrType, toastrMessageId, toastrParams = null) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams));
  };

  const handleXHRResult = (success, messageId, toastrParams = null) => {
    const toastrType = success ? 'success' : 'error';
    displayToastrMessage(toastrType, messageId, toastrParams);
  };

  const getSelectedPermisisonsControlledByDevPortal = (permissionsList) => {
    const selectedPermissionsControlledByDevPortalVal = [];
    for (let [key] of Object.entries(permissionsList)) {
      const selectedScopes = permissionsList[key].selectedScopes || [];
      if (selectedScopes.length)
        selectedPermissionsControlledByDevPortalVal.push(...selectedScopes);
    }
    return selectedPermissionsControlledByDevPortalVal.slice();
  };

  const getClientStartDate = () => {
    if (client && client.created) {
      const lang = externalProps.meLocale ? externalProps.meLocale.split('_')[0] : 'en';
      return moment(client.created).locale(lang).format('LL');
    }
    return '';
  };

  const permissionCheckboxClick = (name, scope) => (e) => {
    const isChecked = e.target && e.target.checked;
    const permissionsListVal = { ...permissionsList };
    if (permissionsListVal && permissionsListVal[name]) {
      const indexOfScope = permissionsListVal[name].selectedScopes.indexOf(scope);
      if (isChecked && indexOfScope < 0) {
        permissionsListVal[name].selectedScopes.push(scope);
      } else if (!isChecked && indexOfScope > -1) {
        permissionsListVal[name].selectedScopes.splice(indexOfScope, 1);
      }
    }
    const selectedPermissionsControlledByDevPortalVal = getSelectedPermisisonsControlledByDevPortal(permissionsListVal);
    setPermissionsList(permissionsListVal);
    setSelectedPermissionsControlledByDevPortal(selectedPermissionsControlledByDevPortalVal);
    return true;
  };

  const permissionGroupCheckboxClick = (name) => (e) => {
    const isChecked = e.target && e.target.checked;
    const permissionsListVal = { ...permissionsList };
    if (permissionsListVal && permissionsListVal[name]) {
      if (isChecked) {
        permissionsListVal[name].selectedScopes = [...permissionsListVal[name].scopes];
      } else {
        permissionsListVal[name].selectedScopes = [];
      }
    }
    const selectedPermissionsControlledByDevPortalVal = getSelectedPermisisonsControlledByDevPortal(permissionsListVal);
    setPermissionsList(permissionsListVal);
    setSelectedPermissionsControlledByDevPortal(selectedPermissionsControlledByDevPortalVal);
    return true;
  };

  const togglePermissionsDetail = (name) => (e) => {
    stopEvent(e);
    const permissionsListVal = { ...permissionsList };
    if (permissionsListVal && permissionsListVal[name]) {
      permissionsListVal[name].isGranularView = !permissionsListVal[name].isGranularView;
    }
    setPermissionsList(permissionsListVal);
    return false;
  };

  const navToClientList = () => {
    if (contentsHaveChanged()) {
      dispatch(uiShowConfirmDialog("hasEdits", () => true, toClientList));
    } else {
      toClientList();
    }
  };

  const toClientList = () => {
    props.history.push("/clients");
  };

  const navToCredentialsTab = () => {
    setDetailsContentsClass('hidden');
    setPermissionsContentsClass('hidden');
    setCredentialsContentsClass('');
    setDetailsButtonClass('completed');
    setPermissionsButtonClass('completed');
    setCredentialsButtonClass('completed');
  };

  const onCancel = (e) => {
    stopEvent(e);
    setClient(origState.current && origState.current.client);
    setEditMode(origState.current && origState.current.isEditMode);
    setErrors(origState.current && origState.current.errors);
    setAddRedirectURI(origState.current && origState.current.addRedirectURI);
    setDetailsContentsClass(origState.current && origState.current.detailsContentsClass);
    setPermissionsContentsClass(origState.current && origState.current.permissionsContentsClass);
    setCredentialsContentsClass(origState.current && origState.current.credentialsContentsClass);
    setDetailsButtonClass(origState.current && origState.current.detailsButtonClass);
    setPermissionsButtonClass(origState.current && origState.current.permissionsButtonClass);
    setCredentialsButtonClass(origState.current && origState.current.credentialsButtonClass);
    setPermissionsList(origState.current && origState.current.permissionsList);
    setExistingPermissionsNotControlledByDevPortal(origState.current && origState.current.existingPermissionsNotControlledByDevPortal);
    setSelectedPermissionsControlledByDevPortal(origState.current && origState.current.selectedPermissionsControlledByDevPortal);
    setSecretCopied(origState.current && origState.current.secretCopied);
    return true;
  };

  const onAddUriClick = (e) => {
    stopEvent(e);
    const clientVal = { ...client };
    const addRedirectURIVal = addRedirectURI.trim();
    if (Object.keys(errors).length === 0 && addRedirectURIVal) {
      if (!clientVal.redirectUris) {
        clientVal.redirectUris = [];
      }
      if (clientVal.redirectUris.indexOf(addRedirectURIVal) > -1) {
        const errorsVal = { ...errors };
        errorsVal.addRedirectURI = 'client-edit.addRedirectUrl-matching-uri';
        setErrors(errorsVal);
        return false;
      }
      clientVal.redirectUris.push(addRedirectURIVal);
      setClient(clientVal);
      setAddRedirectURI('');
      return true;
    }
    return false;
  };

  const onDeleteUriClick = (index) => (e) => {
    stopEvent(e);
    const clientVal = { ...client };
    clientVal.redirectUris.splice(index, 1);
    setClient(clientVal);
    return true;
  };

  const validateURIEntryFromNextButton = () => {
    const clientVal = { ...client };
    const field = "addRedirectURI";
    const value = addRedirectURI.trim();
    clientVal[field] = value;
    if (validateUserInput(field, value, true)) {
      setClient(clientVal);
      return true;
    }
    return false;
  };

  const onTabClick = (e) => {
    const tabClicked = e.target.id.split('-')[0];
    if ((tabClicked === 'permissionsTabBtn' && addRedirectURI) &&
      !(onAddUriClick(e) && validateURIEntryFromNextButton() && isRedirectUriValid())) {
      return false;
    }
    const stateUpdate = {
      detailsContentsClass: 'hidden',
      permissionsContentsClass: 'hidden',
      credentialsContentsClass: 'hidden',
      detailsButtonClass: '',
      permissionsButtonClass: '',
      credentialsButtonClass: ''
    };
    switch (tabClicked) {
      case 'detailsTab':
      case 'detailsTabBtn':
        stateUpdate.detailsContentsClass = '';
        stateUpdate.detailsButtonClass = 'active';
        break;
      case 'permissionsTab':
      case 'permissionsTabBtn':
        stateUpdate.permissionsContentsClass = '';
        stateUpdate.permissionsButtonClass = 'active';
        if (!isEditMode) {
          stateUpdate.detailsButtonClass = 'completed';
        }
        break;
      case 'credentialsTab':
      case 'credentialsTabBtn':
        stateUpdate.credentialsContentsClass = '';
        stateUpdate.credentialsButtonClass = 'active';
        if (!isEditMode) {
          stateUpdate.detailsButtonClass = 'completed';
          stateUpdate.permissionsButtonClass = 'completed';
          stateUpdate.credentialsButtonClass = 'completed';
        }
        break;
    }
    setDetailsContentsClass(stateUpdate.detailsContentsClass);
    setPermissionsContentsClass(stateUpdate.permissionsContentsClass);
    setCredentialsContentsClass(stateUpdate.credentialsContentsClass);
    setDetailsButtonClass(stateUpdate.detailsButtonClass);
    setPermissionsButtonClass(stateUpdate.permissionsButtonClass);
    setCredentialsButtonClass(stateUpdate.credentialsButtonClass);

    return true;
  };

  const onCopy = (str) => (e) => {
    stopEvent(e);
    if (copyTextToClipboard(str)) {
      displayToastrMessage('success', 'copy-to-clipboard.client-id-copied');
    }
    return false;
  };

  const onDelete = (e) => {
    stopEvent(e);
    dispatch(uiShowConfirmDialog("deleteClient", doDelete, () => true));
  };

  const onConfirmSecretCopy = (e) => {
    const isChecked = e.target && e.target.checked;
    setSecretCopied(isChecked);
    return true;
  };

  const doDelete = (e) => {
    stopEvent(e);
    return dispatch(portalClientRequestDelete(externalProps.clientId))
      .then(
        () => {
          handleXHRResult(true, 'client-edit.client-delete-successful');
          origState.current = null;
          toClientList();
        },
        () => {
          handleXHRResult(false, 'client-edit.client-delete-failed');
        },
      );
  };

  const onSave = (e) => {
    stopEvent(e);
    setHandledErrorCodes([
      'duplicate.name'
    ]);
    const dataToSave = {};
    if (client.name !== origState.current.client.name) {
      dataToSave.name = client.name;
    }
    if (client.description !== origState.current.client.description) {
      dataToSave.description = client.description ? client.description : null;
    }
    if (!isEqual(client.redirectUris, origState.current.client.redirectUris)) {
      dataToSave.redirectUris = client.redirectUris;
    }
    if (!isEqual(origState.current.selectedPermissionsControlledByDevPortal, selectedPermissionsControlledByDevPortal)) {
      dataToSave.scopes = [...selectedPermissionsControlledByDevPortal, ...existingPermissionsNotControlledByDevPortal];
      const identityReadScopeIndex = dataToSave.scopes.indexOf(appconfig.defaultProfileScope);
      if (identityReadScopeIndex > -1) {
        dataToSave.scopes.splice(identityReadScopeIndex, 1);
      }
    }
    
    // Save the data:
    if (isEditMode) {
      return dispatch(portalClientRequestPatch(externalProps.clientId, dataToSave))
        .then(
          () => {
            handleXHRResult(true, 'client-edit.client-update-successful');
            origState.current = null;
            if (previousUrlPath !== '/clients') {
              props.history.push(previousUrlPath);
            }
            else {
              toClientList();
            }
          },
          (error) => {
            const errorObj = getAPIError(error);
            const errorCode = errorObj && errorObj.length === 1 ? errorObj[0].code : '';
            if (errorCode === 'duplicate.name') {
              handleXHRResult(false, 'client-edit.client-update-failed-dup-name');
            } else {
              handleXHRResult(false, 'client-edit.client-update-failed');
            }
          },
        );
    } else {
      // Add the default grant values
      dataToSave.grants = ["authorization_code", "implicit", "refresh_token"];
      return dispatch(portalClientRequestPost(dataToSave))
        .then(
          () => {
            handleXHRResult(true, 'client-edit.client-add-successful');
            navToCredentialsTab();
          },
          (error) => {
            const errorObj = getAPIError(error);
            const errorCode = errorObj && errorObj.length === 1 ? errorObj[0].code : '';
            if (errorCode === 'duplicate.name') {
              handleXHRResult(false, 'client-edit.client-add-failed-dup-name');
            } else {
              handleXHRResult(false, 'client-edit.client-add-failed');
            }
          },
        );
    }
  };


  return (
    <ClientEdit
      isEditMode={isEditMode}
      client={client}
      clientStartDate={getClientStartDate()}
      errors={errors}
      tabClick={onTabClick}
      onChange={updateClientState}
      onRedirectUriChange={updateRedirectUriInState}
      onBlur={validateEntryOnBlur}
      onSave={onSave}
      onCancel={onCancel}
      onDelete={onDelete}
      onDoneClick={toClientList}
      isDone={secretCopied}
      onConfirmSecretCopy={onConfirmSecretCopy}
      detailsContentsClass={detailsContentsClass}
      permissionsContentsClass={permissionsContentsClass}
      credentialsContentsClass={credentialsContentsClass}
      detailsButtonClass={detailsButtonClass}
      permissionsButtonClass={permissionsButtonClass}
      credentialsButtonClass={credentialsButtonClass}
      permissionsList={permissionsList}
      togglePermissionsDetail={togglePermissionsDetail}
      enableCTAs={enableSubmit()}
      canCancel={contentsHaveChanged()}
      addRedirectURI={addRedirectURI}
      isRedirectUriValid={isRedirectUriValid()}
      onAddUriClick={onAddUriClick}
      onDeleteUriClick={onDeleteUriClick}
      permissionCheckboxClick={permissionCheckboxClick}
      permissionGroupCheckboxClick={permissionGroupCheckboxClick}
      onCopy={onCopy}
      newClientCredentials={externalProps.newClientCredentials}
      isLoading={false}
      navToClientList={navToClientList}
    />
  );
};
ClientEditContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ClientEditContainer;