import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import Table from "react-bootstrap/Table";
import { getModalParent } from "lib/utility-functions";
import Modal from "react-modal";
import st from "core/shared-translations";
import mt from 'core/message-translations';
import modalClasses from "components/confirm-dialog.scss";
import CloseButton from "components/close-button-view";
import TextInput from "components/text-input-view";
import ReactSwitch from "components/react-switch";
import classes from "./clientedit.scss";
import CheckboxInput from "components/checkbox-input-view";
import ReactTooltip from 'react-tooltip';

const t = defineMessages({
  "client-secret.title": {
    id: "client-secret.title",
    defaultMessage: "You can create up to 6 secrets, but only 3 can be active.",
    description: "Sub title for secrets details",
  },
  "client-secret.title.disable.max-secrets": {
    id: "client-secret.title.disable.max-secrets",
    defaultMessage: "You’re at the maximum number of secrets. To create a new secret, you need to delete an existing one.",
    description: "Sub title for secrets details when it's disable",
  },
  "client-secret.title.disable.max-active-secrets": {
    id: "client-secret.title.disable.max-active-secrets",
    defaultMessage: "You’re at the maximum number of active secrets. To create a new secret, you need to disable an existing one.",
    description: "Sub title for secrets details when active secret is 3",
  },
  'client-secret.create.title': {
    id: 'client-secret.create.title',
    defaultMessage: 'Create another secret',
    description: 'Title for editing a client secret in client details',
  },
  'client-secret.table.title1': {
    id: 'client-secret.table.title1',
    defaultMessage: 'Name',
    description: 'Table Secret-Title 1',
  },
  'client-secret.table.title2': {
    id: 'client-secret.table.title2',
    defaultMessage: 'Created',
    description: 'Table Secret-Title 2',
  },
  'client-secret.table.title3': {
    id: 'client-secret.table.title3',
    defaultMessage: 'Status',
    description: 'Table Secret-Title 3',
  },
  'client-secret.table.title4': {
    id: 'client-secret.table.title4',
    defaultMessage: 'Actions',
    description: 'Table Secret-Title 4',
  },
  'client-secret.delete-button.text': {
    id: 'client-secret.delete-button.text',
    defaultMessage: 'Delete secret',
    description: 'Client secret delete button text',
  },
  'client-secret.create-modal.title': {
    id: 'client-secret.create-modal.title',
    defaultMessage: 'Create secret',
    description: 'Title for create secret modal',
  },
  'client-secret.create-modal.info': {
    id: 'client-secret.create-modal.info',
    defaultMessage: 'Name your secret to make it easier to identify.',
    description: 'Info for create secret input',
  },
  'client-secret.create-modal.input.label': {
    id: 'client-secret.create-modal.input.label',
    defaultMessage: 'Secret name',
    description: 'label for create secret input',
  },
  'client-secret.create-modal-Cta-button.next': {
    id: 'client-secret.create-modal-Cta-button.next',
    defaultMessage: 'Next: Copy secret',
    description: 'label for create secret Next button',
  },
  'client-secret.create-modal-copy-secret.title': {
    id: 'client-secret.create-modal-copy-secret.title',
    defaultMessage: 'Copy and store secret',
    description: 'heading for copy secret modal',
  },
  'client-secret.create-modal-copy-secret.subtitle': {
    id: 'client-secret.create-modal-copy-secret.subtitle',
    defaultMessage: 'This secret will only be shown once. Copy it and store it in a secure place.',
    description: 'sub-heading for copy secret modal',
  },
  'client-secret.create-modal-copy-secret.second-subtitle': {
    id: 'client-secret.create-modal-copy-secret.second-subtitle',
    defaultMessage: 'Secret',
    description: 'second heading for copy secret modal',
  },
  'client-edit.credentials.client.new.secret.copy.confirm': {
    id: 'client-edit.credentials.client.new.secret.copy.confirm',
    defaultMessage: 'I’ve copied and stored the secret.',
    description: 'confirm secret copied label for checkbox',
  },
  'client-secret.active.status-value': {
    id: 'client-secret.active.status-value',
    defaultMessage: 'Active',
    description: 'label for active status value',
  },
  'client-secret.disabled.status-value': {
    id: 'client-secret.disabled.status-value',
    defaultMessage: 'Disabled',
    description: 'label for disable status value',
  },
  'client-secret-info.delete': {
    id: 'client-secret-info.delete',
    defaultMessage: 'Only disabled secrets can be deleted',
    description: 'On hover text for delete client secret',
  },
  'client-secret.active-secret-limit': {
    id: 'client-secret.active-secret-limit',
    defaultMessage: 'You can\'t activate this secret because you’ve reached the limit of active secrets.You have to disable another secret to be able to activate this.',
    description: 'Client name already present warning',
  }
});

const ClientSecretCreateView = ({
  createModelIsOpen,
  copySecretModelIsOpen,
  openCreateModal,
  closeCreateModal,
  closeCopySecretModal,
  onNextClick,
  updateNewClientSecret,
  openedSecretEdit,
  toggleEditMode,
  onDelete,
  secrets,
  enableCTA,
  onCopy,
  onConfirmSecretCopy,
  statusOnChange,
  clientSecretData,
  updateClientSecretName,
  enableEditCTA,
  onEditSave,
  errors,
  disableCreateSecret,
  validateUserInput,
  currentSecretSubHeading
}) => {
  const intl = useIntl();
  const emptyFuc = () => { };

  return (

    <div id="client-secret-create-container">
      <div id="clientSecretCreateTitle">
        <FormattedMessage {...t[currentSecretSubHeading]} />
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 no-gutter">
          <button
            type="button"
            className={`${classes['addSecretButton']} ${disableCreateSecret ? classes['disabled'] : ''}`}
            onClick={openCreateModal}
            disabled={disableCreateSecret}
          >
            <i className="togo-icon togo-icon-add" />
            <FormattedMessage {...t['client-secret.create.title']} />
          </button>
        </div>
      </div>
      <div>
        {!!secrets.length && (
          <Table striped>
            <thead>
              <tr>
                {[...Array(4).keys()].map((i) => {
                  return (
                    <th key={i}>
                      <FormattedMessage {...t[`client-secret.table.title${i + 1}`]} />
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {secrets.map((data, i) => (
                // eslint-disable-next-line react/jsx-key
                <React.Fragment key={`${data.name}`}>
                  <tr key={`secret_ket_row ${i}`}>
                    <td>{data.name}</td>
                    <td>{data.createTime}</td>
                    <td>{!!data.active ?
                      <FormattedMessage {...t['client-secret.active.status-value']} /> :
                      <FormattedMessage {...t['client-secret.disabled.status-value']} />}
                    </td>
                    <td>
                      <a
                        href="#"
                        onClick={toggleEditMode(data && data.secretId)}
                      >
                        <i
                          className={`togo-icon togo-icon-edit ${classes['editIcon']}`}
                          role="presentation"
                        />
                      </a>
                      <a
                        href="#"
                        onClick={!data.active ? onDelete(data && data.secretId) : emptyFuc}
                      >
                        <i
                          className={`togo-icon togo-icon-trash ${classes['editIcon']} 
                          ${!!data.active ? classes['disabled'] : ''}`}
                          data-tip
                          data-for={!!data.active ? `${data.name}_${data.active}_tip` : ''}
                          style={{ pointerEvents: 'auto' }}
                        />
                        {!!data.active && <ReactTooltip
                          id={`${data.name}_${data.active}_tip`}
                          className="helpToolTip"
                          type="light"
                          place="bottom"
                          effect="solid"
                          border
                          multiline>
                          <span>{intl.formatMessage(t['client-secret-info.delete'])}</span>
                        </ReactTooltip>}
                      </a>
                    </td>
                  </tr>
                  <React.Fragment>
                    {openedSecretEdit === data.secretId && (
                      <tr key={`keyEdit ${data && data.name}`}>
                        <td colSpan={5} style={{ padding: 0 }}>
                          <div className={`row ${classes['editView']}`}>
                            <div className="col-xs-2" />
                            <div className="col-xs-6">
                              <div className={`${classes['editTitle']}`}>
                                <FormattedMessage {...t['client-secret.table.title1']} />
                              </div>
                              <TextInput
                                name="name"
                                label=""
                                helpText=""
                                value={clientSecretData.name}
                                onChange={updateClientSecretName}
                                showCounter
                                error={
                                  (errors.name && (intl.formatMessage(mt[errors.name]))) || 
                                  (errors.longEditSecret && errors.longEditSecret)
                                }
                                dangerCounter={!!errors.longEditSecret && errors.longEditSecret === 'client-edit.secret.length.exceeded'}
                                onBlur={() => validateUserInput("name", clientSecretData.name)}
                              />

                              <button
                                id="btn-save"
                                type="submit"
                                disabled={!enableEditCTA}
                                className="btn btn-default ninety-button marginl-5"
                                onClick={onEditSave()}
                              >
                                <FormattedMessage {...st['shared.save']} />
                              </button>
                              <button
                                id="btn-cancel"
                                type="button"
                                className={`btn btn-tertiary ninety-button ${classes['cancelBtn']}`}
                                onClick={toggleEditMode(data && data.secretId)}
                                title={intl.formatMessage(st['shared.cancel'])}
                              >
                                <FormattedMessage {...st['shared.cancel']} />
                              </button>
                            </div>

                            <div className="col-xs-4">
                              <div className={`${classes['editTitle']}`}>
                                <FormattedMessage {...t['client-secret.table.title4']} />
                              </div>
                              <div className={`${classes["switchButton"]}`}>
                                <div
                                  data-tip
                                  data-for={`${openedSecretEdit}_tip`}
                                >
                                  {!!errors.status &&
                                    <ReactTooltip
                                      id={`${openedSecretEdit}_tip`}
                                      className="helpToolTip"
                                      type="light"
                                      place="bottom"
                                      effect="solid"
                                      border
                                      multiline>
                                      <span>{intl.formatMessage(t[errors.status])}</span>
                                    </ReactTooltip>}
                                  <ReactSwitch
                                    onChange={!!errors.status ? emptyFuc : statusOnChange}
                                    checked={clientSecretData.active}
                                  />
                                </div>
                                <div className={`${classes["switchStatus"]}`}>
                                  {clientSecretData.active && (
                                    <FormattedMessage {...t['client-secret.active.status-value']} />
                                  )}
                                  {!clientSecretData.active && (
                                    <FormattedMessage {...t['client-secret.disabled.status-value']} />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        )}
        <Modal
          isOpen={createModelIsOpen}
          onRequestClose={closeCreateModal}
          contentLabel="ConfirmDialog"
          shouldCloseOnOverlayClick={false}
          className={modalClasses['modal']}
          overlayClassName={modalClasses['overlay']}
          parentSelector={getModalParent}
        >
          <div className="row">
            <div
              id="confirmDialogTitle"
              className={`col-xs-11 ${modalClasses['confirmDialogTitle']}`}
            >
              <FormattedMessage {...t['client-secret.create-modal.title']} />
            </div>
            <div className="col-xs-1">
              <CloseButton ariaLabel={intl.formatMessage(st['shared.close'])} onClick={closeCreateModal} />
            </div>
          </div>
          <div
            id="confirmDialogBody"
            className={modalClasses['confirmDialogBody']}
          >
            <div className={`${classes['modalBodySpacing']}`}>
              <div
                className={`${classes['credentialsSubtitleTitle']} ${classes['modalBodySpacing']}`}
              >
                <FormattedMessage {...t['client-secret.create-modal.input.label']} />
              </div>
              <FormattedMessage {...t['client-secret.create-modal.info']} />
            </div>

            <TextInput
              id="secret-key"
              name="secret-key"
              type="text"
              label=""
              value={clientSecretData.name}
              onChange={updateNewClientSecret}
              showCounter
              error={
                (errors.name && (intl.formatMessage(mt[errors.name]))) ||
                (errors.longEditSecret && errors.longEditSecret)
              }
              dangerCounter={!!errors.longEditSecret && errors.longEditSecret === 'client-edit.secret.length.exceeded'}
              onBlur={() => validateUserInput("name", clientSecretData.name)}
            />
          </div>
          <div id="confirmDialogButtonRow" className="row">
            <div className="col-xs-12 no-gutter text-right">
              <button
                id="dialog-no-button"
                type="button"
                className={`btn btn-tertiary ninety-button ${modalClasses['dialogNoButton']}`}
                onClick={closeCreateModal}
              >
                <FormattedMessage {...st['shared.cancel']} />
              </button>
              <button
                id="dialog-yes-button"
                type="button"
                className={`btn btn-default ninety-button marginl-5 ${modalClasses['dialogYesButton']}`}
                onClick={onNextClick}
                disabled={!enableCTA}
              >
                <FormattedMessage {...t['client-secret.create-modal-Cta-button.next']} />
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={copySecretModelIsOpen}
          onRequestClose={closeCopySecretModal}
          contentLabel="ConfirmDialog"
          shouldCloseOnOverlayClick={false}
          className={modalClasses['modal']}
          overlayClassName={modalClasses['overlay']}
          parentSelector={getModalParent}
        >
          <div className="row">
            <div
              id="confirmDialogTitle"
              className={`col-xs-11 ${modalClasses['confirmDialogTitle']}`}
            >
              <FormattedMessage {...t['client-secret.create-modal-copy-secret.title']} />
            </div>
            <div className="col-xs-1">
              <CloseButton
                ariaLabel={intl.formatMessage(st['shared.close'])}
                onClick={closeCopySecretModal}
              />
            </div>
          </div>
          <div
            id="confirmDialogBody"
            className={modalClasses['confirmDialogBody']}
          >
            <React.Fragment>
              <FormattedMessage {...t['client-secret.create-modal-copy-secret.subtitle']} />
              <div
                className={`${classes['modalBodySpacing']} ${classes['credentialsSubtitleTitle']} ${classes['modalBodySpacing']} ${classes['secretKeyHeadingSpacing']}`}
              >
                <FormattedMessage {...t['client-secret.create-modal-copy-secret.second-subtitle']} />
              </div>
              <div
                className={`row ${classes['clientsecretDisplay']}`}
                id="clientSecret-value-row"
              >
                <div
                  id="client-secret-value"
                  className={`col-xs-9 ${classes['credentialsValue']}`}
                >
                  {clientSecretData.id}
                </div>
                <div className={`col-xs-3 ${classes['credentialsCopy']}`}>
                  <button
                    type="button"
                    id="clientSecretCopyButton"
                    className={classes['clientCopyCTA']}
                    onClick={onCopy(clientSecretData.id)}
                  >
                    <i className="togo-icon togo-icon-copy" />
                    <FormattedMessage {...st['shared.copy']} />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div
                    style={{ display: 'inline-block', paddingRight: '12px', verticalAlign: 'text-top' }}
                  >
                    <CheckboxInput
                      id="confirmCopy"
                      name="confirmCopy"
                      checked={!!clientSecretData.secretCopied}
                      onChange={onConfirmSecretCopy}
                    />
                  </div>
                  <div
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'top',
                    }}
                  >
                    <FormattedMessage {...t['client-edit.credentials.client.new.secret.copy.confirm']} />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
          <div id="confirmDialogButtonRow" className="row">
            <div className="col-xs-12 no-gutter text-right">
              <button
                id="dialogue-success-button"
                type="button"
                className={`btn btn-default ninety-button ${modalClasses['dialogYesButton']}`}
                onClick={closeCopySecretModal}
                disabled={!clientSecretData.secretCopied}
              >
                <FormattedMessage {...st['shared.done']} />
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

ClientSecretCreateView.propTypes = {
  createModelIsOpen: PropTypes.bool.isRequired,
  copySecretModelIsOpen: PropTypes.bool.isRequired,
  openCreateModal: PropTypes.func.isRequired,
  closeCreateModal: PropTypes.func.isRequired,
  closeCopySecretModal: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  secrets: PropTypes.array.isRequired,
  updateNewClientSecret: PropTypes.func.isRequired,
  openedSecretEdit: PropTypes.string.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  enableCTA: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
  onConfirmSecretCopy: PropTypes.func.isRequired,
  statusOnChange: PropTypes.func.isRequired,
  clientSecretData: PropTypes.object.isRequired,
  updateClientSecretName: PropTypes.func.isRequired,
  enableEditCTA: PropTypes.bool.isRequired,
  onEditSave: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  disableCreateSecret: PropTypes.bool.isRequired,
  validateUserInput: PropTypes.func.isRequired,
  currentSecretSubHeading: PropTypes.string.isRequired,
};

export default ClientSecretCreateView;
