/**
 * This callback is called once counted equals totalCount.
 * @callback completeCallback
 */

/**
 * This callback is called each interval loop
 * @callback intervalCallback
 * @param {number} totalCount - The number of times to loop
 * @param {number} counted - The number of intervals we have counted
 */

/**
 * Runs a setInterval for the specified totalCount, calling the callback each
 * interval and then calls completeCallback when the interval has run the specified
 * total number of times.
 * @param {number} totalCount - the number of times to loop
 * @param {number} intervalDuration - the interval to loop in ms
 * @param {intervalCallback} intervalCallback - called on each interval loop
 * @param {completeCallback} completeCallback - called once counted equals totalCount
 */
export const scheduledIntervalCounter = (totalCount, intervalDuration, intervalCallback, completeCallback) => {
  let counted = 0;
  const intervalTimer = setInterval(() => {
    counted++;
    intervalCallback(totalCount, counted);

    if (counted === totalCount) {
      completeCallback();
      // Stop the interval
      clearInterval(intervalTimer);
    }
  }, intervalDuration);
};
