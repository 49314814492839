import React from "react";
import Footer from "../components/footer-view";
import getVersion from "lib/version";
import { meLocale } from "modules/me-module";
import { useSelector } from "react-redux";

const version = getVersion();

export const FooterContainer = () => {
  const { language } = useSelector(mapStoreValueToProps);
  return (
    <Footer
      currentYear={new Date().getFullYear()}
      version={version}
      language={language}
    />
  );
};

export const mapStoreValueToProps = (state) => ({
  language: meLocale(state)
    ? meLocale(state).split("_")[0].toLowerCase()
    : "en",
});

export default FooterContainer;
