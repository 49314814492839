/**
 * This module contains global configuration values that are not different per environment.
 * It is not meant to be used for environment-specific values like Authentication, APIs, client secrets, etc. as
 * values here will be included in all production bundles unchanged.
 *
 * Environment-specific values are configured in src/static/config/config.json for local sandbox development,
 * and in the client-management-portal-config project for other environments. See README.md for more details.
 */

const identityUserUrl = 'identity/v1/Users';
const orgServiceUrl = 'organization/api';
const identityVerificationUrl = 'verification';
const billingUrl = 'rest/v1';
const tfaUrl = 'tfa/users';
const devicesUrl = 'devmgmt/v1';
const mtgServiceUrl = 'rest/2';

export default {
  routes: {
    clients: () => '/clients',
    'clients.clientsearch': (clientId) => `/clients/clientsearch/${clientId}`,
    'clients.clientedit': (clientId) => `/clients/clientedit/${clientId}`,
    'clients.clientedit.add': () => `/clients/clientedit`,
    noKey: {
      unauthorized: () => '/unauthorized',
      error: () => '/error',
    },
  },
  endpoints: {
    me: `${identityUserUrl}/me`,
    meWithUniqueParam: `${identityUserUrl}/me?u=${new Date().valueOf()}`,
    avatarUrls: `${identityUserUrl}/me/avatarurls`,
    organizationMe: `${orgServiceUrl}/me`,
    organizationByKey: (organizationKey) => `${orgServiceUrl}/organizations/${organizationKey}`,
    organizationDomains: (organizationKey) => `${orgServiceUrl}/organizations/${organizationKey}/domains`,
    organizationAdmins: (organizationKey) => `${orgServiceUrl}/organizations/${organizationKey}/admins`,
    identityVerificationEmail: `${identityVerificationUrl}/emails`,
    identityUserById: (userId) => `${identityUserUrl}/${userId}`,
    billingAccountMe: `${billingUrl}/me`,
    tfaMe: `${tfaUrl}/me`,
    forceSamlSso: 'samlRequired',
    devices: `${devicesUrl}/devices`,
    portalClient: 'client',
    portalClients: 'clients',
    portalClientById: (clientId) => `client/${clientId}`,
    portalClientsById: (clientId) => `clients/${clientId}`,
    mtgServiceSettings: `${mtgServiceUrl}/settings`,
    portalAdminClientGetEndpoint: `/me`,
    clientSecretKeysById: (clientId) => `clients/${clientId}/secrets`,
    clientSecretKeyBySecretId: (clientId, secretId) => `clients/${clientId}/secrets/${secretId}`,
  },
  storage: {
    oauth: 'auth_token',
  },
  unauthorized: {
    redirect_time: 10,
  },
  scimSchemas: {
    core: 'urn:scim:schemas:core:1.0',
    enterprise: 'urn:scim:schemas:extension:enterprise:1.0',
    getGo: 'urn:scim:schemas:extension:getgo:1.0',
  },
  blackListedDomains: [
    'yahoo',
    'gmail',
    'outlook',
    'hotmail',
  ],
  toastrOptions: {
    timeOut: 6000,
  },
  supportedLocales: [
    'de_DE',
    'en_US',
    'es_ES',
    'fr_FR',
    'it_IT',
    'zh_CN',
    'ja_JP',
    'ko_KR',
    'pt_BR',
  ],
  logMeInProducts: {
    g2ars: 'GoToAssist Remote Support',
    g2asd: 'GoToAssist Service Desk',
    g2aseeit: 'GoToAssist Seeit',
    g2m: 'GoToMeeting',
    g2t: 'GoToTraining',
    g2w: 'GoToWebinar',
    g2p: 'GoToMyPc',
    openvoice: 'OpenVoice',
    jive: 'GoToConnect',
    hvoice: 'HVoice',
    gotoconnect: 'GoToConnect',
    bold360: 'Bold360',
    gotoresolve: 'GoToResolve',
    grasshopper: 'Grasshopper',
  },
  // Two-Factor-Verification - list of products that support tfa.
  tfaSupportList: ['g2ars', 'g2asd', 'g2aseeit'],
  // Support experience "enumerations"
  supportExperiences: {
    DEFAULT: 'DEFAULT',
    HIDE: 'HIDE',
    JIVE_ONLY: 'JIVE_ONLY',
  },
  infoURLs: {
    supportUrl: '/support',
    faqUrl: '/guides/FAQ/Ref-API-Cost/'
  },
  devices: {
    highRiskThreshold: 0.9,
  },
  defaultProfileScope: 'identity:read',
  permissionsList: {
    profile: ["identity:scim.me"],
    collaboration : ['collab:'],
    support: ['support:'],
    scim: ['identity:scim.org'],
    admincenter: ['identity:'],
    jive: [
      'call-control.v1.calls.control',
      'call-events.v1.events.read',
      'call-events.v1.notifications.manage',
      'call-history.v1.notifications.manage',
      'calls.v2.initiate',
      'cr.v1.read',
      'fax.v1.notifications.manage',
      'fax.v1.read',
      'fax.v1.write',
      'messaging.v1.notifications.manage',
      'messaging.v1.read',
      'messaging.v1.send',
      'messaging.v1.write',
      'presence.v1.notifications.manage',
      'presence.v1.read',
      'presence.v1.write',
      'recording.v1.notifications.manage',
      'recording.v1.read',
      'users.v1.lines.read',
      'voice-admin.v1.read',
      'voice-admin.v1.write',
      'voicemail.v1.notifications.manage',
      'voicemail.v1.voicemails.read',
      'voicemail.v1.voicemails.write',
      'webrtc.v1.read',
      'webrtc.v1.write',
    ]
  },
};
