import React from 'react';
import {AnchorIntl} from 'components/intl';
import { FormattedHTMLMessage, defineMessages } from 'react-intl';
import environment from 'lib/environment';
import PropTypes from 'prop-types';
import { getLocalizedUrl } from 'lib/utility-functions';

const privacyPolicyUrl = environment.get().privacy_policy_url;
const aboutUsUrl = environment.get().about_us_url;

const t = defineMessages({
  'footer.aboutus': {
    id: 'footer.aboutus',
    defaultMessage: 'About us',
    description: 'The about us link',
  },
  'footer.legalinfo': {
    id: 'footer.legalinfo',
    defaultMessage: 'Legal info',
    description: 'The terms of service link',
  },
  'footer.copyright': {
    id: 'footer.copyright',
    defaultMessage: '© {currentYear} GoTo Group, Inc. All Rights Reserved',
    description: 'The copyright text',
  },
});

export const Footer = ({
  currentYear,
  version,
  language,
}) => (
  <footer className="togo-footer-lite">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12 col-sm-5">
          <ul className="pull-left center-mobile">
            <li><AnchorIntl a={{href: getLocalizedUrl(aboutUsUrl, language), message: t['footer.aboutus']}} /></li>
            <li><AnchorIntl a={{href: getLocalizedUrl(privacyPolicyUrl, language), message: t['footer.legalinfo']}} /></li>
          </ul>
        </div>
        <div className="col-xs-12 col-sm-7">
          <ul className="pull-right center-mobile">
            <li>
              <span id="cmpversion">{version}</span>
            </li>
            <li>
              <FormattedHTMLMessage {...t['footer.copyright']} values={{currentYear}} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  currentYear: PropTypes.number,
  version: PropTypes.string,
  language: PropTypes.string,
};

export default Footer;
